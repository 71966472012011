import React, { FC } from 'react';
import { targetLinkArrowWhite } from 'assets/lib/common/icons';
import { Icon } from 'components/base';
import { AEP_DATA_TYPE_PREFIX, AEP_TRACK, AEP_TRACK_ACTION } from 'aepLibrary/aepDataCollection';
import { formatString } from 'common/utils';
import { RootState, useAppSelector } from 'core/store';

interface IToolKitServiceItem {
    title: string;
    icon: string;
    link: string;
}
const ToolKitListItem: FC<IToolKitServiceItem> = ({ title, icon, link }) => {
    const activeServiceGroupId = useAppSelector(
        (state: RootState) => state.banner.activeServiceGroupId
    );
    const activeServiceId = useAppSelector(
        (state: RootState) => state.banner.activeServices[activeServiceGroupId]
    );
    const activeProductId = useAppSelector(
        (state: RootState) => state.banner.activeProductIds[activeServiceId]
    );
    const activeEdgePlatformId = useAppSelector(
        (state: RootState) => state.banner.activeEdgePlatformId[activeProductId]
    );
    const activeEdgePlatformName = useAppSelector(
        (state: RootState) => state.banner.edgePlatformData[activeEdgePlatformId]
    );

    const handleToolkitServiceLink = (link: string) => {
        window.open(link, '_blank');
    };

    return (
        <div
            data-aep-track={AEP_TRACK.analytics}
            data-aep-track-action={AEP_TRACK_ACTION.linkClick}
            data-aep-type={`${AEP_DATA_TYPE_PREFIX.toolkit}-${formatString(activeEdgePlatformName, true)}`}
            data-aep-name={formatString(title)}
            className="toolkit-item"
            onClick={() => handleToolkitServiceLink(link)}>
            <div className="toolkit-item-icon">
                <Icon src={icon} className="toolkit-svg" />
            </div>
            <div className="toolkit-item-title">{title}</div>
            <div className="toolkit-item-link-icon">
                <Icon src={targetLinkArrowWhite} className="external-link-icon" />
            </div>
        </div>
    );
};

export default ToolKitListItem;
