import React, { FC } from 'react';
import { ToolkitItemStyle, ToolkitStyle } from './EdgeCard.styled';
import {
    EDGE_TOOLKIT_MARKETING_HEADER,
    EDGE_TOOLKIT_SALES_HEADER,
    TOOLKIT_SERVICES
} from 'core/base/const';
import { RootState, useAppSelector } from 'core/store';
import { Card, CardBody, CardHeader } from 'components/base/Card';
import ToolKitListItem from './EdgeToolKitItem';

const EdgeToolkit: FC = () => {
    const activeServiceGroupId = useAppSelector(
        (state: RootState) => state.banner.activeServiceGroupId
    );
    const activeServiceId = useAppSelector(
        (state: RootState) => state.banner.activeServices[activeServiceGroupId]
    );
    const activeProductId = useAppSelector(
        (state: RootState) => state.banner.activeProductIds[activeServiceId]
    );
    const activeEdgePlatformId = useAppSelector(
        (state: RootState) => state.banner.activeEdgePlatformId[activeProductId]
    );
    const currentEdgeCard = TOOLKIT_SERVICES.find((item) => item.id === activeEdgePlatformId);

    return (
        <ToolkitStyle>
            <Card className="motif-card-toolkit">
                <CardHeader>
                    <span className="motif-card-header-title">
                        {activeEdgePlatformId === 1 || activeEdgePlatformId === 2
                            ? EDGE_TOOLKIT_MARKETING_HEADER
                            : EDGE_TOOLKIT_SALES_HEADER}
                    </span>
                </CardHeader>
                <CardBody>
                    {currentEdgeCard && currentEdgeCard.services.length > 0 ? (
                        <ToolkitItemStyle>
                            {currentEdgeCard.services.map((data, index: number) => (
                                <ToolKitListItem key={index} {...data} />
                            ))}
                        </ToolkitItemStyle>
                    ) : null}
                </CardBody>
            </Card>
        </ToolkitStyle>
    );
};

export default EdgeToolkit;
