import styled from 'styled-components';

const MethodologyStyled = styled.div`
    background-color: #b3b4c3;
    width: 100%;
    height: 186px;
    margin-bottom: 12px;
    position: relative;

    .methodology {
        &__bg {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
        &__flex {
            z-index: 1;
            position: absolute;
            width: 100%;
            height: 100%;
            padding: 60px 35px;
            top: 0;
            left: 0;
        }

        &__title {
            font-size: 32px;
            font-weight: var(--weight-bold);
            color: #23232f;
            margin-bottom: 12px;
        }
        &__desc {
            font-size: 20px;
            font-weight: var(--weight-bold);
            color: #23232f;
        }
        &__cta {
            .motif-icon {
                margin-left: 5px;
                position: relative;
                top: 3px;
            }
        }
    }
`;

export default MethodologyStyled;
