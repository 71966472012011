import React, { FC, useRef } from 'react';
import { MotifTheme } from 'assets/lib/motif';
import AppHeaderLogo from './AppHeaderLogo';
import ProfileCard from './ProfileCard';
import Flex, { FLEX_ALIGN } from 'assets/styled/Flex';
import { Button, ToggleSwitch } from 'components/base';
import { RootState, useAppDispatch, useAppSelector } from 'core/store';
import { setTheme } from 'core/store/common.reducer';
import { StyledAppHeader } from './AppHeader.style';
import { API_ENDPOINTS, ENABLE_PERSONALIZATION, THEME } from 'core/base/const';
import { useUpdateUserThemeMutation } from 'core/api';
import { BUTTON_VARIANT } from 'components/base/Button/Button';
import { acceptAllCookies } from 'oneTrustLibrary/oneTrustLibrary';
import { aepUserConsentEvent } from 'aepLibrary/aepDataCollection';

const AppHeader: FC = () => {
    const avatarRef = useRef<HTMLElement>();
    const dispatch = useAppDispatch();
    const theme = useAppSelector((state: RootState) => state.common.theme);
    const consent = localStorage.getItem('oneTrustConsent');

    const [updateTheme] = useUpdateUserThemeMutation();
    const handleTheme = (updatedTheme: boolean) => {
        const avatar = avatarRef.current?.querySelectorAll(
            '.motif-avatar-initials'
        )[0] as HTMLElement;
        if (avatar) avatar.style.backgroundColor = '';
        const themeValue = updatedTheme ? THEME.DARK : THEME.LIGHT;
        const apiConfig = {
            url: API_ENDPOINTS.UPDATE_USER_THEME,
            data: {
                theme: themeValue
            }
        };
        dispatch(setTheme(updatedTheme));
        updateTheme(apiConfig);
    };

    return (
        <StyledAppHeader data-test-id="app-header">
            <Flex
                className="app-header__content"
                $justify={FLEX_ALIGN.SPACE_BETWEEN}
                $align={FLEX_ALIGN.CENTER}>
                <AppHeaderLogo />
                <Flex className="app-header__right" $gap={15} $align={FLEX_ALIGN.CENTER}>
                    <MotifTheme
                        variant={
                            theme
                                ? `${THEME.DARK.toLocaleLowerCase()}`
                                : `${THEME.LIGHT.toLocaleLowerCase()}`
                        }
                    />
                    {!consent ||
                        (consent === 'false' && (
                            <Button
                                className="btn-enable-personalization"
                                variant={BUTTON_VARIANT.SECONDARY}
                                type="button"
                                onClick={() => {
                                    acceptAllCookies();
                                    aepUserConsentEvent();
                                    (window as any)?.OneTrust?.AllowAll();
                                }}>
                                {ENABLE_PERSONALIZATION}
                            </Button>
                        ))}
                    <ToggleSwitch
                        data-test-id="theme-switcher"
                        id="theme-switcher"
                        name="theme-switcher"
                        checked={theme}
                        onChange={() => handleTheme(!theme)}
                        hideLabels={true}
                    />

                    <div ref={avatarRef as React.RefObject<HTMLDivElement>}>
                        <ProfileCard />
                    </div>
                </Flex>
            </Flex>
        </StyledAppHeader>
    );
};

export default React.memo(AppHeader);
