import { Button } from 'components/base';
import { BUTTON_VARIANT } from 'components/base/Button/Button';
import { PROFILE_CARD_MENU } from 'core/base/const';
import { FC } from 'react';

interface ModalFooterProps {
    handleLogoutConfirmation: (value: boolean) => void;
    handleModal: (value: boolean) => void;
}

const ModalFooter: FC<ModalFooterProps> = ({ handleLogoutConfirmation, handleModal }) => {
    return (
        <>
            <Button type="button" onClick={() => handleLogoutConfirmation(true)}>
                {PROFILE_CARD_MENU.modalActionButtonText}
            </Button>
            <Button
                variant={BUTTON_VARIANT.SECONDARY}
                type="button"
                onClick={() => handleModal(false)}>
                {PROFILE_CARD_MENU.modalCancelButtonText}
            </Button>
        </>
    );
};

export default ModalFooter;
