export const enum APIType {
    POST = 'POST',
    GET = 'GET',
    PUT = 'PUT',
    DELETE = 'DELETE'
}

export const enum HTTP_HEADER {
    ContentType = 'Content-Type',
    RequestedWith = 'X-Requested-With'
}

export const enum Endpoint {
    Users = 'api/User'
}

export const enum HTTP_STATUS_CODES {
    SUCCESS = 200,
    BAD_REQUEST = 400,
    SESSION_TIMEOUT = 401,
    UNAUTHORIZED = 403,
    NOT_FOUND = 404,
    NOT_ACCEPTABLE = 406,
    CONFLICT = 409,
    DOMAIN_MISMATCH = 427,
    CONDITIONAL = 428,
    UNAVAILABLE_LEGAL_REASON = 451,
    SERVER_ERROR = 500
}
