import { baseApi } from 'core/api/api.base';
import { withApiResponse } from 'common/utils/rtk-query.utils';
import {
    GatewayOptions,
    ITheme,
    IContentConfig,
    IQuickLinksAPIResponse,
    IDefaultPromoCard
} from 'core/base/type';
import { CommonService } from 'core/service';

export const commonApi = baseApi.injectEndpoints({
    endpoints: (builder) => ({
        getUserAvatarData: builder.query<any, GatewayOptions>({
            queryFn: async (options) =>
                withApiResponse(() => CommonService.getUserAvatarData(options))
        }),
        getUserTheme: builder.query<ITheme, GatewayOptions>({
            queryFn: async (options) => withApiResponse(() => CommonService.getUserTheme(options))
        }),
        updateUserTheme: builder.mutation<any, GatewayOptions>({
            queryFn: async (options) =>
                withApiResponse(() => CommonService.updateUserTheme(options))
        }),
        getUserRoleData: builder.query<any, GatewayOptions>({
            queryFn: async (options) =>
                withApiResponse(() => CommonService.getUserRoleData(options))
        }),
        getQuickLinks: builder.query<IQuickLinksAPIResponse, GatewayOptions>({
            queryFn: async (options) => withApiResponse(() => CommonService.getQuickLinks(options))
        }),
        getDefaultPromoCards: builder.query<IDefaultPromoCard, GatewayOptions>({
            queryFn: async (options) =>
                withApiResponse(() => CommonService.getDefaultPromoCards(options))
        }),
        getContentConfig: builder.query<IContentConfig, GatewayOptions>({
            queryFn: async (options) =>
                withApiResponse(() => CommonService.getContentConfig(options))
        })
    })
});

export const {
    useGetUserAvatarDataQuery,
    useGetUserThemeQuery,
    useUpdateUserThemeMutation,
    useGetUserRoleDataQuery,
    useGetQuickLinksQuery,
    useGetDefaultPromoCardsQuery,
    useGetContentConfigQuery
} = commonApi;
