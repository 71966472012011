import React, { ReactNode } from 'react';
import { MotifCarousel } from '@ey-xd/motif-react';

type ICarousel = {
    children: ReactNode;
    title?: string;
    auto?: boolean;
};

const Carousel: React.FC<ICarousel> = ({ children, auto = false }: ICarousel) => {
    return <MotifCarousel auto={auto}>{children}</MotifCarousel>;
};

export default Carousel;
