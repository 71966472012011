import { ReactNode, FC, useState, useEffect, useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import { useMsal } from '@azure/msal-react';
import { RootState, useAppDispatch, useAppSelector } from 'core/store';
import { setUserProfile, setUserProfilePicture } from 'core/store/userProfile.reducer';
import { SidebarContext } from '../../common/context';
import WindowService from '../../common/utils/window.utils';
import AppHeader from '../../components/custom/AppHeader';
import ErrorBoundary from '../../components/custom/ErrorBoundary/ErrorBoundary';
import AppFooter from '../../components/custom/AppFooter';
import { initializeSession, fetchLogout } from 'core/service';
import { StyledAuthorizedLayout } from './AuthorizedLayout.style';
import { API_ENDPOINTS, THEME } from 'core/base/const';
import {
    useGetUserAvatarDataQuery,
    useGetUserThemeQuery,
    useUpdateUserThemeMutation
} from 'core/api';
import { setTheme } from 'core/store/common.reducer';
import { Loader } from 'components/base';

type AuthorizedLayoutProps = {
    children: ReactNode;
};

const AuthorizedLayout: FC<AuthorizedLayoutProps> = ({ children }) => {
    const { pathname } = useLocation();
    const { instance } = useMsal();
    const dispatch = useAppDispatch();

    const [isExpanded, setIsExpanded] = useState<boolean>(false);
    const [hasError, setHasError] = useState(false);

    const userAvatarApiConfig = {
        url: API_ENDPOINTS.USER_AVATAR
    };
    const getUserThemeApiConfig = {
        url: API_ENDPOINTS.GET_USER_THEME
    };
    const { data: userAvatarData } = useGetUserAvatarDataQuery(userAvatarApiConfig);
    const { data: userThemeData, isLoading } = useGetUserThemeQuery(getUserThemeApiConfig);
    const [updateTheme] = useUpdateUserThemeMutation();

    const logoutState = useAppSelector((state: RootState) => state?.userProfile?.logout);

    const account = instance.getActiveAccount();
    const sideBarContextInitialValue = useMemo(
        () => ({
            isExpanded,
            setIsExpanded
        }),
        [isExpanded]
    );

    const handleLogout = async () => {
        await fetchLogout();
        const logoutRequest = {
            account: account ?? instance.getActiveAccount(),
            postLogoutRedirectUri: '/'
        };
        instance.logoutRedirect(logoutRequest);
    };

    useEffect(() => {
        initializeSession();
    }, []);

    useEffect(() => {
        if (account?.idTokenClaims) {
            const userData = {
                displayName: account?.name ?? '',
                email: account?.username,
                isExternal: false
            };
            dispatch(setUserProfile(userData));
        } else {
            handleLogout();
        }
    }, []);

    useEffect(() => {
        if (userAvatarData) {
            dispatch(setUserProfilePicture(`${userAvatarData?.url}`));
        }
    }, [userAvatarData]);

    useEffect(() => {
        if (!isLoading) {
            if (userThemeData?.theme) {
                const isDarkTheme = userThemeData?.theme === THEME.DARK;
                dispatch(setTheme(isDarkTheme));
            } else {
                const addUserThemeApiConfig = {
                    url: API_ENDPOINTS.UPDATE_USER_THEME,
                    data: {
                        theme: THEME.DARK
                    }
                };
                dispatch(setTheme(true));
                updateTheme(addUserThemeApiConfig);
            }
        }
    }, [isLoading, userThemeData]);

    useEffect(() => {
        if (hasError) {
            WindowService.reloadPage();
        }
    }, [pathname]);

    useEffect(() => {
        if (logoutState) {
            handleLogout();
        }
    }, [logoutState]);

    const handleError = () => {
        setHasError(true);
    };

    if (isLoading) {
        return <Loader />;
    }

    return (
        <SidebarContext.Provider value={sideBarContextInitialValue}>
            <StyledAuthorizedLayout.AppContainer>
                <AppHeader />
                <StyledAuthorizedLayout.Main as="main">
                    <StyledAuthorizedLayout.ContentWrapper>
                        <StyledAuthorizedLayout.Content>
                            <ErrorBoundary onError={handleError}>
                                <StyledAuthorizedLayout.Page as="section">
                                    {children}
                                </StyledAuthorizedLayout.Page>
                            </ErrorBoundary>
                        </StyledAuthorizedLayout.Content>
                    </StyledAuthorizedLayout.ContentWrapper>
                </StyledAuthorizedLayout.Main>
                <AppFooter />
            </StyledAuthorizedLayout.AppContainer>

            <div id="modal"></div>
        </SidebarContext.Provider>
    );
};

export default AuthorizedLayout;
