import React from 'react';
import MethodologyStyled from './Methodology.styled';
import Flex, { FLEX_ALIGN } from 'assets/styled/Flex';
import { Button, Icon } from 'components/base';
import { BUTTON_VARIANT } from 'components/base/Button/Button';
import { arrowEnter } from 'assets/lib/common/icons';
import { API_ENDPOINTS } from 'core/base/const';
import { useGetContentConfigQuery } from 'core/api';
import { AEP_DATA_TYPE_PREFIX, AEP_TRACK, AEP_TRACK_ACTION } from 'aepLibrary/aepDataCollection';
import { formatString } from 'common/utils';

interface MethodologyProps {
    title: string;
    description: string;
    image: string;
    launchUrl: string;
    launchUrlText: string;
}

const Methodology: React.FC = () => {
    const apiConfig = {
        url: API_ENDPOINTS.CONTENT_CONFIG
    };
    const { data, isSuccess } = useGetContentConfigQuery(apiConfig);

    if ((window as any)?.methodologyCardTargetResponse && isSuccess && Object.keys(data)?.length) {
        const { title, description, image, launchUrlText, launchUrl }: MethodologyProps = (
            window as any
        )?.methodologyCardTargetResponse[0];
        const handleCtaLink = () => window.open(launchUrl, '_blank', 'noopener,noreferrer');
        return (
            <MethodologyStyled>
                <img
                    className="methodology__bg"
                    src={`${data.baseUrl}/${image}${data.token}`}
                    alt={title}
                />
                <Flex
                    className="methodology__flex"
                    $justify={FLEX_ALIGN.SPACE_BETWEEN}
                    $align={FLEX_ALIGN.CENTER}
                    $gap={40}>
                    <div>
                        <h3 className="methodology__title">{title}</h3>
                        <p className="methodology__desc">{description}</p>
                    </div>
                    <div>
                        {launchUrl.length > 0 && (
                            <Button
                                className="methodology__cta"
                                variant={BUTTON_VARIANT.PRIMARY_ALT}
                                onClick={handleCtaLink}
                                data-aep-track={AEP_TRACK.analytics}
                                data-aep-track-action={AEP_TRACK_ACTION.linkClick}
                                data-aep-type={AEP_DATA_TYPE_PREFIX.methodology}
                                data-aep-name={formatString(launchUrlText)}>
                                {launchUrlText}
                                <Icon src={arrowEnter} />
                            </Button>
                        )}
                    </div>
                </Flex>
            </MethodologyStyled>
        );
    }
    return <></>;
};

export default Methodology;
