import { aepUserConsentEvent } from 'aepLibrary/aepDataCollection';
import {
    ONE_TRUST_COOKIE_SCRIPT,
    ONE_TRUST_DATA_DOMAIN,
    ONE_TRUST_EMAIL_FIELD_ID,
    ONE_TRUST_PREFERENCES_API,
    ONE_TRUST_PRIVACY_SUBMIT_BUTTON,
    ONE_TRUST_TENANT_ID,
    ONE_TRUST_WEBFORM_INSTALL_CP,
    PUBLIC_KEY,
    PURPOUSE_ID
} from 'app.constant';

window.addEventListener('OnetrustFormLoaded', () => {
    const emailField: HTMLInputElement = document.querySelector(
        'input[id="' + ONE_TRUST_EMAIL_FIELD_ID + '"]'
    )!;
    const button: HTMLInputElement = document.querySelector(
        'button[id="' + ONE_TRUST_PRIVACY_SUBMIT_BUTTON + '"]'
    )!;
    if (emailField) emailField.value = (window as any).userID;
    if (button) button.disabled = false;
});

window.addEventListener('OneTrustWebFormSubmitted', () => {
    (window as any).isCommingFromPrivacy = true;
    addCookieScript();
});

document.addEventListener('click', (element) => {
    const isRejectedCookies = (element?.srcElement as HTMLElement)?.classList.contains(
        'ot-pc-refuse-all-handler'
    );
    const isAcceptedCookies = (element?.srcElement as HTMLElement)?.classList.contains(
        'save-preference-btn-handler'
    );

    const elementId = (element?.srcElement as HTMLElement)?.id;

    if (elementId == 'onetrust-reject-all-handler' || isRejectedCookies) {
        // opt out
        rejectAllCookies();
        aepUserConsentEvent();
    } else if (
        elementId == 'onetrust-accept-btn-handler' ||
        elementId == 'accept-recommended-btn-handler'
    ) {
        // opt in
        acceptAllCookies();
        aepUserConsentEvent();
    } else if (isAcceptedCookies) {
        if (
            (document.getElementsByClassName('category-switch-handler')[0] as HTMLInputElement)
                ?.checked
        ) {
            // opt in
            acceptAllCookies();
        } else {
            // opt out
            rejectAllCookies();
        }
        aepUserConsentEvent();
    } else if (elementId == 'onetrust-pc-btn-handler') {
        const banner = document.getElementById('onetrust-banner-sdk');
        if (banner) {
            banner.style.display = 'none';
        }
    }
});

const addCookieScript = () => {
    const script = document.createElement('script');
    script.type = 'text/javascript';
    script.charset = 'UTF-8';
    script.setAttribute('data-domain-script', ONE_TRUST_DATA_DOMAIN);
    script.src = ONE_TRUST_COOKIE_SCRIPT;
    document.body.appendChild(script);
};

export const acceptAllCookies = () => {
    localStorage.setItem('oneTrustConsent', 'true');
};
const rejectAllCookies = () => {
    localStorage.setItem('oneTrustConsent', 'false');
};

export const oneTrustPrivacyNoticeinitialize = () => {
    const purposeID = PURPOUSE_ID;
    const myJWT = (window as any).generateJWT((window as any).userID, PUBLIC_KEY);
    const options = {
        method: 'GET',
        headers: {
            accept: 'application/json',
            Identifier: (window as any).userID,
            TenantId: ONE_TRUST_TENANT_ID,
            Authorization: myJWT
        }
    };
    fetch(ONE_TRUST_PREFERENCES_API + new Date().getTime(), options)
        .then((e) => e.json())
        .then((e) => {
            function t(e: { preferences?: any }) {
                function n() {
                    'object' == typeof (window as any).OneTrust &&
                        'object' == typeof (window as any).OneTrust.webform &&
                        'function' == typeof (window as any).OneTrust.webform.InstallCP &&
                        clearInterval((window as any).otc),
                        (window as any).OneTrust.webform.InstallCP(ONE_TRUST_WEBFORM_INSTALL_CP),
                        setTimeout(function () {
                            try {
                                // Show
                                (window as any).OneTrust.webform.InstallCP(
                                    ONE_TRUST_WEBFORM_INSTALL_CP
                                );
                            } catch (e) {
                                console.error('error', e);
                            }
                        }, r);
                }
                function o() {
                    clearInterval((window as any).otc), ((window as any).otc = setInterval(n, r));
                }
                const r = 500;
                let c = !0;
                if (Object.keys(e).length < 1) o();
                else if (e.preferences.length < 1) o();
                else {
                    for (let n = 0; n < e.preferences.length; n++) {
                        if (
                            'ACTIVE' !== e.preferences[n].status &&
                            e.preferences[n].id === purposeID
                        ) {
                            o();
                            break;
                        }
                        e.preferences[n].id === purposeID && (c = !1);
                    }
                    if (c) {
                        o();
                    } else {
                        addCookieScript();
                    }
                }
            }
            t(e);
        })
        .catch((e) => console.error('error', e));
};
