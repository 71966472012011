import styled from 'styled-components';
interface StyledMarketingCardItemProps {
    width?: string;
    height?: string;
    objectFit?: string;
    backgroundImageScr: string;
    labelTextPadding?: string;
}

export const StyledMarketingCardItem = styled.div<StyledMarketingCardItemProps>`
    width: ${(props) => props.width || '100%'};
    height: ${(props) => props.height || '100%'};
    background-image: linear-gradient(181deg, rgba(161, 185, 207, -50.91) 0.58%, #3c5369 200.42%),
        url(${(props) => `'${props.backgroundImageScr}'`});
    background-size: 100% 100%;
    background-position:
        right bottom,
        left top;
    background-repeat: no-repeat, repeat;
    display: flex;
    align-items: flex-end;
    cursor: pointer;

    &:hover {
        .label-div.link {
            text-decoration: underline;
        }
    }

    .banner-content {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        padding-bottom: ${(props) => props.labelTextPadding || '20px'};
        padding-left: ${(props) => props.labelTextPadding || '24px'};
        width: 86%;
        row-gap: 3px;

        .label-div {
            color: #ffffff;
            font-size: 28px;
            font-weight: bolder;
            line-height: 40px;
        }

        .category-name {
            color: #ffffff;
            font-size: 14px;
            font-weight: 700;
            line-height: 24px;
            text-transform: uppercase;
        }
    }
`;
