import { post } from '../http';
import { AxiosError } from 'axios';
import { API_ENDPOINTS } from 'core/base/const';
import { BASE_API_URL } from 'app.constant';

export const fetchLogout = async (): Promise<any> => {
    return post(`${BASE_API_URL}/${API_ENDPOINTS.LOGOUT}`, {})
        .then((response) => {
            return response;
        })
        .catch((error: AxiosError) => {
            return error;
        });
};
