import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { USER_ROLE } from 'core/base/enum';

export interface IUserProfileAction {
    displayName: string;
    email: string;
    isExternal: boolean;
    profileImage: string;
}

export type IUserProfileState = {
    loading: boolean;
    logout: boolean;
    userData: IUserProfileAction;
    logoutError: boolean;
    showPolicyModal: boolean;
    policyTypeList: Array<{
        content: string;
        hasAccepted: boolean;
        policyType: string | number;
        version: string | number;
    }>;
    role: USER_ROLE | null;
};

export const userProfileInitialState: IUserProfileState = {
    loading: true,
    logout: false,
    userData: {
        displayName: '',
        email: '',
        isExternal: false,
        profileImage: ''
    },
    logoutError: false,
    showPolicyModal: false,
    policyTypeList: [],
    role: null
};

const userProfileReducer = createSlice({
    name: 'userProfile',
    initialState: userProfileInitialState,
    reducers: {
        setUserProfile: (
            state,
            action: PayloadAction<Omit<IUserProfileAction, 'profileImage'>>
        ) => {
            const { displayName, email, isExternal } = action.payload;
            state.userData.displayName = displayName;
            state.userData.email = email;
            state.userData.isExternal = isExternal;
            state.loading = false;
        },
        setUserProfilePicture: (state, action: PayloadAction<string>) => {
            state.userData.profileImage = action.payload;
            state.loading = false;
        },
        setUserLogout: (state, action: PayloadAction<boolean>) => {
            state.loading = false;
            state.logout = action.payload;
        },
        setUserLogoutError: (state, action: PayloadAction<boolean>) => {
            state.loading = false;
            state.logoutError = action.payload;
        },
        showPolicyModal: (state, action) => {
            (state.showPolicyModal = action.payload.showPolicy),
                (state.policyTypeList = action.payload.policyTypeList);
        },
        setUserRole: (state, action: PayloadAction<USER_ROLE>) => {
            state.role = action.payload;
        }
    }
});

export const {
    setUserProfile,
    setUserProfilePicture,
    setUserLogout,
    setUserLogoutError,
    showPolicyModal,
    setUserRole
} = userProfileReducer.actions;
export default userProfileReducer.reducer;
