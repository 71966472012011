import styled from 'styled-components';
interface StyledMarketingCardsProps {
    width?: string;
    height?: string;
    objectFit?: string;
    showNextPreviousControls?: boolean;
}

export const StyledMarketingCards = styled.div<StyledMarketingCardsProps>`
    width: ${(props) => props.width || '100%'};
    height: ${(props) => props.height || '20rem'};
    display: flex;
    flex-direction: row;
    column-gap: 0.35rem;
    margin-bottom: 0.4375rem;
    position: relative;

    .image-carousel {
        width: 65%;
        height: 100%;
    }

    .chat-box-container {
        width: 35%;
        height: 100%;
        background-color: var(--ska--container-bg-color);
        display: flex;
        flex-direction: column;
    }
`;
