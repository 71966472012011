import React, { StrictMode } from 'react';
import ReactDOM from 'react-dom/client';
import { Provider as StoreProvider } from 'react-redux';
import { BrowserRouter as Router } from 'react-router-dom';
import {
    PublicClientApplication,
    EventType,
    EventMessage,
    AuthenticationResult
} from '@azure/msal-browser';
import { ThemeContext } from 'styled-components';
import { msalConfig } from './core/configs/authConfig';
import { store } from './core/store';
import App from './App';
import { GlobalStyles, theme } from './assets/styled';
import './assets/lib/motif/style.scss';
import { sha256 } from 'js-sha256';
import { aepPageViewEvent } from 'aepLibrary/aepDataCollection';
import { oneTrustPrivacyNoticeinitialize } from 'oneTrustLibrary/oneTrustLibrary';

export const msalInstance = new PublicClientApplication(msalConfig);

msalInstance.initialize().then(() => {
    const accounts = msalInstance.getAllAccounts();
    if (accounts.length > 0) {
        msalInstance.setActiveAccount(accounts[0]);
        (window as any).userID = sha256(accounts[0].username.trim().toLocaleLowerCase());
        oneTrustPrivacyNoticeinitialize();
        aepPageViewEvent();
    }

    msalInstance.addEventCallback((event: EventMessage) => {
        if (event.eventType === EventType.LOGIN_SUCCESS && event.payload) {
            const payload = event.payload as AuthenticationResult;
            const account = payload.account;

            msalInstance.setActiveAccount(account);
            (window as any).userID = sha256(account.username.trim().toLocaleLowerCase());
            oneTrustPrivacyNoticeinitialize();
            aepPageViewEvent();
        }
    });

    const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
    root.render(
        <StrictMode>
            <ThemeContext.Provider value={theme}>
                <GlobalStyles />
                <Router>
                    <StoreProvider store={store}>
                        <App pca={msalInstance} />
                    </StoreProvider>
                </Router>
            </ThemeContext.Provider>
        </StrictMode>
    );
});
