import { Configuration, InteractionRequiredAuthError, PopupRequest } from '@azure/msal-browser';
import { BASE_APP_URL, CLIENT_ID, TENANT } from 'app.constant';
import { msalInstance } from 'index';

// Config object to be passed to Msal on creation
export const msalConfig: Configuration = {
    auth: {
        clientId: CLIENT_ID,
        authority: `https://login.microsoftonline.com/${TENANT}`,
        redirectUri: BASE_APP_URL,
        postLogoutRedirectUri: BASE_APP_URL,
        navigateToLoginRequestUrl: true
    },
    cache: {
        cacheLocation: 'localStorage'
    },
    system: {
        allowNativeBroker: false, // Disables WAM Broker
        allowRedirectInIframe: true
    }
};

// Add here scopes for id token to be used at MS Identity Platform endpoints.
export const loginRequest: PopupRequest = {
    scopes: ['User.Read']
};

// Add here the endpoints for MS Graph API services you would like to use.
export const graphConfig = {
    graphMeEndpoint: 'https://graph.microsoft.com/v1.0/me'
};

export const getAccessTokenRequest = async (): Promise<string> => {
    // MSAL.js v2 exposes several account APIs, logic to determine which account to use is the responsibility of the developer
    const account = await msalInstance.getAllAccounts()[0];

    const accessTokenRequest = {
        scopes: [`${CLIENT_ID}/.default`],
        account: account
    };

    let token = '';

    // Use the same publicClientApplication instance provided to MsalProvider
    await msalInstance
        .acquireTokenSilent(accessTokenRequest)
        .then((accessTokenResponse) => {
            // Acquire token silent success
            token = accessTokenResponse.idToken;
        })
        .catch((error) => {
            //Acquire token silent failure
            if (error instanceof InteractionRequiredAuthError) {
                msalInstance.acquireTokenRedirect(accessTokenRequest);
            }
            console.log(error);
        });

    return token;
};
