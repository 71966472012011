import React, { FC, useEffect, useState } from 'react';
import { PROFILE_CARD_MENU } from '../../../../core/base/const';
import Dropdown from '../../../base/Dropdown';
import Modal, { ModalSize } from '../../../base/Modal';
import StyledProfileCard from './ProfileCard.style';
import { RootState, useAppDispatch, useAppSelector } from 'core/store';
import { setUserLogout } from 'core/store/userProfile.reducer';
import DropdownChildrens, { IDropdownItem } from './DropdownChildrens';
import { logoutIcon, manageContent } from 'assets/lib/common/icons';
import { ModalBody, ModalFooter } from './Modal';
import { USER_ROLE } from 'core/base/enum';

const ProfileCard: FC = () => {
    const dispatch = useAppDispatch();
    const [showDropdown, setDropdown] = useState<boolean>(false);
    const [showModal, setModal] = useState<boolean>(false);
    const [confirmLogout, setConfirmLogout] = useState<boolean>(false);
    const data: IDropdownItem[] = [
        {
            name: PROFILE_CARD_MENU.logOut,
            icon: logoutIcon
        }
    ];
    const userRole = useAppSelector((state: RootState) => state?.userProfile?.role);

    if (userRole === USER_ROLE.ADMIN_USER) {
        data.unshift({
            name: PROFILE_CARD_MENU.manageContent,
            icon: manageContent
        });
    }

    const logoutErrorState = useAppSelector((state: RootState) => state?.userProfile?.logoutError);
    const {
        displayName: userName,
        email: userEmail,
        profileImage
    } = useAppSelector((state: RootState) => state?.userProfile?.userData);

    const triggerId = 'dropdown-trigger';

    useEffect(() => {
        if (confirmLogout) {
            dispatch(setUserLogout(true));
            if (logoutErrorState) {
                setModal(false);
            }
        }
    }, [confirmLogout]);

    const handleMenu = (): void => {
        setDropdown(!showDropdown);
    };

    const handleClickOutside = (): void => {
        setDropdown(false);
    };

    return (
        <>
            <Dropdown
                open={showDropdown}
                aria-labelledby={triggerId}
                handleClickOutside={handleClickOutside}
                trigger={
                    <StyledProfileCard.Avatar
                        userName={userName}
                        description={userEmail}
                        src={profileImage}
                        onClick={handleMenu}
                    />
                }
                data-test-id="app-header-profile-card">
                <DropdownChildrens
                    data={data}
                    handleModal={setModal}
                    toggleDropdown={setDropdown}
                />
            </Dropdown>
            <Modal
                show={showModal}
                size={ModalSize.SMALL}
                headerContent={PROFILE_CARD_MENU.modalHeaderText}
                onClose={() => setModal(false)}
                footerContent={
                    <ModalFooter
                        handleLogoutConfirmation={setConfirmLogout}
                        handleModal={setModal}
                    />
                }>
                <ModalBody logoutErrorState={logoutErrorState} />
            </Modal>
        </>
    );
};

export default React.memo(ProfileCard);
