import { FC, useEffect } from 'react';
import {
    MsalAuthenticationTemplate,
    MsalAuthenticationResult,
    IMsalContext,
    useIsAuthenticated
} from '@azure/msal-react';
import { InteractionType } from '@azure/msal-browser';
import { AuthorizedLayout, GuestLayout } from '.';
import AppIntroduction from 'components/custom/AppIntroduction/AppIntroduction';
import Policies from 'Pages/Policies';
import { RootState, useAppDispatch, useAppSelector } from 'core/store';
import { loginRequest } from 'core/configs/authConfig';
import { Loader } from 'components/base';
import { FallBack } from 'components/custom/ErrorBoundary';
import { useGetUserRoleDataQuery } from 'core/api';
import { API_ENDPOINTS } from 'core/base/const';
import { setUserRole } from 'core/store/userProfile.reducer';
import AppUnauthorised from 'components/custom/AppUnauthorised';
import { HTTP_STATUS_CODES } from 'core/base/enum';

type AppLayoutProps = {
    children?: React.ReactNode;
};

interface RenderComponentProps {
    children: React.ReactNode;
    showPolicyModal: boolean;
}

const RenderComponent: FC<RenderComponentProps> = ({ children, showPolicyModal }) => {
    if (showPolicyModal) {
        return <Policies />;
    }
    return <AuthorizedLayout>{children}</AuthorizedLayout>;
};

const ErrorComponent: FC<MsalAuthenticationResult> = ({ error }) => {
    return <FallBack reloadDocument={true} message={error?.errorMessage} />;
};
const LoadingComponent: FC<IMsalContext> = () => {
    return <Loader fullScreen={true} />;
};

const AppLayout: FC<AppLayoutProps> = ({ children }) => {
    const isAuthenticated = useIsAuthenticated();
    const dispatch = useAppDispatch();
    const userRoleApiConfig = {
        url: API_ENDPOINTS.USER_ROLE
    };
    const {
        data: userRole,
        isLoading: userRoleLoading,
        isSuccess: userRoleSuccess,
        error: userRoleError
    } = useGetUserRoleDataQuery(userRoleApiConfig, {
        skip: !isAuthenticated
    });

    const showPolicyModal = useAppSelector(
        (state: RootState) => state?.userProfile?.showPolicyModal
    );
    const isUnauthorized = useAppSelector((state: RootState) => state?.common?.isUnauthorized);
    useEffect(() => {
        if (userRole?.role) {
            dispatch(setUserRole(userRole?.role));
        }
    }, [userRole]);

    if (userRoleLoading) {
        return <Loader fullScreen={true} />;
    }

    return (
        <>
            {isAuthenticated && userRoleSuccess && !isUnauthorized ? (
                <MsalAuthenticationTemplate
                    interactionType={InteractionType.Redirect}
                    authenticationRequest={loginRequest}
                    errorComponent={ErrorComponent}
                    loadingComponent={LoadingComponent}>
                    <RenderComponent showPolicyModal={showPolicyModal}>{children}</RenderComponent>
                </MsalAuthenticationTemplate>
            ) : isAuthenticated &&
              (userRoleError?.code === HTTP_STATUS_CODES.NOT_FOUND || isUnauthorized) ? (
                <GuestLayout>
                    <AppUnauthorised />
                </GuestLayout>
            ) : (
                <GuestLayout>
                    <AppIntroduction />
                </GuestLayout>
            )}
        </>
    );
};

export default AppLayout;
