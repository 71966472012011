import React, { FC } from 'react';
import { Chip, Icon, Tooltip } from 'components/base';
import { RootState, useAppDispatch, useAppSelector } from 'core/store';
import { TooltipPlacement } from 'components/base/Tooltip/Tooltip';
import { ServiceMapCellStyle, ServiceMapProductStyle } from './ServiceMapCell.style';
import { theme } from 'assets/styled';
import { IGridMatrixItems, IGridMatrixProducts } from 'core/base/type';
import { COMING_SOON } from 'core/base/const';
import { SERVICE_GROUP } from 'core/base/enum';
import {
    setActiveProductId,
    setActiveServices,
    setEdgePlatformId
} from 'core/store/banners.reducer';
import { setActiveCell, setActiveProduct } from 'core/store/banners.reducer';
import { imageIcNavigateNext24px } from 'assets/lib/motif/icons';
import Flex, { FLEX_ALIGN } from 'assets/styled/Flex';
import { AEP_DATA_TYPE_PREFIX, AEP_TRACK, AEP_TRACK_ACTION } from 'aepLibrary/aepDataCollection';
import { formatString } from 'common/utils';

interface IServiceMapCellProps {
    columnData: IGridMatrixItems[];
}

const ServiceMapCell: FC<IServiceMapCellProps> = ({ columnData }) => {
    const dispatch = useAppDispatch();

    const isDarkTheme = useAppSelector((state: RootState) => state.common.theme);
    const activeServiceGroupId = useAppSelector((state) => state.banner.activeServiceGroupId);
    const activeServiceGroupName = useAppSelector(
        (state) => state.common.serviceGroupData[activeServiceGroupId]
    );
    const activeServiceId = useAppSelector(
        (state) => state.banner.activeServices[activeServiceGroupId]
    );
    const activeServiceName = useAppSelector((state) => state.common.servicesData[activeServiceId]);
    const activeCell = useAppSelector(
        (state: RootState) => state.banner.activeCells[activeServiceId]
    );
    const activeProductId = useAppSelector(
        (state: RootState) => state.banner.activeProductIds[activeServiceId]
    );
    const edgePlatformData = useAppSelector((state: RootState) => state.banner.edgePlatformData);

    const themeName = isDarkTheme ? 'dark' : 'light';

    const handleApplicationSelect = (row: number, column: number, product: IGridMatrixProducts) => {
        const { serviceId, services, id: productId, name: productName, edgePlatformId } = product;
        const includesServiceId = services.includes(activeServiceId);
        const currentServiceId: number = includesServiceId ? activeServiceId : serviceId;
        if (!includesServiceId) {
            dispatch(setActiveServices(serviceId));
        }
        dispatch(setEdgePlatformId({ productId, edgeId: edgePlatformId }));
        dispatch(setActiveCell({ serviceId: currentServiceId, cellData: [row, column] }));
        dispatch(setActiveProduct({ serviceId: currentServiceId, productId }));
        dispatch(setActiveProductId({ id: productId, name: productName }));
    };

    const handleActiveCell = (row: number, column: number, products: IGridMatrixProducts[]) => {
        const selected = theme[themeName].serviceMap.cellActiveBgColor;
        const highlighted = theme[themeName].serviceMap.cellHighlightBgColor;

        const hasRelatedServicesIds = products[0].services.includes(activeServiceId);

        switch (activeServiceGroupId) {
            case SERVICE_GROUP.strategy:
                if (row === activeCell[0] && column === activeCell[1] && hasRelatedServicesIds) {
                    return selected;
                } else if (hasRelatedServicesIds) {
                    return highlighted;
                }
                break;

            case SERVICE_GROUP.execution:
                if (hasRelatedServicesIds) {
                    return selected;
                }
                break;
            default:
                if (row === activeCell[0] && column === activeCell[1]) {
                    return selected;
                }
                break;
        }

        // Other cells should be default
        return theme[themeName].serviceMap.cellBgColor;
    };

    const handleActiveProduct = (
        row: number,
        column: number,
        productId: number,
        serviceId: number
    ) => {
        const selectedState = {
            color: theme[themeName].serviceMap.chipActiveColor,
            bgColor: theme[themeName].serviceMap.cellActiveBgColor
        };
        const highlightedState = {
            color: theme[themeName].serviceMap.cellColor,
            bgColor: theme[themeName].serviceMap.cellHighlightBgColor
        };
        const defaultState = {
            color: theme[themeName].serviceMap.cellColor,
            bgColor: theme[themeName].serviceMap.cellBgColor
        };

        switch (activeServiceGroupId) {
            case SERVICE_GROUP.strategy:
                selectedState.bgColor = 'transparent';
                highlightedState.bgColor = 'transparent';
                defaultState.bgColor = 'transparent';
                if (
                    productId === activeProductId &&
                    row === activeCell[0] &&
                    column === activeCell[1]
                ) {
                    return selectedState;
                }
                break;

            default:
                if (
                    productId === activeProductId &&
                    row === activeCell[0] &&
                    column === activeCell[1]
                ) {
                    return selectedState;
                } else if (
                    row === activeCell[0] &&
                    column === activeCell[1] &&
                    serviceId === activeServiceId
                ) {
                    return highlightedState;
                }
                break;
        }

        return defaultState;
    };

    return (
        <>
            {columnData.map(({ row, column, products }, index) => (
                <ServiceMapCellStyle
                    key={index}
                    $rowBgColor={handleActiveCell(row[0], column[0], products)}
                    $gridColumnStart={column[0]}
                    $gridColumnEnd={column[1]}
                    $gridRowStart={row[0]}
                    $gridRowEnd={row[1]}>
                    {products?.map((product) => (
                        <ServiceMapProductStyle
                            $chipColor={
                                handleActiveProduct(
                                    row[0],
                                    column[0],
                                    product.id,
                                    product.serviceId
                                )?.color
                            }
                            $chipBgColor={
                                handleActiveProduct(
                                    row[0],
                                    column[0],
                                    product.id,
                                    product.serviceId
                                )?.bgColor
                            }
                            $chipOrder={product.orderId}
                            key={product.name}
                            className="service-map__chip">
                            <Chip
                                data-aep-track={AEP_TRACK.analytics}
                                data-aep-track-action={AEP_TRACK_ACTION.linkClick}
                                data-aep-type={`${AEP_DATA_TYPE_PREFIX.product}-${formatString(activeServiceGroupName, true)}-${formatString(activeServiceName, true)}`}
                                data-aep-name={formatString(product.name)}
                                onClick={() => handleApplicationSelect(row[0], column[0], product)}
                                data-test-id={`service-map-chip__${product.id}`}>
                                <div className="service-map__chip-product">
                                    <Tooltip
                                        content={
                                            <>
                                                <h4 className="service-map__tooltip-header">
                                                    <strong>
                                                        {edgePlatformData[product.edgePlatformId]}
                                                    </strong>
                                                    &nbsp;-&nbsp;{product.name}
                                                </h4>
                                                <p>{product.description}</p>
                                            </>
                                        }
                                        placement={TooltipPlacement.BOTTOM}
                                        hide={!product.description || product.description === ''}
                                        hideArrow={true}>
                                        <Flex
                                            className="service-map__chip-wrapper"
                                            $gap={5}
                                            $align={FLEX_ALIGN.CENTER}>
                                            <Flex>
                                                <div className="service-map__chip-name">
                                                    {product.name}
                                                </div>
                                                <Icon
                                                    src={imageIcNavigateNext24px}
                                                    className="service-map__chip-arrow"
                                                />
                                            </Flex>
                                            {!product.isActive && (
                                                <Chip className="service-map__coming-soon-chip">
                                                    {COMING_SOON}
                                                </Chip>
                                            )}
                                        </Flex>
                                    </Tooltip>
                                </div>
                            </Chip>
                        </ServiceMapProductStyle>
                    ))}
                </ServiceMapCellStyle>
            ))}
        </>
    );
};
export default React.memo(ServiceMapCell);
