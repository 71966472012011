import styled from 'styled-components';

export const StyleAppFooter = styled.div`
    .footer-wrapper {
        margin-left: auto;
        margin-right: auto;
        min-width: ${({ theme }) => theme.pageWrapper.minWidth};
        max-width: ${({ theme }) => theme.pageWrapper.maxWidth};
        .verified-logo {
            margin-right: 0.5rem;
            svg {
                fill: var(--icon-color);
            }
        }
    }
    .global-footer {
        height: auto;

        &__links {
            font-style: normal;
            font-weight: var(--weight-regular);
            font-size: 14px;
        }

        &__right {
            text-align: right;
            padding: 0px 20px;
        }

        &__text {
            text-align: left;
            flex: 2;
        }
    }
`;
