import styled from 'styled-components';

export const VideoPlayerStyle = styled.div<{ $videoUrl: string }>`
    position: relative;
    aspect-ratio: 16 / 9;
    cursor: ${({ $videoUrl }) => ($videoUrl ? 'pointer' : 'auto')};

    .video-wrapper__image-not-found {
        width: 100%;
        height: 100%;
        background-color: rgb(58, 58, 74);
        display: flex;
        -webkit-box-align: center;
        align-items: center;
        -webkit-box-pack: center;
        justify-content: center;
        position: relative;
        flex-direction: column;

        .video-wrapper__image-error {
            color: var(--card-header-branding);
            font-size: 16px;
            font-weight: 400;
            margin-top: 10px;
        }
    }

    .video-wrapper__thumbnail-image {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }

    .video-wrapper__play-btn {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        cursor: pointer;

        svg {
            width: 44px;
            height: 44px;
        }
    }

    .video-wrapper__image-name {
        color: #fff;
        position: absolute;
        width: 100%;
        bottom: 0px;
        background-color: #747480;
        padding: 10px 0px;
        text-align: center;
        font-size: 16px;
        font-weight: 400;
    }

    video {
        width: 100%;
        height: 100%;
        position: absolute;
        left: 0;
        right: 0;
    }
`;
