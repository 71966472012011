import React from 'react';
// { useState }
import { FOOTER, FOOTER_LEAGAL_SECTION } from '../../../core/base/const';
// import FooterModal from './FooterModal/FooterModal';
import Footer from 'components/base/Footer';
import { Button } from 'components/base';
import Flex, { FLEX_ALIGN, FLEX_WRAP } from 'assets/styled/Flex';
import { StyleAppFooter } from './AppFooter.style';
import { RootState, useAppSelector } from 'core/store';

const AppFooter: React.FC = () => {
    // const [modalType, setModalType] = useState<string>('');
    // const [showModal, setShowModal] = useState<boolean>(false);

    const showPolicyModal = useAppSelector(
        (state: RootState) => state?.userProfile?.showPolicyModal
    );

    // const handleShowModal = (type: string) => {
    //     if (showPolicyModal) return;
    //     setModalType(type);
    //     setShowModal(true);
    // };

    return (
        <StyleAppFooter>
            {/* <FooterModal show={showModal} type={modalType} onClose={() => setShowModal(false)} /> */}
            <Footer className="global-footer">
                <div className="footer-wrapper">
                    <Flex $gap={60} $wrap={FLEX_WRAP.WRAP}>
                        <div className="global-footer__text">{FOOTER_LEAGAL_SECTION}</div>
                        <Flex
                            $gap={32}
                            $wrap={FLEX_WRAP.NO_WRAP}
                            $justify={FLEX_ALIGN.FLEX_END}
                            $align={FLEX_ALIGN.FLEX_START}
                            className="global-footer__right">
                            <Button
                                variant="text"
                                className="global-footer__links"
                                onClick={() => (window as any).OneTrust.ToggleInfoDisplay()}
                                disabled={showPolicyModal}>
                                <span className="verified-logo">
                                    <svg
                                        width="16"
                                        height="20"
                                        viewBox="0 0 16 20"
                                        xmlns="http://www.w3.org/2000/svg">
                                        <path d="M8 0.833984L0.5 4.16731V9.16731C0.5 13.7923 3.7 18.1173 8 19.1673C12.3 18.1173 15.5 13.7923 15.5 9.16731V4.16731L8 0.833984ZM7.16666 5.83399H8.83334V7.50065H7.16666V5.83399ZM7.16666 9.16731H8.83334V14.1673H7.16666V9.16731Z" />
                                    </svg>
                                </span>
                                {FOOTER.dataPrivacyCenter}
                            </Button>
                        </Flex>
                    </Flex>
                </div>
            </Footer>
        </StyleAppFooter>
    );
};

export default React.memo(AppFooter);
