import styled from 'styled-components';

export const StyledAppHeader = styled.div`
    --toggle-switch--height: 12px;
    --toggle-switch-thumb--width: 6px;
    --toggle-switch-thumb--height: 6px;
    --toggle-switch--border-width: 2px;

    --toggle-switch-thumb--bg-color: #1a1a24;
    --toggle-switch--bg-color: transparent;
    --toggle-switch--border-color: #2e2e38;
    --toggle-switch--border-style: solid;
    --toggle-switch--border-width: 2px;
    --toggle-switch--border-radius: 12px;

    --toggle-switch-hover--bg-color: transparent;
    --toggle-switch-hover--border-color: #2e2e38;
    --toggle-switch-hover--border-style: solid;
    --toggle-switch-hover--border-width: 2px;
    --toggle-switch-hover--border-radius: 12px;

    --toggle-switch-checked-thumb--bg-color: #fff;
    --toggle-switch-checked--bg-color: transparent;
    --toggle-switch-checked--border-color: #fff;
    --toggle-switch-checked--border-style: solid;
    --toggle-switch-checked--border-width: 2px;
    --toggle-switch-checked--border-radius: 12px;

    --toggle-switch-checked-hover-thumb--bg-color: #fff;
    --toggle-switch-checked-hover--bg-color: transparent;
    --toggle-switch-checked-hover--border-color: #fff;
    --toggle-switch-checked-hover--border-style: solid;
    --toggle-switch-checked-hover--border-width: 2px;
    --toggle-switch-checked-hover--border-radius: 12px;

    background-color: var(--header--bg-color);
    border-style: var(--header--border-style);
    border-width: var(--header--border-width);
    border-color: var(--header--border-color);
    position: sticky;
    top: 0;
    left: 0;
    padding: 8px 0px;
    z-index: 99;

    .btn-enable-personalization {
        padding: 5px;
        font-size: 12px;
    }

    .motif-header-logo {
        display: flex;
        gap: 12px;
        align-items: center;

        a {
            flex: 0 0 40px;
            width: 40px;
            height: 45px;
        }

        svg {
            background-color: var(--header-logo--bg-color);
            padding: 0 0.5714285714rem;
        }
    }

    .motif-toggle-switch {
        width: 22px;
        margin: 0;
    }

    .motif-dropdown-item .motif-icon path {
        fill: var(--dropdown-menu-item--fill);
    }

    .app-header {
        &__content {
            margin-left: auto;
            margin-right: auto;
            max-width: ${({ theme }) => theme?.pageWrapper?.maxWidth};
            padding: 0px 20px;
        }
        &__title,
        &__divider,
        &__caption {
            font-weight: var(--weight-bold);
            color: var(--header-title--color);
            font-size: 24px;
            line-height: 1.7142857143rem;
        }
        &__titlegroup {
            column-gap: 12px;
        }
        &__caption {
            color: var(--app-header-caption-color);
        }
    }

    .motif-toggle-switch-checked {
        .motif-toggle-switch .motif-toggle-switch-slider:before {
            left: -8px;
        }
    }
`;
