import { FC, ReactNode } from 'react';
import { MotifChip } from 'assets/lib/motif';

type ChipProps = {
    children: string | ReactNode;
    className?: string;
    title?: string;
    onClick?: () => void;
};
const Chip: FC<ChipProps> = ({
    children = '',
    className = '',
    title,
    onClick,
    ...other
}: ChipProps) => {
    return (
        <MotifChip className={className} title={title} onClick={onClick} {...other}>
            {children}
        </MotifChip>
    );
};

export default Chip;
