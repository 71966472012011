import {
    GatewayOptions,
    IBannerNavigationApiResponse,
    ICaseStudiesData,
    IEdgeCardApiResponse,
    IServiceMapApiResponse,
    IServicesStageApiResponse,
    ITrainingData
} from 'core/base/type';
import { baseApi } from './api.base';
import { withApiResponse } from 'common/utils/rtk-query.utils';
import { BannerService } from 'core/service';

export const bannerApi = baseApi.injectEndpoints({
    endpoints: (builder) => ({
        getBannerNavigationData: builder.query<IBannerNavigationApiResponse, GatewayOptions>({
            queryFn: async (options) =>
                withApiResponse(() => BannerService.getBannerNavigationData(options))
        }),
        getServiceMapData: builder.query<IServiceMapApiResponse, GatewayOptions>({
            queryFn: async (options) =>
                withApiResponse(() => BannerService.getServiceMapData(options))
        }),
        getServiceStagesData: builder.query<IServicesStageApiResponse, GatewayOptions>({
            queryFn: async (options) =>
                withApiResponse(() => BannerService.getServiceStagesData(options))
        }),
        getEdgeCardData: builder.query<IEdgeCardApiResponse, GatewayOptions>({
            queryFn: async (options) =>
                withApiResponse(() => BannerService.getEdgeCardData(options))
        }),
        getTrainingData: builder.query<ITrainingData[], GatewayOptions>({
            queryFn: async (options) =>
                withApiResponse(() => BannerService.getTrainingData(options))
        }),
        getCaseStudiesData: builder.query<ICaseStudiesData, GatewayOptions>({
            queryFn: async (options) =>
                withApiResponse(() => BannerService.getCaseStudiesData(options))
        })
    })
});

export const {
    useGetBannerNavigationDataQuery,
    useGetServiceMapDataQuery,
    useGetServiceStagesDataQuery,
    useGetEdgeCardDataQuery,
    useGetTrainingDataQuery,
    useGetCaseStudiesDataQuery
} = bannerApi;
