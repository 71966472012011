import { ReactNode } from 'react';
import { MotifCardBody } from 'assets/lib/motif';
import styled from 'styled-components';

interface ICardBodyProps {
    children: ReactNode;
    className?: string;
}

const CardBody = styled(({ children, className = '' }: ICardBodyProps) => {
    return <MotifCardBody className={className}>{children}</MotifCardBody>;
})<{
    height?: string;
}>`
    position: relative;
    /* padding-left: ${({ theme }) => theme?.card?.horizontalPadding};
    padding-right: ${({ theme }) => theme?.card?.horizontalPadding}; */
    padding-left: 1.5rem;
    padding-right: 1.5rem;
    .motif-progress-loader-container {
        --progress-loader--bg-color: var(--card--bg-color);
    }
`;

export default CardBody;
