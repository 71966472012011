export enum SortKeys {
    updatedAt = 'UpdatedAt',
    audience = 'Audience'
}

export enum SortValues {
    asc = 'asc',
    dsc = 'desc',
    default = ''
}

export enum OFFER_LIBRARY_TABLE_HEADERS {
    title = 'Offer',
    updatedAt = 'Last Updated Date',
    audience = 'Audience',
    category = 'Category',
    status = 'Status',
    action = ''
}

export enum OFFER_LIBRARY_TABLE_STATUS {
    draft = 'Draft',
    published = 'Published'
}
