import { combineReducers } from 'redux';
import { reducer as apiReducer, reducerPath as apiReducerPath } from '../api/api.base';
import userProfile from './userProfile.reducer';
import common from './common.reducer';
import banner from './banners.reducer';

const rootReducer = combineReducers({
    userProfile,
    common,
    banner,
    [apiReducerPath]: apiReducer
});

export default rootReducer;

export type RootState = ReturnType<typeof rootReducer>;
