import { FC, useEffect } from 'react';
import { useAppSelector, RootState, useAppDispatch } from 'core/store';
import { API_ENDPOINTS, NO_DATA_AVAILABLE } from 'core/base/const';
import { useGetEdgeCardDataQuery } from 'core/api';
import { ErrorMessage, Loader } from 'components/base';
import { Card } from 'components/base/Card';
import MarketingCards from 'components/custom/MarketingCards';
import HomeBanner from 'components/custom/HomeBanner';
import QuickLinks from 'components/custom/QuickLinks';
import Training from 'components/custom/Training';
import CaseStudies from 'components/custom/CaseStudies';
import EdgeCard from 'components/custom/EdgeCard';
import { HomePageStyled } from './Home.styled';
import { setEdgePlatformData, setCaseStudiesUrl } from 'core/store/banners.reducer';
import LayoutContainer from 'assets/styled/LayoutContainer';
import Methodology from 'components/custom/Methodology';
import ServiceMap from 'components/custom/ServiceMap';
import WelComeMessage from 'components/custom/WelComeMessage';

const Home: FC = () => {
    const dispatch = useAppDispatch();
    /* Edge card details will always loaded when user logged-in */
    const apiConfig = {
        url: API_ENDPOINTS.GET_EDGE_CARD_DATA
    };
    const { data, isLoading, isError } = useGetEdgeCardDataQuery(apiConfig);

    const activeServiceGroupId = useAppSelector(
        (state: RootState) => state.banner.activeServiceGroupId
    );
    const activeServices = useAppSelector((state: RootState) => state.banner.activeServices);
    const showHomePageWidget = activeServices[activeServiceGroupId] !== undefined ? true : false;
    const activeServiceId = useAppSelector(
        (state) => state.banner.activeServices[activeServiceGroupId]
    );
    const activeProductId = useAppSelector(
        (state: RootState) => state.banner.activeProductIds[activeServiceId]
    );
    useEffect(() => {
        if (data && data?.edgeCardDetails?.length > 0) {
            const edgeCardData: {
                [key: number]: string;
            } = {};
            const caseStudiesUrl: {
                [key: number]: string;
            } = {};
            data.edgeCardDetails.forEach((item) => {
                edgeCardData[item.id] = item.name;
                caseStudiesUrl[item.id] = item.caseStudyUrl;
            });

            dispatch(setEdgePlatformData(edgeCardData));
            dispatch(setCaseStudiesUrl(caseStudiesUrl));
        }
    }, [data]);

    return (
        <LayoutContainer>
            <WelComeMessage />
            <MarketingCards />
            <HomeBanner />
            {showHomePageWidget && (
                <HomePageStyled.PageWrapper>
                    <HomePageStyled.WidgetWrapper>
                        <HomePageStyled.LeftSection>
                            <ServiceMap />
                            <div className="inner-section">
                                <Training productID={activeProductId} />
                                <CaseStudies />
                            </div>
                            {/* <Suggestion /> */}
                        </HomePageStyled.LeftSection>
                        <HomePageStyled.RightSection>
                            <Card className="right-section__edge-card">
                                {isLoading && <Loader />}
                                {isError && (
                                    <ErrorMessage className="right-section__error-card">
                                        {NO_DATA_AVAILABLE}
                                    </ErrorMessage>
                                )}
                                {data && data?.edgeCardDetails?.length > 0 && (
                                    <EdgeCard currentEdgeCardDetails={data.edgeCardDetails} />
                                )}
                            </Card>
                        </HomePageStyled.RightSection>
                    </HomePageStyled.WidgetWrapper>
                    <QuickLinks />
                </HomePageStyled.PageWrapper>
            )}
            <Methodology />
        </LayoutContainer>
    );
};

export default Home;
