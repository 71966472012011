import { Button, ErrorMessage, Icon, Input } from 'components/base';
import { useState } from 'react';
import { sendMessage } from 'assets/lib/common/icons';
import { StyledKnowledgeAssistant } from './KnowledgeAssistant.styled';
import { KNOWLEDGE_ASSISTANT_URL } from 'app.constant';
import { KNOWLEDGE_ASSISTANT_ERROR_MESSAGES, KNOWLEDGE_ASSISTANT } from 'core/base/const';
import { formatString, updateQueryStringParameter } from 'common/utils';
import { AEP_DATA_TYPE_PREFIX, AEP_TRACK, AEP_TRACK_ACTION } from 'aepLibrary/aepDataCollection';
import SKA_Logo_Dark_Theme from 'assets/lib/common/images/SKA_Logo_Dark_Theme.svg';
import SKA_Logo_Light_Theme from 'assets/lib/common/images/SKA_Logo_Light_Theme.svg';
import { RootState, useAppSelector } from 'core/store';
import SKA_Bg_Dark from 'assets/lib/common/images/SKA_Bg_Dark.svg';
import SKA_Bg_Light from 'assets/lib/common/images/SKA_Bg_Light.svg';

const KnowledgeAssistant = () => {
    const charCount = 4000;
    const isDarkTheme = useAppSelector((state: RootState) => state.common.theme);
    const [inputValue, setInputValue] = useState('');
    const [errorMessage, setErrorMessage] = useState('');

    const handleAssistantInputChange = (e: string) => {
        if (e === '') setErrorMessage('');
        setInputValue(e);
    };

    const handleAssistantButtonClick = () => {
        /* TODO: Here we are only checking regex for script tags. Once we will receive validation rule for chatbot team we will modify this code. */
        const validateInputStringPattern = /^<.*$/gi;
        if (validateInputStringPattern.test(inputValue)) {
            setErrorMessage(KNOWLEDGE_ASSISTANT_ERROR_MESSAGES.invalidCharacters);
        } else if (inputValue && inputValue.length > charCount) {
            setErrorMessage(KNOWLEDGE_ASSISTANT_ERROR_MESSAGES.charLength);
        } else {
            const url = updateQueryStringParameter(KNOWLEDGE_ASSISTANT_URL, 'q', inputValue);
            const newTab = window.open(url, '_blank', 'noopener,noreferrer');
            if (newTab) {
                newTab.opener = null;
            }
            setErrorMessage('');
        }
    };

    return (
        <StyledKnowledgeAssistant $bgImage={isDarkTheme ? SKA_Bg_Dark : SKA_Bg_Light}>
            <div className="top-section">
                <div className="chat-header">
                    <img
                        alt="SKA logo"
                        src={isDarkTheme ? SKA_Logo_Dark_Theme : SKA_Logo_Light_Theme}
                    />
                    <div className="chat-header__title">{KNOWLEDGE_ASSISTANT.title}</div>
                </div>
                <div className="message-section">
                    <p>{KNOWLEDGE_ASSISTANT.message}</p>
                </div>
            </div>
            <div className="bottom-section">
                <div className="input-wrapper">
                    <div className="input-box">
                        <Input
                            value={inputValue}
                            onChange={(e) => handleAssistantInputChange(e.target.value)}
                            id="chat-input"
                            placeholder={KNOWLEDGE_ASSISTANT.placeholder}
                            className="chat-input"
                            hideClearButton={false}
                        />
                    </div>

                    <Button
                        data-aep-track={AEP_TRACK.analytics}
                        data-aep-track-action={AEP_TRACK_ACTION.linkClick}
                        data-aep-type={AEP_DATA_TYPE_PREFIX.chat}
                        data-aep-name={formatString(inputValue)}
                        disabled={!inputValue}
                        className="chat-button"
                        onClick={handleAssistantButtonClick}>
                        <Icon src={sendMessage} className="chat-arrow" />
                    </Button>
                </div>
                {errorMessage !== '' && <ErrorMessage>{errorMessage}</ErrorMessage>}
                <div className="chat-disclaimer">{KNOWLEDGE_ASSISTANT.disclaimer}</div>
            </div>
        </StyledKnowledgeAssistant>
    );
};

export default KnowledgeAssistant;
