import { THEME } from '../const';
import { IPromoCardOffer } from './MarketingCard';
import { IServiceStageProductMap } from './serviceMap';

export interface IApiResponse {
    [k: string]: string | number;
}

export interface IAppConfig {
    baseApiUrl: string;
    activeDirectory: IActiveDirectoryConfig;
    azureMapsClientId: string;
}

export interface IActiveDirectoryConfig {
    tenant: string;
    clientId: string;
}

export interface IBannerNavigationApiResponse {
    serviceAreaDetails: {
        id: number;
        name: string;
        serviceItemsDetails: {
            id: number;
            orderId: number;
            name: string;
            iconIdentifier: string;
            isActive: boolean;
        }[];
    }[];
}

export interface IServiceMapStage {
    id: number;
    name: string;
}

export interface IServicesStageApiResponse {
    serviceStageMap: {
        id: number;
        name: string;
        stages: IServiceMapStage[];
    }[];
}

export interface IEdgeCardDetails {
    id: number;
    name: string;
    description: string;
    videoUrl: string;
    thumbnailUrl: string;
    thumbnailTitle: string;
    caseStudyUrl: string;
    launchUrl: string;
    keyStatsDetails: {
        name: string;
        value: number;
    }[];
}

export interface IEdgeCardApiResponse {
    edgeCardDetails: {
        id: number;
        name: string;
        description: string;
        videoUrl: string;
        thumbnailUrl: string;
        thumbnailTitle: string;
        caseStudyUrl: string;
        launchUrl: string;
        asOfDate: string;
        keyStatsDetails: {
            name: string;
            value: number;
        }[];
    }[];
}
export interface IServiceMapApiResponse {
    serviceStageProductMap: IServiceStageProductMap[];
}

export interface ITheme {
    theme: THEME.DARK | THEME.LIGHT;
}

export interface IPromoContent {
    id: number;
    title: string;
    categoryName: string;
    imageIdentifier: string;
    contentUrl: string;
}

export interface IContentLibraryList {
    totalCount: number;
    contentLibraryDetails: IPromoContent[];
}

export interface IOfferLibraryObj {
    id: number;
    name: string;
}

export interface IOfferLibraryAPIResponseContent {
    contentId: number;
    imageIdentifier: string;
    label: string;
    category: IOfferLibraryObj;
    redirectUrl: string;
}

export interface IOfferLibraryData {
    id: number;
    name: string;
    audience: IOfferLibraryObj;
    contentType: IOfferLibraryObj;
    status: string;
    updatedBy: string;
    updatedAt: string;
    contentData: IOfferLibraryAPIResponseContent[];
}

export interface IOfferLibraryAPIResponse {
    offersData: IOfferLibraryData[];
}

export interface IContentConfig {
    baseUrl: string;
    token: string;
}

export interface IQuickLinksAPIResponse {
    quickLinksByServiceArea: Array<IQuickLinksByServiceArea>;
}

export interface IQuickLinksByServiceArea {
    quickLinks: Array<IQuickLinks>;
    serviceArea: number;
}

export interface IQuickLinks {
    order: number;
    title: string;
    url: string;
    currentRowIndex?: number;
    currentColumnIndex?: number;
}

export enum HEADER_CONTENT_TYPE {
    APPLICATION_JSON = 'application/json',
    FORM_DATA = 'multipart/form-data'
}

export interface IDefaultPromoCard {
    promoBannerData: Array<IPromoCardOffer>;
}

export interface IBannerImageUplaodRequest {
    file: File | null;
}

export interface IBannerImageUplaodResponse {
    message: string;
    statue: number;
}

export interface IBannerImageDeleteRequest {
    imageIdentifier: string;
}

export interface ICreateEditOfferRequest {
    name: string;
    contentType: number;
    audienceId: number;
    content: {
        title: string;
        category: number;
        url: string;
        imageIdentifier: string;
    }[];
}

export interface IKeyStatsData {
    id: number;
    asOfDate: string;
    edgePlatform: string;
    enabledTR: number;
    clientEngagement: number;
    user: number;
    updatedAt: string;
}

export interface IKeyStatsAPIResponse {
    keyStatsData: IKeyStatsData[];
}

export interface IGenericResponse {
    statusCode: number;
    message: string;
}

export interface IAPIErrorResponse {
    message: string;
    field: string;
}
