import React from 'react';
import styled from 'styled-components';
import { MotifErrorMessage } from '../../../assets/lib/motif';

type ErrorMessageProps = {
    children: React.ReactNode;
    className?: string;
};

const ErrorMessage = styled(({ children, className = '', ...props }: ErrorMessageProps) => {
    return (
        <MotifErrorMessage className={className} {...props}>
            {children}
        </MotifErrorMessage>
    );
})`
    .motif-error-icon {
        flex: 0 0 1.2rem;
    }
`;

export default ErrorMessage;
