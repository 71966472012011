import React, { FC, useEffect, useState } from 'react';
import { CardBody, CardHeader } from 'components/base/Card';
import { ServiceMapStyled } from './ServiceMap.styled';
import {
    API_ENDPOINTS,
    SERVICE_MAP,
    SERVICE_MAP_FOOTER_INFO_TEXT_LINK,
    SERVICE_MAP_FOOTER_INFO_TEXT,
    SERVICE_MAP_HEADER_INFO_TEXT,
    SERVICE_MAP_FOOTER_STRATEGY_AND_DILIGENCE_INFO_TEXT
} from 'core/base/const';
import { useGetServiceMapDataQuery } from 'core/api';
import ServiceMapHeader from './ServiceMapHeader/ServiceMapHeader';
import { Link, Loader } from 'components/base';
import { FallBack } from 'components/custom/ErrorBoundary';
import ServiceMapCell from './ServiceMapCell';
import { GatewayOptions, IGridMatrixProducts, IServiceStageProductMap } from 'core/base/type';
import { RootState, useAppDispatch, useAppSelector } from 'core/store';
import { IGridMatrix } from 'core/base/type';
import { updateGridMatrix } from 'common/utils/serviceMap';
import Flex, { FLEX_ALIGN, FLEX_DIRECTION } from 'assets/styled/Flex';
import { SERVICE_GROUP } from 'core/base/enum';
import {
    setActiveCell,
    setActiveProduct,
    setActiveProductId,
    setEdgePlatformId
} from 'core/store/banners.reducer';

const ServiceMap: FC = () => {
    const dispatch = useAppDispatch();
    const [skipApi, setSkipApi] = useState<boolean>(true);
    const [apiConfig, setApiConfig] = useState<GatewayOptions>({
        url: ''
    });
    const [gridColumnTemplate, setGridColumnTemplate] = useState<string>(''); // default selected cell will be 1
    const [gridMatrix, setGridMatrix] = useState<IGridMatrix>({});

    const {
        data = {
            serviceStageProductMap: []
        },
        isError,
        isLoading
    } = useGetServiceMapDataQuery(apiConfig, { skip: skipApi });

    const activeServiceGroupId = useAppSelector(
        (state: RootState) => state.banner.activeServiceGroupId
    );
    const activeServiceId = useAppSelector(
        (state: RootState) => state.banner.activeServices[activeServiceGroupId]
    );
    const activeCell = useAppSelector(
        (state: RootState) => state.banner.activeCells[activeServiceId]
    );

    const updateStates = () => {
        let selectedRow;

        for (const entries of Object.values(gridMatrix)) {
            for (const entry of entries) {
                const foundProduct = entry.products.find((product: IGridMatrixProducts) =>
                    product.services.includes(activeServiceId)
                );
                if (foundProduct) {
                    selectedRow = entry;
                    break;
                }
            }
            if (selectedRow) {
                break;
            }
        }

        if (selectedRow) {
            const firstProduct = selectedRow.products[0];
            dispatch(
                setActiveProduct({
                    serviceId: activeServiceId,
                    productId: firstProduct.id
                })
            );
            dispatch(setActiveProductId({ id: firstProduct.id, name: firstProduct.name }));
            dispatch(
                setActiveCell({
                    serviceId: activeServiceId,
                    cellData: [selectedRow.row[0], selectedRow.column[0]]
                })
            );
            dispatch(
                setEdgePlatformId({
                    productId: firstProduct.id,
                    edgeId: firstProduct.edgePlatformId
                })
            );
        }
    };

    useEffect(() => {
        if (apiConfig?.url.length > 0) {
            setSkipApi(false);
        }
    }, [apiConfig]);

    useEffect(() => {
        setApiConfig({
            url: `${API_ENDPOINTS.GET_SERVICE_MAP_DATA}/${activeServiceGroupId}`
        });
        setSkipApi(false);
    }, [activeServiceGroupId]);

    useEffect(() => {
        if (data?.serviceStageProductMap && data?.serviceStageProductMap.length > 0) {
            let gridData = {};
            const selectedRow = data.serviceStageProductMap?.find(
                (service: IServiceStageProductMap) => service.id === activeServiceId
            );

            if (activeServiceGroupId === SERVICE_GROUP.diligence) {
                if (selectedRow) {
                    gridData = updateGridMatrix([selectedRow], activeServiceGroupId);
                }
            } else {
                gridData = updateGridMatrix(data.serviceStageProductMap, activeServiceGroupId);
            }
            setGridMatrix(gridData);
            // To avoid column grid mismatch on service change, we should trigger resize
            setTimeout(() => {
                window.dispatchEvent(new Event('resize'));
            }, 0);
        }
    }, [data, activeServiceId]);

    // set states
    useEffect(() => {
        if (Object.keys(gridMatrix).length > 0 && !activeCell) {
            updateStates();
        }
    }, [gridMatrix]);

    const handleGridColumn = (clientWidthArray: number[]) => {
        const gridColumn = clientWidthArray.map((width) => `${width}px`).join(' ');
        setGridColumnTemplate(gridColumn);
    };

    return (
        <ServiceMapStyled.Card>
            <CardHeader>
                <Flex $gap={10} $direction={FLEX_DIRECTION.COLUMN}>
                    <span className="motif-card-header-title motif-card-header-title-alt">
                        {SERVICE_MAP}
                    </span>
                    <span className="motif-card-header-subtitle">
                        <p>
                            {`${SERVICE_MAP_HEADER_INFO_TEXT} ${
                                activeServiceGroupId === SERVICE_GROUP.strategy ||
                                (activeServiceGroupId === SERVICE_GROUP.diligence &&
                                    activeServiceId === 7)
                                    ? SERVICE_MAP_FOOTER_STRATEGY_AND_DILIGENCE_INFO_TEXT
                                    : ''
                            }`}
                        </p>
                    </span>
                </Flex>
            </CardHeader>
            <CardBody>
                <Loader show={isLoading} />
                {isError && <FallBack />}
                <ServiceMapHeader updateClientWidth={handleGridColumn} />

                {gridColumnTemplate.length > 0 && activeCell && (
                    <ServiceMapStyled.Grid $gridColumn={gridColumnTemplate}>
                        {Object.keys(gridMatrix).length > 0 &&
                            Object.keys(gridMatrix).map((key) => (
                                <ServiceMapCell key={key} columnData={gridMatrix[Number(key)]} />
                            ))}
                    </ServiceMapStyled.Grid>
                )}

                {activeServiceGroupId === SERVICE_GROUP.execution && (
                    <Flex $gap={10} $align={FLEX_ALIGN.FLEX_END} $justify={FLEX_ALIGN.FLEX_START}>
                        <div className="service-map-footer">
                            <Link
                                target="_blank"
                                href="https://eygb.sharepoint.com/sites/CapitalEdge/SitePages/Home.aspx">
                                {SERVICE_MAP_FOOTER_INFO_TEXT_LINK}
                            </Link>
                            {SERVICE_MAP_FOOTER_INFO_TEXT}
                        </div>
                    </Flex>
                )}
            </CardBody>
        </ServiceMapStyled.Card>
    );
};

export default ServiceMap;
