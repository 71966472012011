import { FC } from 'react';
import { COMING_SOON, WHAT_TO_DO_TODAY } from 'core/base/const';
import { StyledServiceContainer, StyledServices } from './HomeBannerServices.styled';
import { IBannerServiceState } from 'core/base/type/banner';
import { RootState, useAppDispatch, useAppSelector } from 'core/store';
import { setActiveServices } from 'core/store/banners.reducer';
import { Chip } from 'components/base';
import { AEP_DATA_TYPE_PREFIX, AEP_TRACK, AEP_TRACK_ACTION } from 'aepLibrary/aepDataCollection';
import { formatString } from 'common/utils';

const CHAR_LIMIT = 65;

interface IServicesProps {
    services: IBannerServiceState[];
    serviceGroupName: string;
}
const renderTitle = (title: string) => {
    if (title.length < CHAR_LIMIT) {
        return title;
    } else {
        const truncateTitle = title.slice(0, CHAR_LIMIT - 3).concat('...');
        return truncateTitle;
    }
};

const HomeBannerServices: FC<IServicesProps> = ({ services, serviceGroupName }) => {
    const dispatch = useAppDispatch();
    const activeServices = useAppSelector((state: RootState) => state.banner.activeServices);
    const activeServiceGroupId = useAppSelector(
        (state: RootState) => state.banner.activeServiceGroupId
    );
    const selectedServiceItem = activeServices[activeServiceGroupId];

    const handleService = (id: number, isActive: boolean) => {
        if (isActive) {
            dispatch(setActiveServices(id));
        }
    };

    // Chat bot URL
    // https://eycapitaledge.ey.com/chatbot

    return (
        <StyledServiceContainer>
            <div className={`header header-${serviceGroupName}`} id="services-header">
                <div className="title" id="services-title">
                    <h1>{serviceGroupName}</h1>
                    <h3>{WHAT_TO_DO_TODAY}</h3>
                </div>
            </div>
            <StyledServices>
                {services.map(({ id, name, isActive, iconIdentifier }) => {
                    const serviceName = renderTitle(name);
                    return (
                        <div
                            data-aep-track={AEP_TRACK.analytics}
                            data-aep-track-action={AEP_TRACK_ACTION.linkClick}
                            data-aep-type={`${AEP_DATA_TYPE_PREFIX.accordionServices}-${formatString(serviceGroupName, true)}`}
                            data-aep-name={formatString(serviceName)}
                            key={id}
                            className={`service service-${serviceGroupName} ${
                                selectedServiceItem === id ? 'selected' : ''
                            } ${!isActive ? 'disabled' : ''}`}
                            onClick={() => handleService(id, isActive)}
                            id={`services-service-${id}`}>
                            <img src={iconIdentifier} alt={`${serviceName}_alt`} />
                            <div className="title-section" id={`services-title-section-${id}`}>
                                <p>{serviceName}</p>
                                {!isActive && (
                                    <Chip className="coming-soon-text">{COMING_SOON}</Chip>
                                )}
                            </div>
                        </div>
                    );
                })}
            </StyledServices>
        </StyledServiceContainer>
    );
};

export default HomeBannerServices;
