import {
    GatewayOptions,
    ICategoryList,
    IFormData,
    IGenericResponse,
    IKeyStatsAPIResponse,
    IOfferLibraryAPIResponse
} from 'core/base/type';
import { baseApi } from './api.base';
import { withApiResponse } from 'common/utils/rtk-query.utils';
import { AdminService } from 'core/service';

export const adminApi = baseApi.injectEndpoints({
    endpoints: (builder) => ({
        getLibraryList: builder.query<IOfferLibraryAPIResponse, GatewayOptions>({
            queryFn: async (options) => withApiResponse(() => AdminService.getLibraryList(options))
        }),
        updateLibraryList: builder.mutation<IFormData, GatewayOptions>({
            queryFn: async (options) =>
                withApiResponse(() => AdminService.updateLibraryList(options))
        }),
        getCategoryList: builder.query<ICategoryList[], GatewayOptions>({
            queryFn: async (options) => withApiResponse(() => AdminService.getCategoryList(options))
        }),
        getKeyStats: builder.query<IKeyStatsAPIResponse, GatewayOptions>({
            queryFn: async (options) => withApiResponse(() => AdminService.getKeyStats(options))
        }),
        updatehOffer: builder.mutation<IGenericResponse, GatewayOptions>({
            queryFn: async (options) =>
                withApiResponse(() =>
                    AdminService.publishOffer({
                        url: options.url,
                        config: options.data
                    })
                )
        }),
        uploadNewBannerImage: builder.mutation<any, GatewayOptions>({
            queryFn: async (options) =>
                withApiResponse(() => AdminService.uploadNewBannerImage(options))
        }),
        deleteBannerImage: builder.mutation<any, GatewayOptions>({
            queryFn: async (options) =>
                withApiResponse(() => AdminService.deleteBannerImage(options))
        }),
        addKeyStats: builder.mutation<IGenericResponse, GatewayOptions>({
            queryFn: async (options) => withApiResponse(() => AdminService.addKeyStats(options))
        }),
        deleteOffer: builder.mutation<any, GatewayOptions>({
            queryFn: async (options) =>
                withApiResponse(() =>
                    AdminService.deleteOffer({
                        url: options.url
                    })
                )
        })
    })
});

export const {
    useGetLibraryListQuery,
    useUpdateLibraryListMutation,
    useGetCategoryListQuery,
    useGetKeyStatsQuery,
    useUpdatehOfferMutation,
    useUploadNewBannerImageMutation,
    useDeleteBannerImageMutation,
    useAddKeyStatsMutation,
    useDeleteOfferMutation
} = adminApi;
