import { FC } from 'react';
import { MotifTextLink } from '../../../assets/lib/motif';

type LinkProps = React.AnchorHTMLAttributes<HTMLAnchorElement> & {
    href: string;
    children: React.ReactNode;
    variant?: 'default' | 'alt';
    disabled?: boolean;
    className?: string;
};

const Link: FC<LinkProps> = ({
    variant = 'default',
    disabled = false,
    className = '',
    children,
    ...other
}: LinkProps) => {
    return (
        <MotifTextLink
            {...other}
            variant={variant}
            disabled={disabled}
            className={`link ${className}`}>
            {children}
        </MotifTextLink>
    );
};

export default Link;
