import { FC } from 'react';
import StyledProfileCard from '../ProfileCard.style';
import { GLOBAL_ERROR_BOUNDARY_MESSAGE, HEADER } from 'core/base/const';
import { ErrorMessage } from 'components/base';
import { logoutIcon } from 'assets/lib/common/icons';

interface IModalProps {
    logoutErrorState: boolean;
}

const ModalBody: FC<IModalProps> = ({ logoutErrorState }) => {
    return (
        <>
            <StyledProfileCard.ModalBody>
                <StyledProfileCard.ModalBodyIcon src={logoutIcon} />
                {HEADER.LOGOUT.CONFIRMATION_TEXT}
            </StyledProfileCard.ModalBody>
            {logoutErrorState && (
                <ErrorMessage>
                    {`${GLOBAL_ERROR_BOUNDARY_MESSAGE.title} ${GLOBAL_ERROR_BOUNDARY_MESSAGE.description}`}
                </ErrorMessage>
            )}
        </>
    );
};

export default ModalBody;
