export const TENANT = '5b973f99-77df-4beb-b27d-aa0c70b8482c';

// dev
// export const CLIENT_ID = 'bd9a9917-a51a-43a6-a6dd-a5492c6a9ddb';
// export const BASE_API_URL = 'https://sat-deliveryportal-bff-dev.sbp.eyclienthub.com/api/v1';
// export const BASE_APP_URL = 'http://localhost:3000/';
// export const KNOWLEDGE_ASSISTANT_URL = 'https://eysatknowledgeassistant-dev.ey.com';

// OneTrust dev
// export const PUBLIC_KEY = 'xmCJY504HNZZqo4TlnCb07OqXhvM3veIhciW3MsIWO6n4M4O96';
// export const ONE_TRUST_TENANT_ID = '1cdf238d-5334-4875-a527-c537afa295f0';
// export const PURPOUSE_ID = '80c037d1-dec9-4201-8c6a-1307ad2977b0';
// export const ONE_TRUST_WEBFORM_INSTALL_CP = '9047f451-1fef-43cb-96cc-f354973c49be';
// export const ONE_TRUST_PREFERENCES_API = 'https://consent-api.onetrust.com/v1/preferences?_=';
// export const ONE_TRUST_COOKIE_SCRIPT = 'https://cdn.cookielaw.org/scripttemplates/otSDKStub.js';
// export const ONE_TRUST_DATA_DOMAIN = '018fed8d-7477-76de-a3e0-5c91049ab913-test';
// export const ONE_TRUST_EMAIL_FIELD_ID = 'dDXKiOrJAz97';
// export const ONE_TRUST_PRIVACY_SUBMIT_BUTTON = 'PgzFyTAjUc29';

// qa
// export const CLIENT_ID = 'f94fe794-7da1-4455-9edf-e0da197fd3c2';
// export const BASE_API_URL = 'https://sat-deliveryportal-bff-qa.sbp.eyclienthub.com/api/v1';
// export const BASE_APP_URL = 'http://localhost:3000/';
// export const KNOWLEDGE_ASSISTANT_URL = 'https://eysatknowledgeassistant-dev.ey.com';

// OneTrust qa
// export const PUBLIC_KEY = 'xmCJY504HNZZqo4TlnCb07OqXhvM3veIhciW3MsIWO6n4M4O96';
// export const ONE_TRUST_TENANT_ID = '1cdf238d-5334-4875-a527-c537afa295f0';
// export const PURPOUSE_ID = '8a4a67ea-28fc-4cf7-a64b-bab15089f729';
// export const ONE_TRUST_WEBFORM_INSTALL_CP = '4db39441-bcdb-4c65-9897-0ec55b3db287';
// export const ONE_TRUST_PREFERENCES_API = 'https://consent-api.onetrust.com/v1/preferences?_=';
// export const ONE_TRUST_COOKIE_SCRIPT = 'https://cdn.cookielaw.org/scripttemplates/otSDKStub.js';
// export const ONE_TRUST_DATA_DOMAIN = '01901117-fc1c-7dee-8b15-3f8bb90dfc90';
// export const ONE_TRUST_EMAIL_FIELD_ID = 'PEAjlgNslb40';
// export const ONE_TRUST_PRIVACY_SUBMIT_BUTTON = 'vGigAvOTZx4';

export const CLIENT_ID = '2fc5e262-d451-4f4c-8ecd-c1800ec1a704';
export const BASE_API_URL = 'https://satdeliveryportal-bff.ey.com/api/v1';
export const BASE_APP_URL = 'https://satdeliveryportal.ey.com/';
export const KNOWLEDGE_ASSISTANT_URL = 'https://satknowledgeassistant.ey.com/';

export const PUBLIC_KEY = 'GEkPnUNjNtaRgWcXciORY9YEnYKwNa51OZ1fy4blfjJWq6WYI8kkU7dM3dQyHDB4vN8b30t0TTZ99Ckx8ZWQL8dmCKQvmZpqfk9B04nde3QpeVKVJMRKI23AF2F92EMuc5NxkE8w4vx4Nc5oN6gnGAvfGIF1DxBEdYjetZ9QPr3ZL6Tq8tvIzDgt1ugls4E1JqRedUAe';
export const ONE_TRUST_TENANT_ID = 'e398929d-19d8-4936-8936-5b2be51a20f3';
export const PURPOUSE_ID = '99ca910b-99bc-457a-992c-843027de2aab';
export const ONE_TRUST_WEBFORM_INSTALL_CP = 'a4c09e9c-597d-41f7-865a-61935edfa917';
export const ONE_TRUST_PREFERENCES_API = 'https://consent-api.onetrust.com/v1/preferences?_=';
export const ONE_TRUST_COOKIE_SCRIPT = 'https://cdn.cookielaw.org/scripttemplates/otSDKStub.js';
export const ONE_TRUST_DATA_DOMAIN = '019029ae-135a-7071-9d13-fdd42fc28ebc';
export const ONE_TRUST_EMAIL_FIELD_ID = 'WefFiYVdrf51';
export const ONE_TRUST_PRIVACY_SUBMIT_BUTTON = 'XmRANxuQfK47';
