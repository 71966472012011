const eyProduct = 'SaT Delivery Portal';
const eyApp = 'SaT Delivery Portal';
export const aepPageViewEvent = () => {
    const aepPageViewObj = {
        event: 'pageView',
        _etassureco: {
            identity: {
                hashedEmailAddress: (window as any).userID
            },
            eyProduct: eyProduct,
            eyApp: eyApp
        }
    };
    const consent = localStorage.getItem('oneTrustConsent');
    if (consent) {
        setConsent(consent);
    } else {
        localStorage.setItem('oneTrustConsent', 'false');
        setConsent('false');
    }
    (window as any).adobeDataLayer.push(aepPageViewObj);
    aepClickEvent(
        consent === 'true' ? PERSONALIZATION_STATUS.optIn : PERSONALIZATION_STATUS.optOut,
        AEP_DATA_TYPE_PREFIX.personalizationChoice
    );
};

export const setConsent = (consent: any) => {
    (window as any)?.adobeDataLayer?.push({
        event: 'userConsent',
        _etassureco: { userConsent: consent === 'true' ? 'in' : 'out' }
    });
};

export const aepUserConsentEvent = (isReload: boolean = true) => {
    if (isReload) {
        setTimeout(() => {
            window.location.reload();
        }, 500);
    }
};

export const aepClickEvent = (ctaText: string, ctaType: string) => {
    const aepClickEventObj = {
        event: 'personalizationClick',
        _etassureco: {
            personalization: {
                name: ctaText,
                type: ctaType
            }
        }
    };
    (window as any).adobeDataLayer.push(aepClickEventObj);
};

// Generic Link Tracking Code

document.addEventListener('click', (e) => {
    const _target = e.target as Element;
    const _targetClosest = _target.closest('[data-aep-track="analytics"]') as HTMLElement;

    if (
        _targetClosest &&
        _targetClosest?.dataset['aepName'] &&
        _targetClosest?.dataset['aepType']
    ) {
        aepClickEvent(_targetClosest?.dataset['aepName'], _targetClosest?.dataset['aepType']);
    }
});

export enum AEP_TRACK {
    analytics = 'analytics'
}

export enum AEP_TRACK_ACTION {
    linkClick = 'link-click'
}

export enum AEP_DATA_TYPE_PREFIX {
    accordion = 'accordion',
    accordionServices = 'services',
    product = 'product',
    edgeCardVideo = 'edge-card-video',
    toolkit = 'toolkit',
    edgeCardApp = 'edge-card-app',
    caseStudiesViewAll = 'case-studies-view-all',
    caseStudies = 'case-studies',
    trainingVideo = 'training-video',
    trainingVideoViewAll = 'training-video-view-all',
    quickLinks = 'quick-links',
    promoCard = 'promo-card',
    chat = 'sat-knowledge-assistant',
    personalizationChoice = 'personalization-choice',
    methodology = 'methodology'
}

export enum PERSONALIZATION_STATUS {
    optIn = 'opt-in',
    optOut = 'opt-out'
}
