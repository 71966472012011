import styled from 'styled-components';

const FOOTER_HEIGHT = '80px';

export const StyledAuthorizedLayout = {
    AppContainer: styled.div``,
    Main: styled.div`
        min-height: calc(100vh - ${FOOTER_HEIGHT});
    `,
    ContentWrapper: styled.div``,
    Content: styled.div`
        padding: ${(props) => props.theme.pagePadding};

        .header-menu-section {
            display: flex;
            .results-inputs {
                display: flex;
                flex-direction: row;

                .input-icons-section,
                .result-icons-section {
                    display: flex;
                }

                .motif-label {
                    width: 60px;
                    font-size: 0.75rem;
                }

                .motif-icon svg {
                    height: 0.75rem;
                    width: 0.75rem;
                }
            }
        }
    `,
    Page: styled.div``
};
