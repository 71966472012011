import { styled } from 'styled-components';

export const StyledComingSoon = styled.div`
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    align-items: center;
    row-gap: 15px;
    margin-bottom: 30px;
    p {
        font-size: 24px;
        color: #ffe600;
        font-weight: 700;
        margin-bottom: 10px;
    }
    .coming-soon-img {
        width: 80%;
        height: 280px;
    }

    .motif-theme-light & {
        p {
            color: #4d4d5c;
        }
    }
`;
