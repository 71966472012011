import { FC } from 'react';
import { MotifToggleSwitch } from '../../../assets/lib/motif';

type ToggleSwitchProps = React.InputHTMLAttributes<HTMLInputElement> & {
    id: string;
    checked: boolean;
    name: string;
    testId?: string;
    onLabel?: string;
    offLabel?: string;
    disabled?: boolean;
    hideLabels?: boolean;
};
const ToggleSwitch: FC<ToggleSwitchProps> = ({
    testId = '',
    onLabel = 'on',
    offLabel = 'off',
    disabled = false,
    hideLabels = true,
    checked,
    ...other
}) => {
    return (
        <MotifToggleSwitch
            checked={checked}
            data-test-id={testId}
            onLabel={onLabel}
            offLabel={offLabel}
            disabled={disabled}
            hideLabels={hideLabels}
            {...other}
        />
    );
};

export default ToggleSwitch;
