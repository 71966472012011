import { PROFILE_CARD_MENU, ROUTES } from 'core/base/const';
import { FC } from 'react';
import { useNavigate } from 'react-router-dom';
import StyledProfileCard from './ProfileCard.style';

export interface IDropdownItem {
    name: string;
    icon: string;
}

interface DropdownChildrensProps {
    data: IDropdownItem[];
    handleModal: (value: boolean) => void;
    toggleDropdown: React.Dispatch<React.SetStateAction<boolean>>;
}

const DropdownChildrens: FC<DropdownChildrensProps> = ({ data, handleModal, toggleDropdown }) => {
    const navigate = useNavigate();

    const handleLogout = (): void => {
        handleModal(true);
    };

    const handleClickDropdownItem = (child = 'username'): void => {
        switch (child) {
            case PROFILE_CARD_MENU.logOut:
                handleLogout();
                toggleDropdown(false);
                break;
            case PROFILE_CARD_MENU.manageContent:
                navigate(ROUTES.MANAGE_CONTENT);
                toggleDropdown(false);
                break;
            default:
                break;
        }
    };
    return (
        <>
            {data.length > 0 &&
                data.map((child, i) => (
                    <StyledProfileCard.Item
                        onClick={() => handleClickDropdownItem(child.name)}
                        key={i}>
                        <StyledProfileCard.Icon src={child.icon} />
                        {child.name}
                    </StyledProfileCard.Item>
                ))}
        </>
    );
};

export default DropdownChildrens;
