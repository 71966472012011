import { Card } from 'components/base/Card';
import styled from 'styled-components';

export const CaseStudiesStyle = styled(Card)<{ $caseStudyImg: string }>`
    flex: 1 1 50%;
    position: relative;
    display: flex;
    flex-direction: column;
    .motif-card-btn-view-all {
        font-size: 18px;
        font-weight: 400;
        width: 130px;
        height: 44px;
        padding: 24px 14px;
    }

    .case-studies-image {
        height: 100%;
        .case-studies-image-element {
            background-image: url(${({ $caseStudyImg }) => $caseStudyImg});
            background-size: contain;
            background-position: right;
            height: 100%;
            background-repeat: no-repeat;
            margin-right: -5px;
        }
    }
`;
