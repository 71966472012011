import React, { FC } from 'react';
import { CardBody, CardHeader } from 'components/base/Card';
import EdgeDataCards from './EdgeDataCards';
import { Button, ErrorMessage, Icon, Loader } from 'components/base';
import VideoPlayerComponent from '../VideoPlayer/VideoPlayer';
import EdgeToolkit from './EdgeToolkit';
import { EdgeStyle } from './EdgeCard.styled';
import { EDGE_BUTTON_LAUNCH_TEXT, EDGE_BUTTON_WHY_TEXT, NO_DATA_AVAILABLE } from 'core/base/const';
import { RootState, useAppSelector } from 'core/store';
import { communicationIcCallMade24px } from 'assets/lib/motif/icons';
import { BUTTON_VARIANT } from 'components/base/Button/Button';
import { AEP_DATA_TYPE_PREFIX, AEP_TRACK, AEP_TRACK_ACTION } from 'aepLibrary/aepDataCollection';
import { formatString } from 'common/utils';
import AsOfDate from './AsOfDate';
import ComingSoon from '../ComingSoon/ComingSoon';

interface ICurrentEdgeCardDetails {
    currentEdgeCardDetails: {
        id: number;
        name: string;
        description: string;
        videoUrl: string;
        thumbnailUrl: string;
        thumbnailTitle: string;
        launchUrl: string;
        asOfDate: string;
        keyStatsDetails: {
            name: string;
            value: number;
        }[];
    }[];
}

const EdgeCard: FC<ICurrentEdgeCardDetails> = ({ currentEdgeCardDetails }) => {
    const activeServiceGroupId = useAppSelector(
        (state: RootState) => state.banner.activeServiceGroupId
    );
    const activeServiceId = useAppSelector(
        (state: RootState) => state.banner.activeServices[activeServiceGroupId]
    );
    const activeProductId = useAppSelector(
        (state: RootState) => state.banner.activeProductIds[activeServiceId]
    );
    const activeEdgePlatformId = useAppSelector(
        (state: RootState) => state.banner.activeEdgePlatformId[activeProductId]
    );

    const currentEdgeCard = currentEdgeCardDetails.find(
        (edgeCard) => edgeCard.id === activeEdgePlatformId
    );

    const openLaunchEdgeLink = (url: string) => {
        window.open(url, '_blank', 'noopener,noreferrer');
    };

    if (!activeEdgePlatformId) {
        return (
            <EdgeStyle>
                <Loader show={true} />
            </EdgeStyle>
        );
    }

    return (
        <EdgeStyle>
            {currentEdgeCard ? (
                <>
                    <CardHeader>
                        <span className="motif-card-header-title motif-card-header-title-alt">
                            {`${EDGE_BUTTON_WHY_TEXT} ${currentEdgeCard.name} ?`}
                        </span>
                    </CardHeader>
                    <CardBody>
                        <div className="edge-card-content-wrapper">
                            <p>{currentEdgeCard.description}</p>
                            <div className="video-wrapper">
                                {currentEdgeCard.videoUrl ? (
                                    <VideoPlayerComponent
                                        thumbnailTitle={currentEdgeCard.thumbnailTitle}
                                        videoUrl={currentEdgeCard.videoUrl}
                                        thumbnailUrl={currentEdgeCard.thumbnailUrl}
                                        dynamicVideoPlayerId={activeEdgePlatformId}
                                        aepType={`${
                                            AEP_DATA_TYPE_PREFIX.edgeCardVideo
                                        }-${formatString(currentEdgeCard.name, true)}`}
                                    />
                                ) : (
                                    <div className="edge-card-vdo-coming-soon">
                                        <ComingSoon />
                                    </div>
                                )}
                            </div>
                            <AsOfDate asOfDate={currentEdgeCard.asOfDate} />
                            <EdgeDataCards keyStatsDetails={currentEdgeCard.keyStatsDetails} />
                            <EdgeToolkit />
                            <Button
                                data-aep-track={AEP_TRACK.analytics}
                                data-aep-track-action={AEP_TRACK_ACTION.linkClick}
                                data-aep-type={`${AEP_DATA_TYPE_PREFIX.edgeCardApp}-${formatString(
                                    currentEdgeCard.name,
                                    true
                                )}`}
                                data-aep-name={formatString(
                                    `${EDGE_BUTTON_LAUNCH_TEXT} ${currentEdgeCard.name}`
                                )}
                                className="btn-launch-edge"
                                variant={BUTTON_VARIANT.SECONDARY}
                                type="button"
                                disabled={!currentEdgeCard.launchUrl}
                                onClick={() => openLaunchEdgeLink(currentEdgeCard.launchUrl)}>
                                <span className="bnt-arrow-text">{`${EDGE_BUTTON_LAUNCH_TEXT} ${currentEdgeCard.name}`}</span>
                                <Icon src={communicationIcCallMade24px} aria-hidden="true" />
                            </Button>
                        </div>
                    </CardBody>
                </>
            ) : (
                <ErrorMessage className="right-section__error-card">
                    {NO_DATA_AVAILABLE}
                </ErrorMessage>
            )}
        </EdgeStyle>
    );
};

export default EdgeCard;
