// import { arrowLeft, arrowRight, arrowRightYellow } from 'assets/lib/common/icons';
import { FC, useState, useRef, useCallback, useEffect, useLayoutEffect } from 'react';
import { IBannerDetails } from 'core/base/type/banner';
import { useAppDispatch } from 'core/store';
import { setActiveServiceGroup } from 'core/store/banners.reducer';
import { API_ENDPOINTS, NO_DATA_AVAILABLE } from 'core/base/const';
import { useGetBannerNavigationDataQuery } from 'core/api';
import HomeBannerServices from 'components/custom/HomeBanner/HomeBannerServices';
import { ErrorMessage, Icon, Loader } from 'components/base';
import { HomeStyle } from './HomeBanner.style';
import { navigationIcArrowBack24px, navigationIcArrowForward24px } from 'assets/lib/motif/icons';
import { AEP_DATA_TYPE_PREFIX, AEP_TRACK, AEP_TRACK_ACTION } from 'aepLibrary/aepDataCollection';
import { formatString } from 'common/utils';
import { setBannerData } from 'core/store/common.reducer';

const HomeBanner: FC = () => {
    const dispatch = useAppDispatch();
    const slideNavWidth = 68;
    const slideLabelTop = 250;
    const ref = useRef<HTMLInputElement>(null);
    const [slide2XPos, setSlide2XPos] = useState(0);
    const [slide3XPos, setSlide3XPos] = useState(0);
    const [transition, setTransition] = useState('none');
    const [currentSlide, setCurrentSlide] = useState(0);
    const [showService, setShowService] = useState(true);
    const apiConfig = {
        url: API_ENDPOINTS.GET_BANNER_NAVIGATION_DATA
    };
    const {
        data: servicesGroups,
        isFetching,
        isError
    } = useGetBannerNavigationDataQuery(apiConfig);

    const handelWidthChange = (currentSlide: number) => {
        if (ref.current && ref.current.offsetWidth) {
            if (currentSlide === 0) {
                setSlide2XPos(ref.current.offsetWidth - 2 * slideNavWidth);
                setSlide3XPos(ref.current.offsetWidth - slideNavWidth);
            } else if (currentSlide === 1) {
                setSlide2XPos(slideNavWidth);
                setSlide3XPos(ref.current.offsetWidth - slideNavWidth);
            } else {
                setSlide2XPos(slideNavWidth);
                setSlide3XPos(2 * slideNavWidth);
            }
        }
    };

    const handleNav = useCallback(
        (current: number, activeServiceGroupId: number, isInit: boolean = false) => {
            if (!isInit) {
                setTransition('0.5s transform ease-in');
            }
            setCurrentSlide(current);
            if (current !== 0) {
                dispatch(setActiveServiceGroup(current + 1));
            } else {
                dispatch(setActiveServiceGroup(activeServiceGroupId));
            }
            setShowService(true);

            // To-do: commented to avoid flickering issue
            // setTimeout(() => {
            //     setShowService(true);
            // }, 500);
            handelWidthChange(current);
        },
        [slideNavWidth, ref]
    );

    const updateServicesData = () => {
        const serviceGroupData: {
            [key: number]: string;
        } = {};
        const servicesData: {
            [key: number]: string;
        } = {};
        servicesGroups?.serviceAreaDetails.forEach((servicesGroups) => {
            serviceGroupData[servicesGroups?.id] = servicesGroups?.name;
            servicesGroups?.serviceItemsDetails?.forEach(
                (item) => (servicesData[item.id] = item.name)
            );
        });
        dispatch(setBannerData({ serviceGroupData, servicesData }));
    };

    useLayoutEffect(() => {
        const handleResize = () => {
            if (servicesGroups) {
                handelWidthChange(currentSlide);
                handleNav(currentSlide, servicesGroups && servicesGroups.serviceAreaDetails[0].id);
            }
        };
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, [ref, servicesGroups, currentSlide]);

    useEffect(() => {
        if (servicesGroups) {
            updateServicesData();
            handleNav(
                currentSlide,
                servicesGroups && servicesGroups.serviceAreaDetails[0].id,
                true
            );
        }
    }, [servicesGroups]);

    const getHomeServiceGroupClass = (index: number) => {
        return `${currentSlide === index ? 'opac-0' : 'opac-1'} home-banner-nav`;
    };

    if (isFetching) return <Loader show={isFetching} />;

    if (isError) return <ErrorMessage>{NO_DATA_AVAILABLE} </ErrorMessage>;

    return (
        <>
            <HomeStyle
                $slide2XPos={slide2XPos}
                $slide3XPos={slide3XPos}
                $slideLabelTop={slideLabelTop + 'px'}
                $transition={transition}>
                <div className="parent" id="home-banner">
                    <ul ref={ref as React.MutableRefObject<null>}>
                        {servicesGroups &&
                            servicesGroups.serviceAreaDetails.length > 0 &&
                            servicesGroups.serviceAreaDetails.map(
                                (banner: IBannerDetails, index) => {
                                    return (
                                        <li
                                            className="home-banner-main"
                                            id={`home-banner-main-${index + 1}`}
                                            key={index}>
                                            <div
                                                data-aep-track={AEP_TRACK.analytics}
                                                data-aep-track-action={AEP_TRACK_ACTION.linkClick}
                                                data-aep-type={AEP_DATA_TYPE_PREFIX.accordion}
                                                data-aep-name={formatString(banner.name)}
                                                className={getHomeServiceGroupClass(index)}
                                                id={`home-banner-nav-${index + 1}`}
                                                onClick={() => handleNav(index, banner.id)}>
                                                <div
                                                    className="home-banner-nav-label-main"
                                                    id={`home-banner-nav-label-main-${index + 1}`}>
                                                    <div>
                                                        <Icon
                                                            src={
                                                                currentSlide === index
                                                                    ? navigationIcArrowForward24px
                                                                    : currentSlide > index
                                                                      ? navigationIcArrowForward24px
                                                                      : navigationIcArrowBack24px
                                                            }
                                                        />
                                                    </div>
                                                    <div
                                                        className="home-banner-nav-label"
                                                        id={`home-banner-nav-label-${index + 1}`}>
                                                        {banner.name}
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="home-banner-body">
                                                {showService && (
                                                    <HomeBannerServices
                                                        serviceGroupName={banner.name}
                                                        services={banner.serviceItemsDetails}
                                                    />
                                                )}
                                            </div>
                                        </li>
                                    );
                                }
                            )}
                    </ul>
                </div>
            </HomeStyle>
        </>
    );
};

export default HomeBanner;
