import { AxiosError } from 'axios';
import { Axios } from 'core/http';
import { isDevEnv } from 'common/utils/env';
import { APIType } from 'core/base/enum';
import { GatewayOptions } from 'core/base/type';
import { ApiResult } from 'common/utils/rtk-query.utils';
export abstract class ServiceBase {
    protected static async sendToGateway<T>(options: GatewayOptions): Promise<ApiResult<T>> {
        options.method = options.method ?? APIType.POST;
        try {
            switch (options.method) {
                case APIType.POST:
                    const { data: postResponse, status: postStatus } = await Axios.post<T>(
                        options.url,
                        options.data,
                        options.config
                    );
                    return new ApiResult(postResponse, postStatus, '');
                case APIType.GET:
                    const { data: getResponse, status: getStatus } = await Axios.get<T>(
                        options.url,
                        options.config
                    );
                    return new ApiResult(getResponse, getStatus, '');
                case APIType.DELETE:
                    const { data: deleteResponse, status: deleteStatus } = await Axios.delete<T>(
                        options.url,
                        options.config
                    );
                    return new ApiResult(deleteResponse, deleteStatus, '');
                case APIType.PUT:
                    const { data: putResponse, status: putStatus } = await Axios.put<T>(
                        options.url,
                        options.config
                    );
                    return new ApiResult(putResponse, putStatus, '');
            }
            return Promise.reject('Unknown HTTP method');
        } catch (error) {
            isDevEnv && console.error('Response validation error:', error);

            const axiosError: AxiosError = error as AxiosError;

            const errorResult = new ApiResult<T>(
                axiosError.response?.data as T,
                axiosError.response?.status,
                axiosError.message
            );

            return errorResult;
        }
    }
}
