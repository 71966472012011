import { APIType } from 'core/base/enum';
import {
    GatewayOptions,
    IBannerImageDeleteRequest,
    IBannerImageUplaodRequest,
    ICategoryList,
    IGenericResponse,
    IKeyStatsAPIResponse,
    IOfferLibraryAPIResponse
} from 'core/base/type';
import { ServiceBase } from 'core/http/base.service';

export class AdminService extends ServiceBase {
    public static async getLibraryList(options: GatewayOptions) {
        return this.sendToGateway<IOfferLibraryAPIResponse>({
            ...options,
            method: APIType.POST
        });
    }
    public static async updateLibraryList(options: GatewayOptions) {
        return this.sendToGateway<any>({
            ...options,
            method: APIType.POST
        });
    }

    public static async getCategoryList(options: GatewayOptions) {
        return this.sendToGateway<ICategoryList[]>({
            ...options,
            method: APIType.GET
        });
    }
    public static async getKeyStats(options: GatewayOptions) {
        return this.sendToGateway<IKeyStatsAPIResponse>({
            ...options,
            method: APIType.GET
        });
    }
    public static async publishOffer(options: GatewayOptions) {
        return this.sendToGateway<IGenericResponse>({
            ...options,
            method: APIType.PUT
        });
    }
    public static async uploadNewBannerImage(options: GatewayOptions) {
        return this.sendToGateway<IBannerImageUplaodRequest>({
            ...options,
            method: APIType.POST
        });
    }
    public static async deleteBannerImage(options: GatewayOptions) {
        return this.sendToGateway<IBannerImageDeleteRequest>({
            ...options,
            method: APIType.DELETE
        });
    }
    public static async addKeyStats(options: GatewayOptions) {
        return this.sendToGateway<IGenericResponse>({
            ...options,
            method: APIType.POST
        });
    }

    public static async deleteOffer(options: GatewayOptions) {
        return this.sendToGateway<any>({
            ...options,
            method: APIType.DELETE
        });
    }
}
