import styled from 'styled-components';

const LayoutContainer = styled.div<{
    pagePaddingX?: string;
    pagePaddingY?: string;
}>`
    margin-left: auto;
    margin-right: auto;
    min-width: ${({ theme }) => theme.pageWrapper.minWidth};
    max-width: ${({ theme }) => theme.pageWrapper.maxWidth};
    padding: ${({ pagePaddingY }) => pagePaddingY || '0'}
        ${({ pagePaddingX }) => pagePaddingX || '0'};
`;

export default LayoutContainer;
