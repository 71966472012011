import { StyledComingSoon } from './ComingSoon.styled';
import comingSoonImg from 'assets/lib/common/images/ComingSoon.svg';

const ComingSoon = () => {
    return (
        <StyledComingSoon>
            <p>Coming Soon...</p>
            <img className="coming-soon-img" src={comingSoonImg} alt="Coming Soon" />
        </StyledComingSoon>
    );
};

export default ComingSoon;
