import { waitFor } from '@testing-library/react';
import { ApiBaseError, baseApi } from 'core/api/api.base';
import { GatewayOptions } from 'core/base/type';
import { store } from 'core/store';

export class ApiResult<T> {
    public value?: T;
    public errors?: string[] = [];
    public statusCode?: number;

    public get hasErrors(): boolean {
        return !!(this.errors && this.errors?.length > 0);
    }

    public get errorMessage(): string {
        return this.errors?.join(' ') ?? '';
    }

    constructor(value: T, status?: number, ...errors: string[]) {
        this.value = value;
        this.statusCode = status;
        this.errors = errors[0] ? errors : [];
    }
}

const withApiResponse = async <TResponse>(
    asyncMethod: (params?: GatewayOptions) => Promise<ApiResult<TResponse>>
) => {
    const result = await asyncMethod();

    if (!result?.hasErrors) {
        return {
            data: result?.value as TResponse
        };
    }

    return {
        // JSON.stringify is used to avoid type error
        error: new ApiBaseError(result.statusCode, result.errors, JSON.stringify(result?.value))
    };
};

// helper method to wait till RTK based promises are settled
const waitForResponse = async () => {
    return await waitFor(() => {
        Promise.allSettled(store.dispatch(baseApi.util.getRunningQueriesThunk() as any));
    });
};

export { withApiResponse, waitForResponse };
