import React, { FC } from 'react';
import { formatDateToString } from 'common/utils';
import { FORMATTED_DATE_OPTION } from 'core/base/enum';
import { AsOfDateStyle } from './EdgeCard.styled';
import { FYTD } from 'core/base/const';

interface AsOfDateProps {
    asOfDate: string;
}
const AsOfDate: FC<AsOfDateProps> = ({ asOfDate }) => {
    return (
        <AsOfDateStyle>
            {`${FYTD}${formatDateToString(new Date(asOfDate), FORMATTED_DATE_OPTION.SHORT_MONTH_YEAR)}`}
        </AsOfDateStyle>
    );
};

export default AsOfDate;
