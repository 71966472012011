import styled from 'styled-components';

export const StyledServiceContainer = styled.div`
    position: relative;
    color: #ffe600;
    top: 0;
    left: 50px;
    padding: 25px;
    height: 24rem;
    width: calc(100% - 120px);
    padding-bottom: 10px;
    padding-top: 40px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    .header {
        display: flex;
        justify-content: space-between;
        .chat a {
            border-radius: 50%;
            background: #23232f;
        }
        .chat-icon {
            width: 35px;
            height: 35px;
            margin: 12px;
            svg {
                width: 100%;
                height: 100%;
                path {
                    fill: #ffe600;
                }
            }
        }
    }
    .header-Diligence {
        .service {
            flex: 1;
        }
    }
    .title {
        padding: 0 30px;
    }
    h1 {
        font-size: 28px;
        margin-bottom: 10px;
        line-height: 28px;
    }
    h3 {
        color: white;
        font-size: 26px;
        line-height: 30px;
        font-weight: var(--weight-regular);
    }
`;
export const StyledServices = styled.div`
    display: flex;
    gap: 10px;
    .service-Diligence {
        flex: 1;
    }
    .service {
        text-align: center;
        cursor: pointer;
        padding: 10px;

        .title-section {
            color: #fff;
            font-size: 18px;
            font-weight: 800;
            display: flex;
            flex-direction: column;
            align-items: center;
            gap: 10px;

            .coming-soon-text {
                min-width: 5rem;
                margin: 0 auto;
                .motif-chip-interactive {
                    background-color: #57e188;
                    padding: 0 4px;
                    font-size: 10px;
                    font-weight: 400;
                    color: #1a1a24;
                    line-height: 20px;
                }
            }
        }
    }

    .selected {
        p {
            color: #ffe600;
            font-weight: 700;
        }
    }
    .disabled {
        cursor: default;
    }
    img {
        height: 39.92px;
    }
    p {
        color: #fff;
        font-size: 16px;
        font-weight: 400;
    }
`;
