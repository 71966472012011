import styled from 'styled-components';

export const ServiceMapCellStyle = styled.div<{
    $rowBgColor: string;
    $gridColumnStart: number;
    $gridColumnEnd: number;
    $gridRowStart: number;
    $gridRowEnd: number;
}>`
    background-color: ${({ $rowBgColor }) => $rowBgColor};
    align-self: flex-start;
    display: flex;
    flex-direction: column;
    grid-column: ${({ $gridColumnStart }) => $gridColumnStart} /
        ${({ $gridColumnEnd }) => $gridColumnEnd};
    grid-row: ${({ $gridRowStart }) => $gridRowStart} / ${({ $gridRowEnd }) => $gridRowEnd};
    height: 100%;

    .motif-chip {
        display: unset;
    }
    .motif-chip-button {
        text-align: left;
        padding-left: 0.5rem;
        padding-right: 0.5rem;
        padding-left: 0.5rem;
        padding-right: 0.5rem;
    }
`;

export const ServiceMapProductStyle = styled.div<{
    $chipOrder: number;
    $chipColor: string;
    $chipBgColor: string;
}>`
    background-color: ${({ $chipBgColor }) => $chipBgColor};
    order: ${({ $chipOrder }) => $chipOrder};
    padding: 0.5rem 0.25rem;
    position: relative;

    &:first-child {
        padding-top: 1rem;
    }
    &:last-child {
        padding-bottom: 1rem;
    }

    .service-map {
        &__chip-product {
            width: 100%;

            .motif-tooltip-wrapper {
                display: block;
                max-width: initial;
            }

            .motif-tooltip-trigger-wrap {
                display: flex;
                align-items: center;
            }
        }

        &__chip-wrapper {
            flex-wrap: wrap;
        }

        &__chip-name {
            font-size: 14px;
            line-height: 20px;
            font-weight: var(--weight-regular);
            color: ${({ $chipColor }) => $chipColor};
        }
        &__chip-arrow {
            --chip-icon-color: ${({ $chipColor }) => $chipColor};
            --chip-icon-hover--color: ${({ $chipColor }) => $chipColor};
            --icon-color: ${({ $chipColor }) => $chipColor};
            --chip-btn-hover--color: ${({ $chipColor }) => $chipColor};
            height: 1.25rem;
            margin-top: 2px;
            flex: 0 0 16px;
        }
        &__coming-soon-chip {
            .motif-chip-button {
                --chip--bg-color: ${({ theme }) => theme.comingSoonChipBgColor};
                --chip-active--bg-color: ${({ theme }) => theme.comingSoonChipBgColor};
                --chip--border-color: ${({ theme }) => theme.comingSoonChipBgColor};
                --chip-active--border-color: ${({ theme }) => theme.comingSoonChipBgColor};
                --chip--color: ${({ theme }) => theme.comingSoonChipTextColor};
                --chip-active--color: ${({ theme }) => theme.comingSoonChipTextColor};
                padding: 0 4px;
                font-size: 10px;
                font-weight: var(--weight-regular);
            }
        }
    }
`;
