import styled from 'styled-components';

export const EdgeStyle = styled.div`
    --progress-loader--bg-color: var(--card--bg-color);
    --progress-loader--border-color: var(--card-header-separator-line--border-color);
    margin-bottom: 20px;
    height: 100%;

    .motif-card-body {
        height: 100%;
        p {
            color: var(--training-card--text-color);
        }

        .btn-launch-edge {
            width: 100%;
            margin-top: 20px;
            /* background-color: #ffe600; */
            /* color: #000; */
            line-height: 28px;
            font-size: 18px;
            margin-bottom: 0.5rem;
            padding-top: 24px;
            padding-bottom: 24px;
            height: 44px;

            .bnt-arrow-text {
                margin-right: 10px;
            }
        }

        .edge-card-content-wrapper {
            height: 96%;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
        }

        .video-wrapper {
            padding: 2.1875rem 0rem;
            .edge-card-vdo-coming-soon {
                aspect-ratio: 16 / 9;
            }
        }
    }
`;

export const AsOfDateStyle = styled.div`
    font-size: 20px;
    font-weight: 400;
    line-height: 30px;
    color: var(--toolkit-text-color);
`;

export const ToolkitStyle = styled.div`
    margin-top: 30px;
    border-radius: 4px;
    .motif-card-toolkit {
        background-color: var(--toolkit-bg-color);
        color: var(--toolkit-text-color);
        border: 1px solid var(--toolkit-border-color);

        .motif-card-header {
            .motif-card-header-title {
                color: var(--toolkit-text-color);
            }
            background-color: transparent;
        }
    }
`;

export const ToolkitItemStyle = styled.div`
    display: grid;
    gap: 20px;
    padding: 0.25rem 1.0625rem;
    grid-template-columns: 1fr 1fr;

    .toolkit-item {
        flex: 0 0 40%;
        min-height: 50px;
        display: flex;
        cursor: pointer;
        justify-content: space-between;

        .toolkit-svg {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 40px;
            height: 40px;
            background-color: #1a1a24;
            border-radius: 26px;

            svg {
                width: 20px;
                height: 20px;
            }
        }

        .toolkit-item-title {
            flex: 0 0 60%;
            font-weight: 500;
        }

        .toolkit-item-link-icon {
            svg {
                width: 12px;
                height: 10px;
            }
        }
    }
`;

export const DataCardsStyle = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    /* margin: 3.5rem 0.4375rem; */

    .data-card {
        width: 140px;
        height: 140px;
        background-color: #3a3a4a;
        border-radius: 70px;
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        text-align: center;
        gap: 5px;
        border-bottom: 2px solid #ffe600;

        .data-card-text {
            color: var(--data-card-text-color);
            font-size: 14px;
            width: 82%;
            line-height: 16px;
            min-height: 34px;
        }

        .data-card-number {
            font-size: 24px;
            font-weight: 700;
            line-height: 32px;
            color: var(--data-card-number-color);
        }
    }
`;
