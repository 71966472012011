import React, { useState, useEffect } from 'react';
import { StyledMarketingCards } from './MarketingCards.style';
import ImageCarousel from 'components/custom/ImageCarousel';
import KnowledgeAssistant from './KnowledgeAssistant';
import { IPromoCardOffer } from 'core/base/type/MarketingCard';
import { API_ENDPOINTS } from 'core/base/const';
import { useGetDefaultPromoCardsQuery } from 'core/api';

const MarketingCards: React.FC = () => {
    let promoCardTargetResponse = (window as any)?.promoCardTargetResponse;
    const isAdobePromoPresent = promoCardTargetResponse && promoCardTargetResponse?.length > 0;
    if (isAdobePromoPresent) {
        promoCardTargetResponse = promoCardTargetResponse?.map((row: IPromoCardOffer) => {
            return { imageIdentifier: row?.imageFileName, ...row };
        });
    }
    const [defaultPromoCardData, setDefaultPromoCardData] = useState<Array<IPromoCardOffer>>(
        isAdobePromoPresent ? promoCardTargetResponse : []
    );

    const apiConfig = {
        url: API_ENDPOINTS.GET_DEFAULT_PROMO_CARD
    };
    const { data: promoCardData, isSuccess } = useGetDefaultPromoCardsQuery(apiConfig, {
        skip: isAdobePromoPresent
    });

    useEffect(() => {
        if (promoCardData?.promoBannerData && isSuccess) {
            setDefaultPromoCardData(promoCardData?.promoBannerData);
        }
    }, [isSuccess]);

    return (
        <StyledMarketingCards>
            <div className="image-carousel">
                {
                    <ImageCarousel
                        imageList={defaultPromoCardData}
                        isAdmin={false}
                        imageCarouselTestId={'marketing-cards-landing-page-id'}></ImageCarousel>
                }
            </div>
            <div className="chat-box-container">
                <KnowledgeAssistant />
            </div>
        </StyledMarketingCards>
    );
};

export default MarketingCards;
