import styled from 'styled-components';
import { ErrorMessage } from 'components/base';

const StyledFallback = {
    Wrapper: styled.div<{ height?: number }>`
        display: flex;
        justify-content: center;
        align-items: center;
        height: ${({ height }) => height ?? 400 + 'px'};
    `,
    Content: styled.div`
        text-align: center;
    `,
    Title: styled(ErrorMessage)`
        margin-bottom: 30px;
        &.motif-error-message {
            font-size: 24px;
            justify-content: center;
        }
        .motif-error-icon {
            width: 24px;
            height: 24px;
        }
    `,
    Description: styled.div`
        margin-bottom: 30px;
    `
};
export default StyledFallback;
