import React from 'react';
import { StyledMarketingCardItem } from './MarketingCardItem.style';
import { IMarketingCardItem } from 'core/base/type/MarketingCard';
import { AEP_DATA_TYPE_PREFIX, AEP_TRACK, AEP_TRACK_ACTION } from 'aepLibrary/aepDataCollection';
import { formatString } from 'common/utils';

const MarketingCardItem: React.FC<IMarketingCardItem> = ({
    imageUrl,
    label,
    labelPadding,
    testId,
    categoryName = '',
    redirectUrl
}) => {
    const handleLabelClick = () => {
        const newWindow = window.open(redirectUrl, '_blank', 'noopener,noreferrer');
        if (newWindow) newWindow.opener = null;
    };

    return (
        <StyledMarketingCardItem
            data-aep-track={AEP_TRACK.analytics}
            data-aep-track-action={AEP_TRACK_ACTION.linkClick}
            data-aep-type={`${AEP_DATA_TYPE_PREFIX.promoCard}-${formatString(categoryName, true)}`}
            data-aep-name={formatString(label)}
            labelTextPadding={labelPadding}
            backgroundImageScr={imageUrl ?? ''}
            data-test-id={testId}>
            <div className="banner-content">
                <div className="category-name">{categoryName}</div>
                {redirectUrl ? (
                    <div
                        className="label-div link"
                        key={`marketing_card_${label}`}
                        onClick={handleLabelClick}>
                        {label}
                    </div>
                ) : (
                    <div className="label-div" key={`marketing_card_${label}`}>
                        {label}
                    </div>
                )}
            </div>
        </StyledMarketingCardItem>
    );
};

export default MarketingCardItem;
