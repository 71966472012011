import React, { FC, useEffect, useState } from 'react';
import { CardBody, CardHeader } from 'components/base/Card';
import { TrainingStyle } from './Training.styled';
import VideoPlayerComponent from '../VideoPlayer/VideoPlayer';
import { Button, Icon, Loader } from 'components/base';
import { API_ENDPOINTS, BUTTON_LABELS, TRAINING } from 'core/base/const';
import ComingSoon from '../ComingSoon/ComingSoon';
import { useGetTrainingDataQuery } from 'core/api';
import { GatewayOptions, ITrainingData } from 'core/base/type';
import { navigationIcArrowForward24px } from 'assets/lib/motif/icons';
import { BUTTON_SIZE, BUTTON_VARIANT } from 'components/base/Button/Button';
import { AEP_DATA_TYPE_PREFIX, AEP_TRACK, AEP_TRACK_ACTION } from 'aepLibrary/aepDataCollection';
import { formatString } from 'common/utils';
import { RootState, useAppSelector } from 'core/store';

const Training: FC<{ productID: number }> = ({ productID }) => {
    const [trainingData, setTrainingData] = useState<ITrainingData>();

    const currentProduct = useAppSelector((state: RootState) => state.banner.activeProductId);

    const apiConfig: GatewayOptions = {
        url: API_ENDPOINTS.GET_TRAINING_DATA
    };

    const { data, isFetching } = useGetTrainingDataQuery(apiConfig);

    useEffect(() => {
        if (data && productID) {
            const selectedTrainingData = data.find((obj) => obj.productId === productID);
            setTrainingData(selectedTrainingData);
        }
    }, [data, productID]);

    const handleViewAll = () => {
        const newWindow = window.open(trainingData?.viewAllUrl, '_blank', 'noopener,noreferrer');
        if (newWindow) newWindow.opener = null;
    };

    if (isFetching) {
        return <Loader className="product-loader" />;
    }
    return (
        <TrainingStyle>
            <CardHeader>
                <span className="motif-card-header-title">
                    {trainingData?.title} {TRAINING}
                </span>
            </CardHeader>
            <CardBody>
                {trainingData?.isActive ? (
                    <>
                        <p>{trainingData?.description}</p>
                        <div className="training-video">
                            <VideoPlayerComponent
                                videoUrl={trainingData?.videoUrl}
                                thumbnailUrl={trainingData?.thumbnailUrl}
                                thumbnailTitle={trainingData?.title + ' Overview'}
                                dynamicVideoPlayerId={productID}
                                aepType={`${AEP_DATA_TYPE_PREFIX.trainingVideo}-${formatString(trainingData?.title)}'`}
                            />
                        </div>
                        <Button
                            data-aep-track={AEP_TRACK.analytics}
                            data-aep-track-action={AEP_TRACK_ACTION.linkClick}
                            data-aep-type={`${AEP_DATA_TYPE_PREFIX.trainingVideoViewAll}-${formatString(currentProduct?.name, true)}`}
                            data-aep-name={formatString(
                                `${trainingData?.title} ${TRAINING} ${BUTTON_LABELS.viewAll}`
                            )}
                            variant={BUTTON_VARIANT.SECONDARY}
                            type="button"
                            className="motif-card-btn-view-all"
                            size={BUTTON_SIZE.MEDIUM}
                            disabled={!trainingData?.viewAllUrl}
                            onClick={() => handleViewAll()}>
                            {BUTTON_LABELS.viewAll}&nbsp;
                            <Icon src={navigationIcArrowForward24px} aria-hidden="true" />
                        </Button>
                    </>
                ) : (
                    <ComingSoon />
                )}
            </CardBody>
        </TrainingStyle>
    );
};

export default Training;
    