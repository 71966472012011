import Modal from './Modal';

export enum ModalSize {
    DEFAULT = 'default',
    SMALL = 'sm',
    MEDIUM = 'md',
    LARGE = 'lg',
    EXTRA_LARGE = 'xl',
    XXL = 'xxl',
    XXXL = 'xxxl'
}

export enum ModalVariant {
    DEFAULT = 'default',
    ALT = 'alt'
}

export enum ModalPlacement {
    DEFAULT = 'default',
    SLIDE_OUT = 'slideout'
}

export default Modal;
