import React, { FC, useEffect, useState } from 'react';
import { ServiceStyle } from './ServiceMapHeader.style';
import { RootState, useAppSelector } from 'core/store';
import { useGetServiceStagesDataQuery } from 'core/api';
import { GatewayOptions, IServiceMapStage } from 'core/base/type';
import { API_ENDPOINTS } from 'core/base/const';
import ServiceMapStageItem from './ServiceMapStageItem';
import { Loader } from 'components/base';
import { FallBack } from 'components/custom/ErrorBoundary';

interface IServiceMapHeaderProps {
    updateClientWidth: (clientWidthArray: number[]) => void;
}
const ServiceMapHeader: FC<IServiceMapHeaderProps> = ({ updateClientWidth }) => {
    const [skipApi, setSkipApi] = useState<boolean>(true);
    const [apiConfig, setApiConfig] = useState<GatewayOptions>({
        url: ''
    });
    const [stagesData, setStagesData] = useState<IServiceMapStage[]>([]);
    const [columnWidthArr, setColumnWidthArr] = useState<number[]>([]);

    const {
        data = {
            serviceStageMap: []
        },
        isLoading,
        isError
    } = useGetServiceStagesDataQuery(apiConfig, { skip: skipApi });

    const activeServiceGroupId = useAppSelector(
        (state: RootState) => state.banner.activeServiceGroupId
    );
    const activeServiceId = useAppSelector(
        (state: RootState) => state.banner.activeServices[activeServiceGroupId]
    );
    const isDarkTheme = useAppSelector((state) => state.common.theme);

    const handleClientWidth = (index: number, width: number) => {
        setColumnWidthArr((prev) => {
            const newArr = [...prev];
            newArr[index] = width;
            return newArr;
        });
    };

    useEffect(() => {
        if (apiConfig?.url.length > 0) {
            setSkipApi(false);
        }
    }, [apiConfig]);

    useEffect(() => {
        setApiConfig({
            url: `${API_ENDPOINTS.GET_SERVICE_STAGES_DATA}/${activeServiceGroupId}`
        });
    }, [activeServiceGroupId]);

    useEffect(() => {
        if (data?.serviceStageMap?.length > 0) {
            const response = data?.serviceStageMap.find(
                (service) => service.id === activeServiceId
            ) ?? {
                stages: []
            };
            setStagesData(response?.stages);
        }
    }, [data, activeServiceId]);

    useEffect(() => {
        if (stagesData?.length === columnWidthArr.length) {
            updateClientWidth(columnWidthArr);
        } else if (columnWidthArr.length > stagesData?.length) {
            setColumnWidthArr((prev) => prev.slice(0, stagesData?.length));
        }
    }, [columnWidthArr]);

    if (isLoading) {
        return <Loader show={isLoading} />;
    } else if (isError) {
        return <FallBack />;
    }

    return (
        <ServiceStyle $isDarkTheme={isDarkTheme}>
            {stagesData.map(({ id, name }, index) => (
                <ServiceMapStageItem
                    key={id}
                    name={name}
                    updateClientWidth={(width) => handleClientWidth(index, width)}
                />
            ))}
        </ServiceStyle>
    );
};

export default ServiceMapHeader;
