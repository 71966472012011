import styled from 'styled-components';

export const StyledWelComeMsg = styled.div<{ $isDarkTheme: boolean }>`
    height: 60px;
    width: 100%;
    display: flex;
    flex-direction: 'row';
    padding: 16px 24px 10px 24px;
    opacity: 0px;
    font-size: 14px;
    .user-first-name {
        font-weight: 400;
        color: var(--app-header-caption-color);
        font-size: 18px;
    }

    .welcome-msg {
        font-weight: 400;
        font-size: 18px;
    }
    margin-bottom: 10px;
`;
