export enum KEY_STATS_TABLE_HEADERS {
    asOfDate = 'As of Date',
    edgePlatorm = 'Edge Platform',
    enabledTr = 'Enabled TERs',
    clientEngagement = 'Client Engagement',
    users = 'Users',
    lastUpdatedDate = 'Last Updated Date'
}

export enum ADD_KEY_STATS_FORM_KEYS {
    enabledTR = 'EnabledTR',
    clientEngagement = 'ClientEngagement',
    user = 'User'
}

export enum EdgeCardType {
    CapitalEdge = 'CapitalEdge',
    DiligenceEdge = 'DiligenceEdge',
    CompetitiveEdge = 'CompetitiveEdge'
}
