import { styled } from 'styled-components';

export const ServiceStyle = styled.div<{ $isDarkTheme: boolean }>`
    --bg-color: ${({ $isDarkTheme }) => ($isDarkTheme ? '#FFE600' : '#23232f')};
    --font-color: ${({ $isDarkTheme }) => ($isDarkTheme ? 'black' : 'white')};
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    gap: 10px;
    margin-bottom: 10px;

    .platform {
        background-color: var(--bg-color);
        padding-left: 15px;
        position: relative;
        flex-grow: 1;
        height: 48px;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-left: -5px;

        ::after,
        ::before {
            content: '';
            position: absolute;
            top: 0;
            right: -9px;
            border-top: 24px solid transparent;
            border-bottom: 24px solid transparent;
            border-left: 10px solid var(--bg-color);
            z-index: 1;
        }
        ::before {
            left: 0;
            border-left: 10px solid var(--card--bg-color);
            z-index: 0;
        }
        p {
            font-size: 13px;
            font-weight: 700;
            color: var(--font-color);
            line-height: 15px;
            text-align: center;
            min-width: 90px;
        }
    }
`;
