import styled from 'styled-components';
import heroImage from 'assets/lib/common/images/heroImage.jpg';

const slideHeight = '25rem';

export const HomeStyle = styled.div<{
    $slide2XPos: string | number;
    $slide3XPos: string | number;
    $slideLabelTop: string | number;
    $transition: string;
}>`
    margin-bottom: 20px;
    .parent {
        width: 100%;
        height: ${slideHeight};
        overflow: hidden;

        ul {
            display: block;
            width: 100%;
            height: ${slideHeight};
            overflow: hidden;
            position: relative;

            #home-banner-main-1 {
                transform: translateX(0);
            }
            #home-banner-main-2 {
                transform: translateX(${({ $slide2XPos }) => $slide2XPos + 'px'});
            }
            #home-banner-main-3 {
                transform: translateX(${({ $slide3XPos }) => $slide3XPos + 'px'});
            }

            .home-banner-main {
                display: block;
                height: ${slideHeight};
                transition: ${({ $transition }) => $transition};
                position: absolute;
                left: 0;
                top: 0;
                width: calc(100% - 80px);
                .home-banner-body {
                    background-image: url(${heroImage});
                    width: 100%;
                    height: ${slideHeight};
                    background-position-y: center;
                    background-position-x: center;
                    background-size: cover;
                    background-color: #1a1a24;
                    label {
                        font-size: 40px;
                        position: absolute;
                        color: #ffe600;
                        top: 20%;
                        left: 10%;
                        padding: 25px;
                        background-color: #00000080;
                    }
                }
                .opac-0 {
                    color: #ffe600 !important;
                    .home-banner-nav-label-main {
                        .motif-icon {
                            border-color: #ffe600 !important;
                            svg {
                                fill: #ffe600;
                            }
                        }
                    }
                }
                .opac-1 {
                    color: #ffffff !important;
                    .home-banner-nav-label-main {
                        .motif-icon {
                            border-color: #ffffff !important;
                            svg {
                                fill: #ffffff;
                            }
                        }
                    }
                }
                #home-banner-nav-1 {
                    background-color: #3f3f4c;
                    box-shadow: -100px 100px 150px 0px #3440541f;
                    cursor: pointer;
                }
                #home-banner-nav-2 {
                    border-left: 2px solid #4b4b5e;
                    background-color: #2e2e38;
                    box-shadow: -100px 100px 150px 0px #3440541f;
                    cursor: pointer;
                }
                #home-banner-nav-3 {
                    border-left: 2px solid #4b4b5e;
                    background-color: #1a1a24;
                    box-shadow: -100px 100px 150px 0px #3440541f;
                    cursor: pointer;
                }
                .home-banner-nav {
                    font-size: 18px;
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 5rem;
                    height: 100%;
                    color: #fff;
                    transition: opacity 1s linear;
                    z-index: 1;

                    .home-banner-nav-label {
                        transform: rotate(-90deg);
                        font-size: 20px;
                        font-style: normal;
                        font-weight: 600;
                    }
                    .home-banner-nav-label-main {
                        position: relative;
                        top: ${({ $slideLabelTop }) => $slideLabelTop};
                        .motif-icon {
                            width: 2.5rem;
                            height: 2.5rem;
                            display: inline-block;
                            position: relative;
                            border: 2px solid;
                            border-radius: 20px;
                            top: -60px;
                            left: 20px;
                            svg {
                                position: absolute;
                                top: 3px;
                                left: 3px;
                            }
                        }
                    }
                }
            }
        }
    }
`;
