import styled from 'styled-components';

export const StyledIntro = styled.div`
    position: relative;
    height: 100vh;
    cursor: pointer;
    overflow: hidden;
    .log-img {
        width: 204px;
        height: 239px;
        margin-bottom: 30px;
        background-color: transparent;
        filter: invert (100%);
    }

    .logo-div {
        display: flex;
        width: 100%;
        justify-content: center;
    }

    .handler-btn {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }
`;
