import React, { FC } from 'react';
import { HEADER } from '../../../core/base/const';
import { HeaderLogo } from '../../base/Header';
import satDarkLogo from 'assets/lib/common/images/SaT_Dark_Logo.svg';
import satLightLogo from 'assets/lib/common/images/SaT_Light_Logo.svg';
import Flex from 'assets/styled/Flex';
import SvgImage from '../SvgImage';
import { RootState, useAppSelector } from 'core/store';

const AppHeaderLogo: FC = () => {
    const theme = useAppSelector((state: RootState) => state.common.theme);
    return (
        <HeaderLogo className="app-header__logo">
            <div className="header-logo" data-test-id="app-header-logo">
                <SvgImage
                    src={theme ? satDarkLogo : satLightLogo}
                    alt={HEADER.APP_HEADER_NAME}
                    className="app-header__logo-image"
                />
            </div>

            <Flex className="app-header__titlegroup">
                <span className="app-header__title">{HEADER.APP_HEADER_NAME}</span>
                <span className="app-header__divider">|</span>
                <span className="app-header__caption">{HEADER.APP_HEADER_CAPTION}</span>
            </Flex>
        </HeaderLogo>
    );
};

export default React.memo(AppHeaderLogo);
