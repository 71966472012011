import useClientDimention from 'common/hooks/useClientDimention';
import React, { useEffect, useRef } from 'react';

type ServiceMapStageItemProps = {
    name: string;
    updateClientWidth: (width: number) => void;
};

const ServiceMapStageItem: React.FC<ServiceMapStageItemProps> = ({ name, updateClientWidth }) => {
    const chipRef = useRef<HTMLDivElement>(null);

    const { width } = useClientDimention(chipRef);

    useEffect(() => {
        if (width) {
            updateClientWidth(width);
        }
    }, [width]);

    return (
        <div className="platform" ref={chipRef}>
            <p>{name}</p>
        </div>
    );
};

export default ServiceMapStageItem;
