import { APIType } from 'core/base/enum';
import {
    GatewayOptions,
    IContentConfig,
    IDefaultPromoCard,
    IQuickLinksAPIResponse,
    ITheme
} from 'core/base/type';
import { ServiceBase } from 'core/http/base.service';

export class CommonService extends ServiceBase {
    public static async getUserAvatarData(options: GatewayOptions) {
        return this.sendToGateway<any>({
            ...options,
            method: APIType.GET
        });
    }
    public static async getUserTheme(options: GatewayOptions) {
        return this.sendToGateway<ITheme>({
            ...options,
            method: APIType.GET
        });
    }
    public static async updateUserTheme(options: GatewayOptions) {
        return this.sendToGateway<any>({
            ...options,
            method: APIType.POST
        });
    }
    public static async getUserRoleData(options: GatewayOptions) {
        return this.sendToGateway<any>({
            ...options,
            method: APIType.GET
        });
    }
    public static async getContentConfig(options: GatewayOptions) {
        return this.sendToGateway<IContentConfig>({
            ...options,
            method: APIType.GET
        });
    }
    public static async getQuickLinks(options: GatewayOptions) {
        return this.sendToGateway<IQuickLinksAPIResponse>({
            ...options,
            method: APIType.GET
        });
    }
    public static async getDefaultPromoCards(options: GatewayOptions) {
        return this.sendToGateway<IDefaultPromoCard>({
            ...options,
            method: APIType.GET
        });
    }
}
