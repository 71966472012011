import React from 'react';
import { StyledIntro } from './AppUnauthorised.style';
import { Link } from 'react-router-dom';

const AppUnauthorised: React.FC = () => {
    return (
        <StyledIntro>
            <div className="warning">
                <i className="fas fa-exclamation-triangle"> </i>
            </div>
            <div className="box">
                <h2>Unauthorised access!</h2>
                <p className="details-p">
                    Looks like you do not have the credentials to access Strategy and Transactions
                    Delivery Portal.
                </p>
                <p>
                    Please contact the admin or
                    <span>
                        <Link to={'/'} reloadDocument={true}>
                            click here
                        </Link>
                    </span>
                    to try again.
                </p>
            </div>
        </StyledIntro>
    );
};

export default AppUnauthorised;
