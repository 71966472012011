import { SERVICE_GROUP } from 'core/base/enum';
import { IGridMatrix, IServiceMapRows, IServiceStageProductMap } from 'core/base/type/serviceMap';

export const updateGridMatrix = (
    data: IServiceStageProductMap[],
    serviceGroupId: SERVICE_GROUP
) => {
    const gridData: IGridMatrix = {};

    data.forEach((service) => {
        service?.rowData.forEach((row: IServiceMapRows) => {
            row.products.forEach((product) => {
                let temp = product.stages[0];
                product.stages.forEach((stage, i) => {
                    const nextStage = product.stages[i + 1];
                    if (stage + 1 !== nextStage) {
                        const columnMatrix: [number, number] = [temp, stage + 1];
                        const rowMatrix: [number, number] = [row.rowId, row.rowId + 1];

                        // Add new row if not exist
                        if (!gridData[row.rowId]) {
                            gridData[row.rowId] = [
                                {
                                    products: [
                                        {
                                            id: product.id,
                                            serviceId: service.id,
                                            services: [service.id],
                                            name: product.name,
                                            description: product.description,
                                            isActive: product.isActive,
                                            orderId: product.orderId,
                                            edgePlatformId: product.edgePlatformId
                                        }
                                    ],
                                    column: columnMatrix,
                                    row: rowMatrix
                                }
                            ];
                        } else {
                            const isSameColumn = gridData[row.rowId].some(
                                (item) =>
                                    JSON.stringify(item.column) === JSON.stringify(columnMatrix)
                            );

                            let foundProduct: boolean = false;
                            gridData[row.rowId].forEach((item) => {
                                const found = item.products.find(
                                    (subproduct) => subproduct.id === product.id
                                );
                                if (found) {
                                    foundProduct = true;
                                }
                            });

                            // if column and row are same then push product to that column
                            if (isSameColumn) {
                                gridData[row.rowId].forEach((item) => {
                                    const innerSameColumn =
                                        JSON.stringify(item.column) ===
                                        JSON.stringify(columnMatrix);
                                    if (innerSameColumn && !foundProduct) {
                                        item.products.push({
                                            id: product.id,
                                            serviceId: service.id,
                                            services: [service.id],
                                            name: product.name,
                                            description: product.description,
                                            isActive: product.isActive,
                                            orderId: product.orderId,
                                            edgePlatformId: product.edgePlatformId
                                        });
                                    } else {
                                        if (
                                            serviceGroupId === SERVICE_GROUP.strategy &&
                                            innerSameColumn
                                        ) {
                                            item.products.forEach((subproduct) => {
                                                if (subproduct.id === product.id) {
                                                    subproduct.services.push(service.id);
                                                }
                                            });
                                        }
                                    }
                                });
                            } else {
                                // if row already exist then add new column
                                gridData[row.rowId].push({
                                    products: [
                                        {
                                            id: product.id,
                                            serviceId: service.id,
                                            services: [service.id],
                                            name: product.name,
                                            description: product.description,
                                            isActive: product.isActive,
                                            orderId: product.orderId,
                                            edgePlatformId: product.edgePlatformId
                                        }
                                    ],
                                    column: columnMatrix,
                                    row: rowMatrix
                                });
                            }
                        }
                        temp = nextStage;
                    }
                });
            });
        });
    });
    return gridData;
};
