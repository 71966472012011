export * from './http';
export * from './offerLibrary';
export * from './keyStats';

export const enum SIDEBAR_MENU {
    DASHBOARD = 1,
    TEST
}

export const enum ApiStatus {
    DEFAULT = 1,
    LOADING,
    SUCCESS,
    ERROR
}

export const enum SORT_DIRECTION {
    ASC,
    DESC
}

export const enum TOAST_VARIANT {
    INFO = 'info',
    WARNING = 'warning',
    ERROR = 'error',
    SUCCESS = 'success'
}

export const enum SIZE_VARIANT {
    SMALL = 'small',
    MEDIUM = 'medium',
    LARGE = 'large'
}

export const enum AVATAR_STATUS {
    ONLINE = 'online',
    NO_DISTURB = 'no-disturb',
    NO_AVAILABLE = 'no-available',
    ABSENT = 'absent',
    WARNING = 'warning',
    NONE = 'none'
}

export const enum QueryKeyEnum {
    'Query' = 'q'
}

export const enum SERVICE_GROUP {
    strategy = 1,
    diligence = 2,
    execution = 3
}

export const enum USER_ROLE {
    ADMIN_USER = 'AdminUser',
    SAT_USER = 'SaTUser'
}

export enum FORMATTED_DATE_OPTION {
    SHORT_MONTH_YEAR = 'SHORT_MONTH_YEAR',
    MM_DD_YYYY = 'MM_DD_YYYY',
    DD_MM_YYYY = 'DD_MM_YYYY',
    YYYY_MM_DD = 'YYYY_MM_DD',
    YY = 'YY'
}

export enum ToastVariant {
    INFO = 'info',
    WARNING = 'warning',
    ERROR = 'error',
    SUCCESS = 'success'
}
