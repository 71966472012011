import { createSlice } from '@reduxjs/toolkit';
import { ICommonInitialState } from 'core/base/type/store';

export const commonInitialState: ICommonInitialState = {
    theme: true, // true for dark theme, false for light theme
    serviceGroupData: {},
    servicesData: {},
    isUnauthorized: false
};

const commonReducer = createSlice({
    name: 'Common',
    initialState: commonInitialState,
    reducers: {
        setTheme: (state, action) => {
            state.theme = action.payload;
        },
        setBannerData: (state, action) => {
            const { serviceGroupData, servicesData } = action.payload;
            state.serviceGroupData = serviceGroupData;
            state.servicesData = servicesData;
        },
        setIsUnautorized: (state, action) => {
            state.isUnauthorized = action.payload;
        }
    }
});

export const { setBannerData, setTheme, setIsUnautorized } = commonReducer.actions;
export default commonReducer.reducer;
