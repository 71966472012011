const theme = {
    appHeaderHeight: 63,
    pageWrapper: {
        minWidth: '1200px',
        maxWidth: '1600px',
        paddingX: '24px',
        paddingY: '16px'
    },
    scrollBarBgColor: '#23232F',
    scrollBarThumbBgColor: 'rgba(145, 158, 171, 0.6)',
    scrollBarTrackBgColor: 'transparent',
    gray2: '#fafafc',
    brandColor: '#ffe600',
    comingSoonChipBgColor: '#57e188',
    comingSoonChipTextColor: '#1a1a24',
    buttonPadding: '0.5rem 2.14rem',
    dark: {
        bodyBgColor: '#1a1a24',
        trainingCardTextColor: '#bdbdc5',
        cardBorderColor: '#33333E',
        cardHeaderBranding: '#FFE600',
        suggestionBgColor: 'transparent',
        suggestionTextColor: '#737387',
        quickLinkIconColor: '#ffe600',
        toolkitBgColor: '#2E2E3C',
        toolkitTextColor: '#DEDEE2',
        toolkitBorderColor: '#2E2E3C',
        dataCardNumberColor: '#ffe600',
        dataCardTextColor: '#DEDEE2',
        appHeaderCaptionColor: '#ffe600',
        skaContainerBGColor: '#1A1A24',
        skaMessageBgColor: '#2E2E38',
        skaButtonBgColor: '#FFFFFF',
        skaInputBgColor: '#33333E',
        button: {
            buttonText: {
                color: '#737387'
            }
        },
        serviceMap: {
            cellColor: '#afaeba',
            chipActiveColor: '#2e2e38',
            cellBgColor: '#c4c4cd',
            cellActiveBgColor: '#35a4e8',
            cellActiveBorderColor: 'transparent',
            cellHighlightBgColor: '#87D3F2',
            cellHighlightBorderColor: 'transparent',
            chipBgColor: '#e6e6e9',
            tooltipHeaderColor: '#ffe600'
        },
        fileUploader: {
            uploadImageText: '#ffffff'
        }
    },
    light: {
        bodyBgColor: '#E8E8EC',
        trainingCardTextColor: '#656579',
        cardBorderColor: '#D7D7DC',
        cardHeaderBranding: '#2E2E38',
        suggestionBgColor: '#fafafc',
        suggestionTextColor: '#9897A6',
        quickLinkIconColor: '#2E2E38',
        toolkitBgColor: '#F9F9FA',
        toolkitTextColor: '#000000',
        toolkitBorderColor: '#000000',
        dataCardTextColor: '#ffffff',
        dataCardNumberColor: '#ffffff',
        appHeaderCaptionColor: '#747480',
        skaContainerBGColor: '#FFFFFF',
        skaMessageBgColor: '#F3F3F5',
        skaButtonBgColor: '#2E2E38',
        skaInputBgColor: '#E6E6E9',
        button: {
            buttonText: {
                color: '#737387'
            }
        },
        serviceMap: {
            cellColor: '#7F7F91',
            chipActiveColor: '#2e2e38',
            cellBgColor: '#AFAEBA',
            cellActiveBgColor: '#35a4e8',
            cellActiveBorderColor: '#0F69AE',
            cellHighlightBgColor: '#87D3F2',
            cellHighlightBorderColor: '#87D3F2',
            chipBgColor: '#ffffff',
            tooltipHeaderColor: '#000000'
        },
        fileUploader: {
            uploadImageText: '#00000'
        }
    }
};

export default theme;
