import React, { FC, useMemo } from 'react';
import { DataCardsStyle } from './EdgeCard.styled';
import { nFormatter } from 'common/utils';
import {
    EDGE_TOOLKIT_CLIENT_ENGAGEMENT,
    EDGE_TOOLKIT_ENABLED_TR,
    EDGE_TOOLKIT_USERS
} from 'core/base/const';

interface DataCardProps {
    name: string;
    value: number;
}

interface IKeyStatsDetailsProps {
    keyStatsDetails: {
        name: string;
        value: number;
    }[];
}

const DataCard: React.FC<DataCardProps> = ({ name, value }) => {
    const titleFormatter = useMemo(() => {
        switch (name) {
            case 'EnabledTR':
                return EDGE_TOOLKIT_ENABLED_TR;
            case 'ClientEngagement':
                return EDGE_TOOLKIT_CLIENT_ENGAGEMENT;
            case 'User':
                return EDGE_TOOLKIT_USERS;
            default:
                return name;
        }
    }, [name]);

    return (
        <div className="data-card">
            <div className="data-card-number">
                {name === 'EnabledTR' ? `$${nFormatter(value)}` : value}
            </div>
            <div className="data-card-text">{titleFormatter}</div>
        </div>
    );
};

const EdgeDataCards: FC<IKeyStatsDetailsProps> = ({ keyStatsDetails }) => {
    return (
        <DataCardsStyle>
            {keyStatsDetails &&
                keyStatsDetails.map((data, index) => <DataCard key={index} {...data} />)}
        </DataCardsStyle>
    );
};

export default EdgeDataCards;
