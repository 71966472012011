import { UnknownAction, EnhancedStore, configureStore } from '@reduxjs/toolkit';
import rootReducer, { RootState } from './rootReducer';
import { middleware as apiMiddleware } from '../api/api.base';
import { isDevEnv } from 'common/utils/env';
import { userProfileInitialState } from './userProfile.reducer';
import { commonInitialState } from './common.reducer';
import { bannerInitialState } from './banners.reducer';

export type InitialStoreState = Omit<RootState, 'api'> & Partial<Pick<RootState, 'api'>>;

export const initialState: InitialStoreState = {
    userProfile: userProfileInitialState,
    banner: bannerInitialState,
    common: commonInitialState
};

export const getStore = (
    preloadedState = initialState
): EnhancedStore<RootState, UnknownAction> => {
    return configureStore({
        reducer: rootReducer,
        preloadedState,
        middleware: (getDefaultMiddleWares) =>
            getDefaultMiddleWares({ serializableCheck: false }).concat(apiMiddleware),
        devTools: isDevEnv
    });
};

export const store = getStore();

export type AppDispatch = typeof store.dispatch;
