import { Card } from 'components/base/Card';
import styled from 'styled-components';

export const QuickLinksStyle = styled(Card)<{ $column: number }>`
    .motif-card-body {
        display: grid;
        gap: 10px;
        grid-template-columns: repeat(${({ $column }) => $column}, 1fr);
    }
    .quick-link-main {
        cursor: pointer;
        .quick-label {
            font-size: 1rem;
            font-style: normal;
            font-weight: 700;
            margin-right: 1rem;
        }
        .motif-icon {
            position: relative;
            top: 6px;
            color: var(--quick-link-icon-color);
            svg {
                width: 20px;
            }
        }
    }
`;
