import React, { useState } from 'react';
import {
    MotifButton,
    MotifCheckbox,
    MotifInlineMessage,
    MotifModalBody,
    MotifModalFooter,
    MotifModalHeader
} from '@ey-xd/motif-react';
import { useDispatch } from 'react-redux';
import { showPolicyModal } from 'core/store/userProfile.reducer';
import AppHeader from 'components/custom/AppHeader';
import AppFooter from 'components/custom/AppFooter';
import { acceptPrivacyPolicy } from 'core/http';
import { StylePrivacy } from './Policies.style';
import { RootState, useAppSelector } from 'core/store';
import WindowService from 'common/utils/window.utils';
import { Loader } from 'components/base';
import PolicyContent from 'components/custom/AppFooter/PolicyContent/PolicyContent';
import {
    BUTTON_LABELS,
    GLOBAL_ERROR_BOUNDARY_MESSAGE,
    I_AGREE_PRIVACY_NOTICE_TEXT,
    PRIVACY_NOTICE
} from 'core/base/const';

const Policies: React.FC = () => {
    const dispatch = useDispatch();

    const [checkboxAgree, setCheckboxAgree] = useState(false);
    const [loading, setLoading] = useState(false);
    const [isError, setIsError] = useState(false);
    const policyTypeList = useAppSelector((state: RootState) => state?.userProfile?.policyTypeList);

    const handleCheckbox = (event: React.ChangeEvent<HTMLInputElement>) => {
        setCheckboxAgree(event.target.checked);
    };

    const acceptPrivacy = async () => {
        const hasAccepted = await acceptPrivacyPolicy();
        if (hasAccepted.hasAccepted) {
            dispatch(showPolicyModal({ policyTypeList: [], showPolicy: false }));
            WindowService.reloadPage();
        } else {
            setIsError(true);
        }
        setLoading(false);
    };

    const handleActionButton = () => {
        setLoading(true);
        acceptPrivacy();
    };
    if (loading) {
        return <Loader />;
    }
    return (
        <>
            <StylePrivacy>
                <div className="main-content">
                    <AppHeader />
                    <div className="wrapper-progressBar">
                        <div className="policy-modal">
                            <MotifModalHeader>{PRIVACY_NOTICE}</MotifModalHeader>
                            <MotifModalBody>
                                {policyTypeList[0]?.content.length > 0 && (
                                    <PolicyContent content={policyTypeList[0]?.content} />
                                )}
                            </MotifModalBody>
                            <MotifModalFooter>
                                <div className="footer-wrapper">
                                    <MotifCheckbox
                                        id="checkbox-agree-to-privacy"
                                        value="checkbox-agree-to-privacy"
                                        name="checkbox-agree-to-privacy"
                                        checked={checkboxAgree}
                                        onChange={handleCheckbox}>
                                        <p className="agree-to-privacy">
                                            {I_AGREE_PRIVACY_NOTICE_TEXT}
                                        </p>
                                    </MotifCheckbox>
                                    <MotifButton
                                        onClick={handleActionButton}
                                        type="button"
                                        disabled={!checkboxAgree}>
                                        {BUTTON_LABELS.CONTINUE}
                                    </MotifButton>
                                    {isError && (
                                        <div className="policy-error">
                                            <MotifInlineMessage variant="error">
                                                {GLOBAL_ERROR_BOUNDARY_MESSAGE.title +
                                                    ' ' +
                                                    GLOBAL_ERROR_BOUNDARY_MESSAGE.description}
                                            </MotifInlineMessage>
                                        </div>
                                    )}
                                </div>
                            </MotifModalFooter>
                        </div>
                    </div>
                </div>
            </StylePrivacy>
            <AppFooter />
        </>
    );
};

export default React.memo(Policies);
