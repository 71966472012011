import { useCallback, useEffect, useLayoutEffect, useState } from 'react';

const useClientDimention = (myRef: React.RefObject<HTMLDivElement>) => {
    const [width, setWidth] = useState(0);
    const [height, setHeight] = useState(0);

    const handleResize = useCallback(() => {
        if (myRef.current) {
            setWidth(myRef.current.offsetWidth);
            setHeight(myRef.current.offsetHeight);
        }
    }, [myRef]);

    useLayoutEffect(() => {
        handleResize();
    }, []);

    useEffect(() => {
        window.addEventListener('load', handleResize);
        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('load', handleResize);
            window.removeEventListener('resize', handleResize);
        };
    }, [myRef, handleResize]);

    return { width, height };
};

export default useClientDimention;
