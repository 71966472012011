import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IBannerPayload, IBannerState } from 'core/base/type/banner';

export const bannerInitialState: IBannerState = {
    loading: false,
    edgePlatformData: {},
    caseStudiesUrl: {},
    activeServiceGroupId: 1,
    activeServices: {},
    activeEdgePlatformId: {},
    activeCells: {},
    activeProductIds: {},
    activeProductId: {
        id: 0,
        name: ''
    }
};

const bannerSlice = createSlice({
    name: 'banner',
    initialState: bannerInitialState,
    reducers: {
        setActiveServiceGroup: (state, action: PayloadAction<number>) => {
            state.activeServiceGroupId = action.payload;
        },
        setActiveServices: (state, action: PayloadAction<number>) => {
            state.activeServices[state.activeServiceGroupId] = action.payload;
        },
        setEdgePlatformData: (
            state,
            action: PayloadAction<{
                [key: number]: string;
            }>
        ) => {
            state.edgePlatformData = action.payload;
        },
        setCaseStudiesUrl: (
            state,
            action: PayloadAction<{
                [key: number]: string;
            }>
        ) => {
            state.caseStudiesUrl = action.payload;
        },
        setEdgePlatformId: (
            state,
            action: PayloadAction<{ productId: number; edgeId: number }>
        ) => {
            const { productId, edgeId } = action.payload;
            state.activeEdgePlatformId[productId] = edgeId;
        },
        setActiveProduct: (
            state,
            action: PayloadAction<{ serviceId: number; productId: number }>
        ) => {
            const { serviceId, productId } = action.payload;
            state.activeProductIds[serviceId] = productId;
        },
        setActiveCell: (
            state,
            action: PayloadAction<{ serviceId: number; cellData: [number, number] }>
        ) => {
            const { serviceId, cellData } = action.payload;
            state.activeCells[serviceId] = cellData;
        },
        setActiveProductId: (state, action: PayloadAction<IBannerPayload>) => {
            state.activeProductId = action.payload;
        }
    }
});

export const {
    setActiveServiceGroup,
    setActiveServices,
    setEdgePlatformId,
    setActiveProduct,
    setActiveCell,
    setEdgePlatformData,
    setActiveProductId,
    setCaseStudiesUrl
} = bannerSlice.actions;

export default bannerSlice.reducer;
