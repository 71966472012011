import styled from 'styled-components';
import { ReactNode } from 'react';
import { MotifCard } from 'assets/lib/motif';

interface ICardProps {
    children: ReactNode;
    className?: string;
}

const Card = styled(({ children, className = '', ...props }: ICardProps) => (
    <MotifCard className={className} {...props}>
        {children}
    </MotifCard>
))`
    display: block;
    padding: 0.7142857143rem 0rem;
`;

export default Card;
