import React, { FC, Suspense, lazy, useEffect, useState } from 'react';
import { Navigate, Route, Routes, useLocation, useNavigate } from 'react-router';
import { MsalProvider } from '@azure/msal-react';
import { IPublicClientApplication } from '@azure/msal-browser';
import { isDevEnv } from './common/utils/env';
import { CustomNavigationClient } from 'common/utils/NavigationClient';
import { ErrorBoundary } from './components/custom/ErrorBoundary';
import WindowService from './common/utils/window.utils';
import { AppLayout } from './layouts';
import { Loader } from './components/base';
import { ROUTES } from './core/base/const/routes';
import Test from './test';
import PageNotFound from './Pages/PageNotFound';
import Home from 'Pages/Home';
import AppUnauthorised from 'components/custom/AppUnauthorised';
const ManageContent = lazy(() => import('Pages/Admin/ManageContent'));

interface IApp {
    pca: IPublicClientApplication;
}

const App: FC<IApp> = ({ pca }) => {
    // The next 3 lines are optional. This is how you configure MSAL to take advantage of the router's navigate functions when MSAL redirects between pages in your app
    const navigate = useNavigate();
    const navigationClient = new CustomNavigationClient(navigate);
    pca.setNavigationClient(navigationClient);

    const { pathname } = useLocation();
    const [hasError, setHasError] = useState<boolean>(false);

    useEffect(() => {
        if (hasError) {
            WindowService.reloadPage();
        }
    }, [pathname, hasError]);

    const handleError = () => {
        setHasError(true);
    };

    return (
        <ErrorBoundary onError={handleError}>
            <MsalProvider instance={pca}>
                <AppLayout>
                    <Suspense fallback={<Loader />}>
                        <Routes>
                            <Route path={ROUTES.DEFAULT} element={<Home />} />
                            <Route path={ROUTES.MANAGE_CONTENT} element={<ManageContent />} />
                            {isDevEnv && (
                                <Route
                                    path={`${ROUTES.TEST}/${ROUTES.WILD}`}
                                    element={
                                        isDevEnv ? <Test /> : <Navigate to={ROUTES.NOT_FOUND} />
                                    }
                                />
                            )}
                            <Route path={ROUTES.NOT_FOUND} element={<PageNotFound />} />
                            <Route path={ROUTES.UNAUTHORIZED} element={<AppUnauthorised />} />
                            <Route
                                path={ROUTES.WILD}
                                element={<Navigate to={ROUTES.NOT_FOUND} />}
                            />
                        </Routes>
                    </Suspense>
                </AppLayout>
            </MsalProvider>
        </ErrorBoundary>
    );
};

export default App;
