import styled from 'styled-components';

export enum FLEX_ALIGN {
    CENTER = 'center',
    FLEX_START = 'flex-start',
    FLEX_END = 'flex-end',
    SPACE_AROUND = 'space-around',
    SPACE_BETWEEN = 'space-between'
}

export enum FLEX_DIRECTION {
    COLUMN = 'column',
    ROW = 'row'
}

export enum FLEX_WRAP {
    WRAP = 'wrap',
    NO_WRAP = 'nowrap'
}

const Flex = styled.div<{
    $align?: FLEX_ALIGN.CENTER | FLEX_ALIGN.FLEX_START | FLEX_ALIGN.FLEX_END;
    $justify?:
        | FLEX_ALIGN.CENTER
        | FLEX_ALIGN.FLEX_START
        | FLEX_ALIGN.FLEX_END
        | FLEX_ALIGN.SPACE_AROUND
        | FLEX_ALIGN.SPACE_BETWEEN;
    $gap?: number;
    $wrap?: FLEX_WRAP.WRAP | FLEX_WRAP.NO_WRAP;
    $direction?: FLEX_DIRECTION.COLUMN | FLEX_DIRECTION.ROW;
}>`
    display: flex;
    ${({ $align }) => ($align ? `align-items: ${$align};` : null)};
    ${({ $justify }) => ($justify ? `justify-content: ${$justify};` : null)};
    ${({ $gap }) => ($gap ? `gap: ${$gap}px` : null)};
    ${({ $wrap }) => ($wrap ? `flex-wrap: ${$wrap}` : null)};
    ${({ $direction }) => ($direction ? `flex-direction: ${$direction}` : null)};
`;
export default Flex;
