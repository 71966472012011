import styled from 'styled-components';

export const StyledKnowledgeAssistant = styled.div<{ $bgImage: string }>`
    min-height: 130px;
    border-radius: 6px;
    height: 100%;
    background-image: url(${({ $bgImage }) => $bgImage});
    background-repeat: no-repeat;
    background-position-x: right;

    color: ${({ theme }) => theme.knowledgeAssistantGrayColor};

    .top-section {
        margin: 0 10px;
        padding: 20px 15px;
        height: 55%;
        @media (max-width: 1366px) {
            padding-top: 10px;
        }
    }
    .chat-container {
        display: flex;
        flex-direction: column;
        justify-content: center;
    }
    .chat-header {
        display: flex;
        &__title {
            padding-top: 0.7rem;
            font-weight: 600;
            font-size: 16px;
        }
    }
    .message-section {
        margin-left: 3rem;
        p {
            background-color: var(--ska--message-bg-color);
            border-radius: 1rem;
            border-top-left-radius: 0rem !important;
            display: inline-block;
            font-size: 14px;
            max-width: 30rem;
            padding: 1rem 1.5rem;
            word-break: break-word;
            @media (max-width: 1366px) {
                font-size: 13px;
            }
        }
    }
    .input-wrapper {
        padding-top: 1rem;
        display: flex;
        align-items: flex-start;
        gap: 6px;

        .input-box {
            flex: 1;
        }
        .chat-input {
            font-weight: 300;
            font-size: 16px;
            line-height: 24px;
            border-radius: 4px;
        }
        .chat-input::placeholder {
            opacity: 1;
        }
        .motif-input-component {
            flex: 1 1 0%;
        }
        .chat-button {
            background-color: var(--ska--button-bg-color);
            min-width: 4rem;
            padding: 10px 0;
            border: none;
        }
    }
    .motif-error-message {
        line-height: 14px;
        font-size: 12px;
    }

    .chat-disclaimer {
        font-size: 12px;
        position: absolute;
        bottom: 0.5rem;
        line-height: 14px;
    }
    .bottom-section {
        height: 45%;
        background-color: var(--ska--input-bg-color);
        padding: 0 1rem;
        border-radius: 16px 16px 0px 0px;
    }
`;
