import { FC, ReactNode } from 'react';
import { MotifAvatar } from 'assets/lib/motif';
import { AVATAR_STATUS, SIZE_VARIANT } from 'core/base/enum';

interface IAvatarProps {
    userName: string;
    src?: string;
    description: string;
    className?: string;
    size?: SIZE_VARIANT.SMALL | SIZE_VARIANT.MEDIUM | SIZE_VARIANT.LARGE;
    status?:
        | AVATAR_STATUS.ONLINE
        | AVATAR_STATUS.ABSENT
        | AVATAR_STATUS.NONE
        | AVATAR_STATUS.NO_AVAILABLE
        | AVATAR_STATUS.NO_DISTURB
        | AVATAR_STATUS.WARNING
        | string;
    icons?: ReactNode;
    onClick?: () => void;
}

const Avatar: FC<IAvatarProps> = ({
    userName,
    className = '',
    description,
    size = SIZE_VARIANT.SMALL,
    status = '',
    ...props
}) => {
    return (
        <MotifAvatar
            userName={userName}
            className={className}
            description={description}
            size={size}
            status={status}
            {...props}
        />
    );
};

export default Avatar;
