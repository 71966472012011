import styled from 'styled-components';
import { MotifButton } from 'assets/lib/motif';

export enum BUTTON_VARIANT {
    PRIMARY = 'primary',
    PRIMARY_ALT = 'primary-alt',
    SECONDARY = 'secondary',
    WARN = 'warn',
    TEXT = 'text',
    TEXT_ALT = 'text-alt'
}

export enum BUTTON_SIZE {
    SMALL = 'small',
    MEDIUM = 'medium',
    LARGE = 'large',
    FLUID = 'fluid'
}

type ButtonProps = React.ButtonHTMLAttributes<HTMLButtonElement> & {
    variant?: BUTTON_VARIANT;
    size?: BUTTON_SIZE | null;
    disabled?: boolean;
    stepper?: 'prev' | 'next' | null;
    type?: 'submit' | 'reset' | 'button';
    children?: React.ReactNode;
    testId?: string;
    className?: string;
};

const Button = styled(
    ({
        variant = BUTTON_VARIANT.PRIMARY,
        size = null,
        disabled,
        stepper = null,
        type = 'submit',
        children,
        className = '',
        testId = '',
        ...other
    }: ButtonProps) => (
        <MotifButton
            {...other}
            variant={variant}
            size={size}
            disabled={disabled}
            stepper={stepper}
            type={type}
            className={className}
            data-test-id={testId}>
            {children}
        </MotifButton>
    )
)<{
    variant?: string;
}>`
    &.motif-button-small {
        padding: 5px 14px;

        .motif-icon svg {
            width: 20px;
            height: 20px;
        }
        &.button-icon-only {
            padding: 5px 8px;
            min-width: initial;
            width: 36px;
        }
    }
`;

export default Button;
