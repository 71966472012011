import React from 'react';

interface SvgImageProps {
    src: string;
    alt: string;
    height?: string;
    width?: string;
    className?: string;
}

const SvgImage: React.FC<SvgImageProps> = ({
    src,
    alt,
    className,
    height = '40px',
    width = '45px'
}) => {
    return (
        <div className={className}>
            <img src={src} alt={alt} height={height} width={width} />
        </div>
    );
};

export default SvgImage;
