import React, { FC, useEffect, useState } from 'react';
import { CardBody, CardHeader } from 'components/base/Card';
import { QuickLinksStyle } from './QuickLinks.styled';
import { MotifIcon } from 'assets/lib/motif';
import { actionIcLaunch24px } from 'assets/lib/motif/icons';
import { API_ENDPOINTS, QUICK_LINKS } from 'core/base/const';
import { AEP_DATA_TYPE_PREFIX, AEP_TRACK, AEP_TRACK_ACTION } from 'aepLibrary/aepDataCollection';
import { formatString } from 'common/utils';
import { useGetQuickLinksQuery } from 'core/api';
import { RootState, useAppSelector } from 'core/store';
import { IQuickLinks, IQuickLinksByServiceArea } from 'core/base/type';

const QuickLinks: FC = () => {
    const apiConfig = {
        url: API_ENDPOINTS.QUICK_LINKS
    };

    const [quickLinkList, setQuickLinkList] = useState<Array<IQuickLinks>>([]);
    const { data, isSuccess } = useGetQuickLinksQuery(apiConfig);
    const serviceGroupData = useAppSelector((state: RootState) => state.common.serviceGroupData);
    const activeServiceGroupId = useAppSelector(
        (state: RootState) => state.banner.activeServiceGroupId
    );

    const currentServiceGroupName = serviceGroupData[activeServiceGroupId];

    useEffect(() => {
        if (isSuccess && data && data?.quickLinksByServiceArea?.length > 0) {
            const filterdData = data?.quickLinksByServiceArea.filter(
                (quickLinksByServiceArea: IQuickLinksByServiceArea) => {
                    quickLinksByServiceArea.quickLinks;
                    return quickLinksByServiceArea.serviceArea === activeServiceGroupId;
                }
            );

            if (filterdData && filterdData.length > 0) {
                /* To generate row-col structure commented below line
                    and introduced generateRowColumnStructure
                    Need to be reverted if UX gets back to one col structure
                */
                // setQuickLinkList(filterdData[0]?.quickLinks);
                generateRowColumnStructure(filterdData[0]?.quickLinks);
            }
        }
    }, [isSuccess, activeServiceGroupId]);

    const openQuickLink = (url: string) => {
        window.open(url, '_blank', 'noopener,noreferrer');
    };

    // To make column structure with 3 rows in each column
    const maxItemsPerColumn = 3;
    const generateRowColumnStructure = (data: IQuickLinks[]) => {
        const arr: IQuickLinks[] = [];
        let currentColumnIndex = 1;
        let currentRowIndex = 1;
        data.forEach((item, index) => {
            if (index !== 0 && index % maxItemsPerColumn === 0) {
                currentColumnIndex++;
                currentRowIndex = 1;
            }
            arr.push({ ...item, currentRowIndex, currentColumnIndex });
            currentRowIndex++;
        });
        setQuickLinkList(arr);
    };
    return (
        <QuickLinksStyle $column={3}>
            <CardHeader>
                <span className="motif-card-header-title motif-card-header-title-alt">
                    {QUICK_LINKS}
                </span>
            </CardHeader>
            <CardBody>
                {quickLinkList.map((item: IQuickLinks, index: number) => (
                    <div
                        data-aep-track={AEP_TRACK.analytics}
                        data-aep-track-action={AEP_TRACK_ACTION.linkClick}
                        data-aep-type={formatString(
                            `${AEP_DATA_TYPE_PREFIX.quickLinks}-${currentServiceGroupName}`,
                            true
                        )}
                        data-aep-name={formatString(item?.title)}
                        className="quick-link-main"
                        key={index}
                        onClick={() => openQuickLink(item?.url)}
                        data-test-id={`quick-link-${index}`}>
                        <span className="quick-label">{item?.title}</span>
                        <MotifIcon src={actionIcLaunch24px} />
                    </div>
                ))}
            </CardBody>
        </QuickLinksStyle>
    );
};

export default QuickLinks;
