import { noImageFound } from 'assets/lib/common/icons';
import { FAILED_TO_LOAD_VIDEO } from 'core/base/const';

const NoVideoFound = () => {
    return (
        <div className="video-wrapper__image-not-found">
            <img src={noImageFound} alt={FAILED_TO_LOAD_VIDEO} className="video-wrapper__image" />
            <span className="video-wrapper__image-error">{FAILED_TO_LOAD_VIDEO}</span>
        </div>
    );
};

export default NoVideoFound;
