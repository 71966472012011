export const RTK_QUERY_CACHE_TIME = 86400;
export const API_ENDPOINTS = {
    ACCEPT_POLICY: 'paans/acceptpolicy',
    GET_PRIVACY_POLICY: 'paans/policytype',
    GET_SERVICE_MAP_DATA: 'service-maps/products',
    GET_SERVICE_STAGES_DATA: 'service-maps/stages',
    GET_EDGE_CARD_DATA: '/edge-platforms/details',
    GET_BANNER_NAVIGATION_DATA: '/configuration/service-area-details',
    LOGOUT: 'user/sign-out',
    USER_AVATAR: 'user/photo',
    GET_TRAINING_DATA: 'trainings',
    GET_CASE_STUDIES_DATA: 'casestudy/',
    GET_USER_THEME: 'user/user-preference/details',
    UPDATE_USER_THEME: 'user/user-preference/add',
    GET_CONTENT_LIBRARY_LIST: 'content-library/list',
    CONTENT_CONFIG: 'configuration/content-config',
    USER_ROLE: 'authorization/user-role',
    GET_CONTENT_LIBRARY_CATEGORY_LIST: 'configuration/categories',
    ADD_CONTENT_LIBRARY_DATA: 'offers/upsert',
    QUICK_LINKS: '/configuration/quick-links',
    GET_DEFAULT_PROMO_CARD: '/offers/promo/default',
    BANNER_UPLOAD_NEW_IMAGE: '/fileUpload/upload-file',
    BANNER_UPDATE_IMAGE: '/fileUpload/update',
    BANNER_DELETE_IMAGE: '/fileUpload/delete',
    OFFER_LIBRARY_LIST: 'offers/list',
    OFFER_LIBRARY_PUBLISH: 'offers/publish',
    GET_KEY_STATS: 'keystats/key-stats',
    ADD_KEY_STATS: 'keystats/add',
    OFFER_LIBRARY_DELETE: 'offers/delete'
};
