import { APIType } from 'core/base/enum';
import {
    GatewayOptions,
    IBannerNavigationApiResponse,
    ICaseStudiesData,
    IEdgeCardApiResponse,
    IServiceMapApiResponse,
    IServicesStageApiResponse,
    ITrainingData
} from 'core/base/type';
import { ServiceBase } from 'core/http/base.service';

export class BannerService extends ServiceBase {
    public static async getServiceMapData(options: GatewayOptions) {
        return this.sendToGateway<IServiceMapApiResponse>({
            ...options,
            method: APIType.GET
        });
    }
    public static async getBannerNavigationData(options: GatewayOptions) {
        return this.sendToGateway<IBannerNavigationApiResponse>({
            ...options,
            method: APIType.GET
        });
    }
    public static async getServiceStagesData(options: GatewayOptions) {
        return this.sendToGateway<IServicesStageApiResponse>({
            ...options,
            method: APIType.GET
        });
    }
    public static async getEdgeCardData(options: GatewayOptions) {
        return this.sendToGateway<IEdgeCardApiResponse>({
            ...options,
            method: APIType.GET
        });
    }

    public static async getTrainingData(options: GatewayOptions) {
        return this.sendToGateway<ITrainingData[]>({
            ...options,
            method: APIType.GET
        });
    }
    public static async getCaseStudiesData(options: GatewayOptions) {
        return this.sendToGateway<ICaseStudiesData>({
            ...options,
            method: APIType.GET
        });
    }
}
