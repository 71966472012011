import styled from 'styled-components';
import { DropdownItem, Icon } from 'components/base';
import Avatar from 'components/base/Avatar/Avatar';

const StyledProfileCard = {
    Wrapper: styled.div``,
    Item: styled(DropdownItem)`
        cursor: pointer;
    `,
    Avatar: styled(Avatar)`
        .motif-avatar-status-icon-none {
            display: none;
        }
    `,
    Icon: styled(Icon)`
        .motif-dropdown-item & svg {
            width: 20px;
            height: 20px;
        }
    `,
    ModalBody: styled.div`
        font-size: 14px;
        font-weight: var(--weight-bold);
        display: flex;
        gap: 10px;
        align-items: center;
        padding: 20px 0 40px;
    `,
    ModalBodyIcon: styled(Icon)`
        display: inline-block;
        height: 24px;
    `
};

export default StyledProfileCard;
