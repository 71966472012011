import { clientMaterial, marketMaterial, pricingCosting, quality } from 'assets/lib/common/icons';
import { IAdminTab } from '../type';
export * from './api';
export * from './routes';

type KeyType = string | number;

export type IGenericObject<T = unknown> = { [key in KeyType]: T };

export const EY = 'EY';

export const HEADER = {
    APP_HEADER_NAME: 'Strategy and Transactions Delivery Portal',
    APP_HEADER_CAPTION: 'From Strategy to Diligence to Execution',
    CONTACTUS: {
        EMAIL: 'EYEMaaS@au.ey.com',
        SUBJECT: ''
    },
    HELP: {
        LINK: 'https://www.ey.com'
    },
    LOGOUT: {
        CONFIRMATION_TEXT: 'Are you sure want to logout?'
    }
};

export const PROFILE_CARD_MENU = {
    help: 'Help',
    contactUs: 'Contact us',
    logOut: 'Logout',
    modalHeaderText: 'Logout',
    modalActionButtonText: 'Logout',
    modalCancelButtonText: 'Close',
    manageContent: 'Manage content'
};

export enum SIDEBAR {
    dashboard = 'Dashboard'
}

export const FOOTER = {
    privacyNotice: 'Privacy Notice',
    engagement: 'Engagement Management',
    termsOfUse: 'Terms Of Use',
    dataPrivacyCenter: 'Data Privacy'
};

export const FOOTER_LEAGAL_SECTION = `Copyright © ${new Date().getFullYear()} EYGM Limited. All rights reserved. EY refers to the global organization, and may refer to one or more, of the
member firms of Ernst & Young Global Limited, each of which is a separate legal
entity. Ernst & Young Global Limited, a UK company limited by guarantee, does
not provide services to clients.`;

export const TNC_CONTENT = `EY advises that the outcomes provided are based on many detailed assumptions underpinning the scenarios, and the key assumptions are described in the Report. The modelled scenarios represent one possible future for the development and operation of the NEM, and it must be acknowledged that many alternative futures exist. We encourage the Client to ensure that they develop a thorough understanding of the sensitivity of the outcomes to key assumptions and consider these assumptions with due care before acting on the outcomes presented in this Report.`;

export const ENGAGEMENT_MANAGEMENT_URL = 'https://go.ey.com/45b78io';

export const GLOBAL_ERROR_BOUNDARY_MESSAGE = {
    title: 'Something Went Wrong.',
    description: 'Please try again or report an issue to support'
};
export const SUB_PAGE = 'SUB_PAGE';

export const MODAL_BUTTONS = {
    close: 'Close',
    apply: 'Apply',
    reset: 'Reset',
    cancel: 'Cancel',
    confirm: 'Confirm',
    submit: 'Submit'
};

export const INTRO_PAGE = {
    buttonLabel: 'Begin Your SaT Delivery Portal Journey'
};
export const SEARCH_PLACEHOLDER = 'Search';
export const SEARCH_BUTTON_TITLE = 'Search';
export const WHAT_TO_DO_TODAY = 'What do you want to do today?';

/* Edge Card Constants */
export const EDGE_BUTTON_WHY_TEXT = 'Why';
export const EDGE_BUTTON_LAUNCH_TEXT = 'Launch';
export const EDGE_BUTTON_EDGE_TEXT = 'Edge';
export const EDGE_BUTTON_VIDEO_TEXT = 'Video';
export const EDGE_TOOLKIT_MARKETING_HEADER = 'View Marketing Toolkit';
export const EDGE_TOOLKIT_SALES_HEADER = 'View Sales Toolkit';
export const EDGE_TOOLKIT_ENABLED_TR = 'Total Engagement Revenue Enabled';
export const EDGE_TOOLKIT_CLIENT_ENGAGEMENT = 'Client Engagements';
export const EDGE_TOOLKIT_USERS = 'Users';
export const CASE_STUDY_HEADER = 'Case Studies';
export const QUICK_LINKS = 'Quick Links';
export const SERVICE_MAP = 'Service Map';
export const SUGGESTED_FOR_YOU = 'Suggested For You';
export const TRAINING = 'Training';
export const FYTD = 'Financial Year to Date – ';
export const ANNUAL_RESULT = 'Annual Results';

/* Banner Services */
export const CHAR_LIMIT = 65;
export const COMING_SOON = 'Coming Soon';

export const ENABLE_PERSONALIZATION = 'Enable Personalization';

/* Error Messages */
export const NO_DATA_AVAILABLE = 'No Data Available';
export const FAILED_TO_LOAD_IMAGE = 'Failed to load image';
export const FAILED_TO_LOAD_VIDEO = 'Failed to load video';
export const NO_SUGGESTIONS_FOUND = 'No Suggestion Found';

export const EDGE_CARD_LAUNCH_URL = [
    {
        id: 1,
        name: 'Strategy',
        url: 'https://ie-uat.sbp.eyclienthub.com/'
    },
    {
        id: 2,
        name: 'Diligence',
        url: 'https://teams.microsoft.com/l/entity/720b2f05-1cc4-4ed0-8bda-9236b18e45c5/tenantId=5b973f99-77df-4beb-b27d-aa0c70b8482c'
    },
    {
        id: 3,
        name: 'Execution',
        url: 'https://eycapitaledge.ey.com/'
    }
];

export const TOOLKIT_SERVICES = [
    {
        id: 1,
        services: [
            {
                title: 'Pricing and Costing',
                icon: pricingCosting,
                link: 'https://discovercontent.ey.net/knd24105qbudmngv'
            },
            {
                title: 'Quality and Risk Management',
                icon: quality,
                link: 'https://sites.ey.com/sites/DS_SaT/Pages/ey-competitive-edge.aspx#Quality%20and%20risk%20management'
            },
            {
                title: 'Go-To-Market Materials',
                icon: marketMaterial,
                link: 'https://sites.ey.com/sites/DS_SaT/Pages/ey-competitive-edge.aspx#Go-to-market'
            }
        ]
    },
    {
        id: 2,
        services: [
            {
                title: 'Pricing and Costing',
                icon: pricingCosting,
                link: 'https://discovercontent.ey.net/knd22015907q1ob1'
            },
            {
                title: 'Quality and Risk Management',
                icon: quality,
                link: 'https://discovercontent.ey.net/pod23043cm3onbvr'
            },
            {
                title: 'Market Materials',
                icon: marketMaterial,
                link: 'https://discovercontent.ey.net/knd23022jafom4al'
            }
        ]
    },
    {
        id: 3,
        services: [
            {
                title: 'Pricing and Costing',
                icon: pricingCosting,
                link: 'https://eygb.sharepoint.com/:p:/r/sites/CapitalEdge/_layouts/15/Doc.aspx?sourcedoc=%7b5E183DD7-E25A-4C3C-AD18-B64B2E1E6060%7d&file=SaT%20CE5%20minimum%20pricing%20guidelines_20211208.pptx&action=edit&mobileredirect=true&isSPOFile=1&clickparams=eyJBcHBOYW1lIjoiVGVhbXMtRGVza3RvcCIsIkFwcFZlcnNpb24iOiI0OS8yNDAzMzEwMTgxNyJ9'
            },
            {
                title: 'Quality and Risk Management',
                icon: quality,
                link: 'https://eygb.sharepoint.com/sites/CapitalEdge/SitePages/Sales-Support.aspx#quality-risk-management'
            },
            {
                title: 'Go-To-Market Materials',
                icon: marketMaterial,
                link: 'https://eygb.sharepoint.com/sites/CapitalEdge/SitePages/Sales-Support.aspx#go-to-market-resources'
            },
            {
                title: 'Client Materials',
                icon: clientMaterial,
                link: 'https://eygb.sharepoint.com/sites/CapitalEdge/SitePages/Sales-Support.aspx#client-conversation-resources'
            }
        ]
    }
];

export enum THEME {
    DARK = 'Dark',
    LIGHT = 'Light'
}
// Service Map
export const SERVICE_MAP_HEADER_INFO_TEXT =
    'Click on each product in the service map to learn more.';

export const SERVICE_MAP_FOOTER_STRATEGY_AND_DILIGENCE_INFO_TEXT =
    'Additional products mapped to the service are highlighted.';

export const SERVICE_MAP_FOOTER_INFO_TEXT_LINK = 'Launch the Capital Edge Hub site ';
export const SERVICE_MAP_FOOTER_INFO_TEXT =
    'to learn more about our other apps and how Capital Edge supports client engagements.';

export const MARKETING_CARD_TITLE = 'In the spotlight';
export const KNOWLEDGE_ASSISTANT = {
    avatar: 'SKA',
    title: 'SaT Knowledge Assistant',
    placeholder: 'Message',
    message: `Hello! I'm here to assist you with any queries you might have related to SaT
                    methodology and tools.`,
    disclaimer:
        'Disclaimer: Please be aware that the SaT Knowledge Assistant is currently in the beta phase.'
};
export const KNOWLEDGE_ASSISTANT_ERROR_MESSAGES = {
    invalidCharacters:
        'Error: Your input contains invalid characters. Please revise and try again.',
    charLength:
        'Error: Your input exceeds the maximum character limit. Please revise and try again.'
};

export const carouselLength: number = 5;
export const PRIVACY_NOTICE = 'Privacy Notice';

export const I_AGREE_TO_STATEMENT = `I agree to the privacy statement`;
export const BUTTON_LABELS = {
    viewAll: 'View all',
    CONTINUE: 'Continue',
    CONTINUE_TO_EY: 'Continue to Climate Economics Model Journey',
    BACK: 'Back'
};

export const FORM = {
    REQUIRED_LABEL: '(required)',
    SELECT_LABEL: 'Select',
    SEARCH_LABEL: 'Search',
    OFFER_TITLE_ERROR: 'Please enter a valid title!',
    TEXT_AREA_PLACEHOLDER: 'Your message',
    TEXT_AREA_HINT: 'Max 100 characters',
    TEXT_AREA_CHAR_LIMIT: 100,
    URL_ERROR: 'Please enter a valid URL!',
    URL_REGEX:
        /(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})/gi,
    URL_REGEX_CHAR: 4000,
    OFFER_TITLE_REGEX: /^[a-zA-Z0-9][a-zA-Z0-9\s\-_]*$/,
    TITLE_CHAR_LENGTH: 100,
    POSTER_IMAGE_ERROR:
        'Image file size should be under 1 MB. Supported formats are PNG, JPG (max. 800x400px)',
    BANNER_IMAGE_SIZE_ERROR: 'Image file size should be under 1 MB',
    BANNER_IMAGE_FORMATE_ERROR: 'Supported formats are PNG, JPG (max. 800x400px)',
    BANNER_IMAGE_ERROR_DIMENTION: 'Please upload a valid image file with 800* 400 Size.',
    IMAGE_TYPE_JPE: 'jpg',
    IMAGE_TYPE_PNG: 'png',
    IMAGE_TYPE_JPEG: 'jpeg',
    IMAGE_UPLOAD_SIZE_IN_MB: 1000000,
    IMAGE_SIZE_400: 400,
    IMAGE_SIZE_800: 800,
    IMAGE_FILE_NAME_REGEX: /[^a-zA-Z0-9\-_ ]+/,
    IMAGE_FILE_NAME_ERROR:
        'Invalid characters found in file name. Only alphanumeric, -, _, and space are allowed.',
    CONTENT_TITLE_TEXT_REGEX: /^[a-zA-Z0-9][a-zA-Z0-9\s\-_][^<>\\/|{}[\]^]*$/,
    CONTENT_TITLE_TEXT_LENGTH_ERROR: `Content title must be less than 100 characters`,
    CONTENT_TITLE_TEXT_FORMAT_ERROR: 'Special characters space, hyphen, comma, underscore allowed',
    API_FAILED_ERROR_MESSAGE: 'Something went wrong. Please try again later!'
};

export enum ADD_NEW_CONTENT_FORM_KEYS {
    category = 'category',
    label = 'label',
    redirectUrl = 'redirectUrl',
    posterImage = 'posterImage'
}

export const I_AGREE_PRIVACY_NOTICE_TEXT = `By clicking on the "I ACCEPT/ACKNOWLEDGE" button you are confirming you have reviewed the Privacy Notice and consent to the collection, use, disclosure and handling of your personal information for the purposes of, and in accordance with, the activities and terms, set out in this Privacy Notice.`;

export const MANAGE_CONTENT_PAGE = {
    TITLE: 'Manage content',
    SUB_TITLE: 'Descriptive text that describes your page or content.',
    CREATE_OFFER: {
        CONTENT_TYPE: 'Content Type',
        MODAL_HEADING: 'Create New Offer',
        CATEGORY: 'Category',
        CONFIRM: 'Confirm',
        MARKETING_TAGLINE: 'Marketing Tagline',
        URL: 'URL',
        URL_PLACEHOLDER: 'Type or paste URL',
        BANNER_IMAGE: 'Banner Image',
        UPLOAD_DEFAULT_IMAGE: 'Upload Default Image',
        CLICK_TO_UPLOAD: 'Click to upload',
        UPLOAD_IMAGE_TYPE_MESSAGE: 'or drag and drop PNG, JPG, JPEG (max. 800x400px)',
        UPLOAD_DEFAULT_IMAGE_ERROR: 'Error while uploading default image. Please try again later.',
        UPLOAD_DEFAULT_API_ERROR:
            'Unable to delete the banner image at the moment. Please try again later',
        CONFIRMATION_MODAL: {
            TITLE: 'Confirmation',
            DESCRIPTION:
                'Are you sure you want to delete this record? This action cannot be undone.'
        }
    },
    PREVIEW_CONTENT: {
        TITLE: 'Offer Preview'
    },
    EDIT_OFFER: {
        MODAL_HEADING: 'Edit Offer',
        EDIT_OFFER_FETCH_ERROR: 'Error while fetching offer details. Please try again later.'
    },
    MANAGE_CONTENT_ADD_BTN: 'Create Offer',
    MANAGE_CONTENT_LIST: 'List',
    MANAGE_CONTENT_Edit: 'Edit',
    ADD_MORE_CONTENT: 'Add More Content',
    OFFER_CREATED_SUCCESS_MESSAGE: 'Offer created successfully.',
    OFFER_UPDATED_SUCCESS_MESSAGE: 'Offer updated successfully.',
    OFFER_PUBLISH: {
        MODAL_TITLE: 'Publish Offer?',
        MODAL_DESCRIPTION: 'Are you sure you want to publish the selected offer(s)?',
        MODAL_SUBMIT_BUTTON: 'Publish',
        MODAL_SUBMIT_SUCCESS_MESSAGE: 'Offer published successfully.',
        MODAL_SUBMIT_ERROR_MESSAGE: 'Error while publishing offer. Please try again later.',
        MODAL_SUBMIT_LOADING_MESSAGE: 'Publishing...'
    },
    DELETE_OFFER: {
        MODAL_HEADING: 'Delete Offer?',
        ERROR_MESSAGE: 'Error while deleting offer. Please try again later.',
        MESSAGE: 'Are you sure you want to delete this offer? This action cannot be undone.',
        MODAL_ACTIONBTN_TEXT: 'Delete',
        SUCCESS_MESSAGE: 'Offer deleted successfully'
    }
};

export const NUMBER_REGEX = /^\d*$/;
export const NUMBER_WITH_DECIMAL_REGEX = /^\d*\.?\d*$/;
export const DOLLAR = '$';

export const OFFER_LIBRARY_PAGE = {
    CREATE_NEW_OFFER: {
        CONTENT_TYPE: 'Content Type',
        TITLE: 'Offer',
        AUDIENCE: 'Audience'
    }
};

export const MANAGE_CONTENT_TABS: IAdminTab[] = [
    {
        name: 'Offer Library',
        key: 0
    },
    {
        name: 'Key Stats',
        key: 1
    }
];

export const MANAGE_CONTENT_LIST = 'List';

export const PAGINATION_PAGE_SET = [5, 10, 15, 20, 50];

export const OFFER_LIBRARY_RADIO_BUTTON_PPED = 'PPED';

export const OFFER_LIBRARY_RADIO_BUTTON_NON_PPED = 'Non-PPED';
export const DIALOG_BOX = {
    cancel: 'Cancel'
};
