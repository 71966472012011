import { IRoutingOptions } from 'core/base/const';
import { FORMATTED_DATE_OPTION, QueryKeyEnum } from 'core/base/enum';
import { sanitizeHtml } from './htmlUtil';

/**
 * Checks whether an arguments is null.
 *
 * @param The values to be checked.
 *
 * @returns true if any argument is null.
 */
export const isNull = function isNull<T>(...args: T[]): boolean {
    if (args.length === 0) {
        return true;
    }
    const len: number = args.length;
    for (let i = 0; i < len; i++) {
        const obj: T = args[i];
        if (obj === undefined || obj === null) {
            return true;
        }
    }
    return false;
};

/**
 * Checks whether all of the provided arguments are NOT null.
 *
 * @param values to be checked.
 *
 * @returns true if all arguments are NOT null.
 */
export const isNotNull = function isNotNull<T>(...args: T[]): boolean {
    return !isNull(...args); // forward args
};

/**
 * to serialize the query prams
 */
export const serializeQueryString = <T>(obj: T): string => {
    return JSON.stringify(obj);
};

export const removeHashFromURL = () => {
    let searchUrl = window.location.search;
    const urlParameter = window.location;
    if (
        (urlParameter.hash !== '' || urlParameter.hash !== null) &&
        !urlParameter.hash.includes('id_token')
    ) {
        searchUrl = urlParameter.search + urlParameter.hash;
        return searchUrl;
    } else {
        return searchUrl;
    }
};

export const sanitizeQueryString = (query: string): string => {
    if (!query) return '';
    return query.match(/^\?[a-z]+=/) ? query : `?${QueryKeyEnum.Query}=${query}`;
};

export const generateTabId = (tabRoutes: IRoutingOptions): string => {
    const formattedString = Object.values(tabRoutes).toString().replaceAll(',', '/');
    return `/${formattedString}`;
};

export const truncateTitle = (str: string, n: number, useWordBoundary?: any): string => {
    if (!str || str?.length <= n) {
        return str;
    }
    const subString = str.slice(0, n - 1); // the original check
    return (useWordBoundary ? subString.slice(0, subString.lastIndexOf(' ')) : subString) + '...';
};

export const getNameFromId = (data: { id: string; name: string }[], id: string) =>
    data?.find((item) => item.id === id)?.name;

export const nFormatter = (num: number) => {
    if (num >= 1000000) {
        return (num / 1000000).toFixed(1).replace(/\.0$/, '') + 'M';
    } else if (num >= 1000) {
        return (num / 1000).toFixed(1).replace(/\.0$/, '') + 'K';
    }
    return num;
};

export const updateQueryStringParameter = (uri: string, key: string, value: string) => {
    const re = new RegExp('([?&])' + key + '=.*?(&|$)', 'i');
    const separator = uri.indexOf('?') !== -1 ? '&' : '?';
    if (uri.match(re)) {
        return uri.replace(re, '$1' + key + '=' + value + '$2');
    } else {
        return uri + separator + key + '=' + value;
    }
};

export const formatString = (value: string = '', hypen: boolean = false) => {
    const response = hypen ? value?.replace(/\s+/g, '-') : value;
    return response.toLowerCase();
};

export const formatDate = (date: string) => {
    const options: Intl.DateTimeFormatOptions = {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit'
    };
    const newDate = new Date(date);
    return newDate.toLocaleDateString(undefined, options);
};

// by default retruns string in MM-DD-YYYY format
export const formatDateToString = (date: Date, option: string): string => {
    const year = date.getFullYear();
    const month = date.getMonth() + 1;
    const day = date.getDate();

    const options: Intl.DateTimeFormatOptions = { month: 'short', year: 'numeric' };
    if (option === FORMATTED_DATE_OPTION.DD_MM_YYYY) {
        return `${day >= 10 ? day : '0' + day}-${month >= 10 ? month : '0' + month}-${year}`;
    } else if (option === FORMATTED_DATE_OPTION.YYYY_MM_DD) {
        return `${year}-${month >= 10 ? month : '0' + month}-${day >= 10 ? day : '0' + day}`;
    } else if (option === FORMATTED_DATE_OPTION.SHORT_MONTH_YEAR) {
        return date.toLocaleDateString(undefined, options);
    } else if (option === FORMATTED_DATE_OPTION.YY) {
        return year.toString().slice(-2);
    } else {
        return `${month >= 10 ? month : '0' + month}-${day >= 10 ? day : '0' + day}-${year}`;
    }
};

export { sanitizeHtml };
