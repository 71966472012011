import { createGlobalStyle } from 'styled-components';
import theme from './theme';

const GlobalStyles = createGlobalStyle`

.body {
    font-family: var(--primary-font), sans-serif;
}

.service-map__tooltip-header {
    font-weight: var(--weight-regular);

    strong {
        font-weight: var(--weight-bold);
    }
}

.motif-theme-dark {
    --body--bg-color : ${theme?.dark?.bodyBgColor};
    --training-card--text-color : ${theme?.dark?.trainingCardTextColor};
    --card--border-color : ${theme?.dark?.cardBorderColor};
    --card-header-branding: ${theme?.dark?.cardHeaderBranding};
    --suggestion-bg-color: ${theme?.dark?.suggestionBgColor};
    --suggestion-text-color: ${theme?.dark?.suggestionTextColor};
    --quick-link-icon-color: ${theme?.dark?.quickLinkIconColor};
    --toolkit-bg-color: ${theme?.dark?.toolkitBgColor};
    --toolkit-text-color: ${theme?.dark?.toolkitTextColor};
    --toolkit-border-color: ${theme?.dark?.toolkitBorderColor};
    --data-card-number-color: ${theme?.dark?.dataCardNumberColor};
    --data-card-text-color: ${theme?.dark?.dataCardTextColor};
    --app-header-caption-color: ${theme?.dark?.appHeaderCaptionColor};
    --chip--bg-color: ${theme?.dark?.serviceMap.chipBgColor};
    --file-uploader--text-color: ${theme?.dark?.fileUploader.uploadImageText};
    --ska--container-bg-color: ${theme?.dark?.skaContainerBGColor};
    --ska--message-bg-color: ${theme?.dark?.skaMessageBgColor};
    --ska--button-bg-color: ${theme?.dark?.skaButtonBgColor};
    --ska--input-bg-color: ${theme?.dark?.skaInputBgColor};
    --btn--padding: ${theme?.buttonPadding};
    .service-map__tooltip-header {
        color: ${theme?.dark?.serviceMap.tooltipHeaderColor};
    }
}
.motif-theme-light {
    --body--bg-color : ${theme?.light?.bodyBgColor};
    --training-card--text-color : ${theme?.light?.trainingCardTextColor};
    --card--border-color : ${theme?.light?.cardBorderColor};
    --card-header-branding: ${theme?.light?.cardHeaderBranding};
    --suggestion-bg-color: ${theme?.light?.suggestionBgColor};
    --suggestion-text-color: ${theme?.light?.suggestionTextColor};
    --quick-link-icon-color: ${theme?.light?.quickLinkIconColor};
    --toolkit-bg-color: ${theme?.light?.toolkitBgColor};
    --toolkit-text-color: ${theme?.light?.toolkitTextColor};
    --toolkit-border-color: ${theme?.light?.toolkitBorderColor};
    --data-card-number-color: ${theme?.light?.dataCardNumberColor};
    --data-card-text-color: ${theme?.light?.dataCardTextColor};
    --app-header-caption-color: ${theme?.light?.appHeaderCaptionColor};
    --chip--bg-color: ${theme?.light?.serviceMap.chipBgColor};
    --file-uploader--text-color: ${theme?.light?.fileUploader.uploadImageText};
    --ska--container-bg-color: ${theme?.light?.skaContainerBGColor};
    --ska--message-bg-color: ${theme?.light?.skaMessageBgColor};
    --ska--button-bg-color: ${theme?.light?.skaButtonBgColor};
    --ska--input-bg-color: ${theme?.light?.skaInputBgColor};
    --btn--padding: ${theme?.buttonPadding};
}

/* Privacy Notice popup */
.ot-consent-modal {
    .otwf-content {
        padding: 0 !important;
        background-color: var(--modal-header--bg-color) !important;
        .ot-form-content {
            #HostedWebFormContentBlock_0 {
                border-color: var(--modal-header--border-color) !important;
                border-style: var(--modal-header--border-style) !important;
                border-width: var(--modal-header--border-width) !important;
            }
            #HostedWebFormContentBlock_1 {
                margin-bottom: 0;
            }
            #HostedWebFormContentBlock_4 {
                hr {
                    display: none;
                }
            }
            .ot-contentblock {
                width: 100% !important;
                padding: 15px 0 !important;
                p {
                    padding-left: 1.428571rem;
                }
            }
        }
        .ot-btns .ot-submit {
            align-items: center;
            background-color: var(--btn--bg-color);
            border-color: var(--btn--border-color);
            border-radius: 0.29rem;
            border-style: var(--btn--border-style);
            border-width: var(--btn--border-width);
            box-shadow: var(--btn--box-shadow);
            color: var(--btn--color);
            cursor: pointer;
            display: flex;
            font-family: var(--primary-font);
            font-size: var(--btn--font-size);
            font-weight: var(--btn--font-weight);
            justify-content: center;
            line-height: 1.572;
            min-width: 6.2857142857rem;
            overflow: visible;
            padding: var(--btn--padding);
            margin-left: 15px;
            margin-bottom: 8px;
        }
    }
}

/* Cookie consent popup */
#onetrust-banner-sdk {
    .ot-sdk-container {
        background-color: var(--modal-header--bg-color) !important;
        #onetrust-policy-text {
            color: var(--body--color) !important;
        }
        #onetrust-button-group-parent {
            border-top-color: var(--modal-header--border-color) !important;
            border-top-style: var(--modal-header--border-style) !important;
            border-top-width: 0.071rem !important;
        }
        #onetrust-accept-btn-handler {
            background-color: var(--btn--bg-color) !important;
            border-color: var(--btn--border-color) !important;
            border-radius: 0.29rem !important;
            border-style: var(--btn--border-style) !important;
            border-width: var(--btn--border-width) !important;
            color: var(--btn--color) !important; 
            font-family: var(--primary-font);
            font-size: var(--btn--font-size);
            font-weight: var(--btn--font-weight);
        }

        #onetrust-reject-all-handler, #onetrust-pc-btn-handler {
            align-items: center;
            background-color: var(--btn-secondary--bg-color) !important;
            border-color: var(--btn-secondary--border-color) !important;
            border-radius: var(--btn-secondary--border-radius) !important;
            border-style: var(--btn-secondary--border-style) !important;
            border-width: var(--btn-secondary--border-width) !important;
            box-shadow: var(--btn-secondary--box-shadow) !important;
            color: var(--btn-secondary--color) !important;
            font-family: var(--primary-font);
            font-size: var(--btn--font-size);
            font-weight: var(--btn--font-weight);
        }
    }
}

/* Data Privacy Center popup */
#onetrust-consent-sdk {
    #onetrust-pc-sdk {
        background-color: var(--modal-header--bg-color) !important;
        top: 12% !important;
        #close-pc-btn-handler.ot-close-icon {
            display: none !important;
        }
        .ot-pc-header {
            border-bottom-color: var(--modal-header--border-color) !important;
            border-bottom-style: var(--modal-header--border-style) !important;
            border-bottom-width: 0.071rem !important;
        }
        .ot-switch-nob {
            border: 0 !important;
        }
        .ot-tgl input:checked+.ot-switch .ot-switch-nob:before {
            background-color: var(--modal-header--bg-color) !important;
        }
        .ot-switch-nob:before {
            background-color: var(--modal-header--bg-color) !important;
        }
        .ot-pc-footer {
            border-top: 0 !important;
        }
        .ot-btn-container {
            display: flex;
            justify-content: end;
        }
        .ot-pc-logo {
            img {
                display: none;
            }
        }
        .ot-pc-logo:after {
            content: "Data Privacy Center";
        }
        .ot-pc-refuse-all-handler, #accept-recommended-btn-handler {
            align-items: center;
            background-color: var(--btn-secondary--bg-color) !important;
            border-color: var(--btn-secondary--border-color) !important;
            border-radius: var(--btn-secondary--border-radius) !important;
            border-style: var(--btn-secondary--border-style) !important;
            border-width: var(--btn-secondary--border-width) !important;
            box-shadow: var(--btn-secondary--box-shadow) !important;
            color: var(--btn-secondary--color) !important;
            font-family: var(--primary-font);
            font-size: var(--btn--font-size);
            font-weight: var(--btn--font-weight);
        }
        .save-preference-btn-handler {
            background-color: var(--btn--bg-color) !important;
            border-color: var(--btn--border-color) !important;
            border-radius: 0.29rem !important;
            border-style: var(--btn--border-style) !important;
            border-width: var(--btn--border-width) !important;
            color: var(--btn--color) !important; 
            font-family: var(--primary-font);
            font-size: var(--btn--font-size);
            font-weight: var(--btn--font-weight);
        }
    }
}

#onetrust-consent-sdk #onetrust-pc-sdk h3, #onetrust-consent-sdk #onetrust-pc-sdk h4, #onetrust-consent-sdk #onetrust-pc-sdk h5, #onetrust-consent-sdk #onetrust-pc-sdk h6, #onetrust-consent-sdk #onetrust-pc-sdk p, #onetrust-consent-sdk #onetrust-pc-sdk #ot-ven-lst .ot-ven-opts p, #onetrust-consent-sdk #onetrust-pc-sdk #ot-pc-desc, #onetrust-consent-sdk #onetrust-pc-sdk #ot-pc-title, #onetrust-consent-sdk #onetrust-pc-sdk .ot-li-title, #onetrust-consent-sdk #onetrust-pc-sdk .ot-sel-all-hdr span, #onetrust-consent-sdk #onetrust-pc-sdk #ot-host-lst .ot-host-info, #onetrust-consent-sdk #onetrust-pc-sdk #ot-fltr-modal #modal-header, #onetrust-consent-sdk #onetrust-pc-sdk .ot-checkbox label span, #onetrust-consent-sdk #onetrust-pc-sdk #ot-pc-lst #ot-sel-blk p, #onetrust-consent-sdk #onetrust-pc-sdk #ot-pc-lst #ot-lst-title h3, #onetrust-consent-sdk #onetrust-pc-sdk #ot-pc-lst .back-btn-handler p, #onetrust-consent-sdk #onetrust-pc-sdk #ot-pc-lst .ot-ven-name, #onetrust-consent-sdk #onetrust-pc-sdk #ot-pc-lst #ot-ven-lst .consent-category, #onetrust-consent-sdk #onetrust-pc-sdk .ot-leg-btn-container .ot-inactive-leg-btn, #onetrust-consent-sdk #onetrust-pc-sdk .ot-label-status, #onetrust-consent-sdk #onetrust-pc-sdk .ot-chkbox label span, #onetrust-consent-sdk #onetrust-pc-sdk #clear-filters-handler, #onetrust-consent-sdk #onetrust-pc-sdk .ot-optout-signal {
    color: var(--body--color) !important;
}

.motif-card {
    border-color: var(--card--border-color);
}

* {
    margin: 0px;
    padding: 0px;
    box-sizing: border-box;
}

// *:focus:not(.focus-visible) {
//     outline: none;
// }

*:focus {
    outline: 2px solid gray;
}

/* Scrollbar */
::-webkit-scrollbar {
    width: 4px;
    height : 8px;
    background-color: ${theme?.scrollBarBgColor};
}

::-webkit-scrollbar-track {
    background:  ${theme?.scrollBarTrackBgColor} !important;
}

::-webkit-scrollbar-thumb {
    background:  ${theme?.scrollBarThumbBgColor} !important;
}

a {
    border: 0 !important;
    box-shadow: none !important;
}

iframe {
    display: none;   
}

.hide-for-accessibility {
    clip: rect(1px, 1px, 1px, 1px);
    clip-path: inset(50%);
    height: 1px;
    width: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
}

.show-loader-with-data {
  background-color: red;
  height: 800px;
  z-index: 8;
  position: absolute;
  width: 100%;
  opacity: 0.6;
}

/* tooltip */
.motif-tooltip-wrapper {
    max-width: 500px;
}
.motif-tooltip-content .motif-tooltip-content-children {
    font-size: 14px;
    line-height: 20px;
}

.ot-contentblock hr {
    margin: 20px 0 10px;
    border-color: #33333e;
    opacity: .2;
}
`;

export default GlobalStyles;
