import React, { FC } from 'react';
import { RootState, useAppSelector } from 'core/store';
import { CardBody, CardHeader } from 'components/base/Card';
import { CaseStudiesStyle } from './CaseStudies.styled';
import { Button, Icon, Loader } from 'components/base';
import CompetitiveEdgeCaseStudies from 'assets/lib/common/images/CompetitiveEdgeCaseStudies.png';
import DiligenceEdgeCaseStudies from 'assets/lib/common/images/DiligenceEdgeCaseStudies.png';
import CapitalEdgeCaseStudies from 'assets/lib/common/images/CapitalEdgeCaseStudies.png';
import { BUTTON_LABELS, CASE_STUDY_HEADER, EY } from 'core/base/const';
import { navigationIcArrowForward24px } from 'assets/lib/motif/icons';
import { SERVICE_GROUP } from 'core/base/enum';
import { BUTTON_SIZE, BUTTON_VARIANT } from 'components/base/Button/Button';
import { AEP_DATA_TYPE_PREFIX, AEP_TRACK, AEP_TRACK_ACTION } from 'aepLibrary/aepDataCollection';
import { formatString } from 'common/utils';

const CaseStudies: FC = () => {
    const currentProduct = useAppSelector((state: RootState) => state.banner.activeProductId);
    const activeServiceGroupId = useAppSelector(
        (state: RootState) => state.banner.activeServiceGroupId
    );
    const activeServiceId = useAppSelector(
        (state: RootState) => state.banner.activeServices[activeServiceGroupId]
    );
    const activeProductId = useAppSelector(
        (state: RootState) => state.banner.activeProductIds[activeServiceId]
    );
    const activeEdgePlatformId = useAppSelector(
        (state: RootState) => state.banner.activeEdgePlatformId[activeProductId]
    );
    const edgePlatformData = useAppSelector((state: RootState) => state.banner.edgePlatformData);
    const caseStudiesUrl = useAppSelector((state: RootState) => state.banner.caseStudiesUrl);

    const activeEdgePlatformName: string = edgePlatformData[activeEdgePlatformId];
    const activeEdgePlatformLink: string = caseStudiesUrl[activeEdgePlatformId];

    const handleViewAll = () => {
        const newWindow = window.open(activeEdgePlatformLink, '_blank', 'noopener,noreferrer');
        if (newWindow) newWindow.opener = null;
    };

    let image = '';
    if (activeEdgePlatformId === SERVICE_GROUP.strategy) {
        image = CompetitiveEdgeCaseStudies;
    } else if (activeEdgePlatformId === SERVICE_GROUP.diligence) {
        image = DiligenceEdgeCaseStudies;
    } else if (activeEdgePlatformId === SERVICE_GROUP.execution) {
        image = CapitalEdgeCaseStudies;
    }

    if (image === '') {
        return <Loader className="product-loader" />;
    }
    return (
        <CaseStudiesStyle $caseStudyImg={image}>
            <CardHeader>
                <span className="motif-card-header-title">
                    {EY} {activeEdgePlatformName} {CASE_STUDY_HEADER}
                </span>
            </CardHeader>
            <div className="case-studies-image">
                <div className="case-studies-image-element"></div>
            </div>
            <CardBody>
                <Button
                    data-aep-track={AEP_TRACK.analytics}
                    data-aep-track-action={AEP_TRACK_ACTION.linkClick}
                    data-aep-type={`${AEP_DATA_TYPE_PREFIX.caseStudiesViewAll}-${formatString(currentProduct?.name, true)}`}
                    data-aep-name={formatString(
                        `${EY} ${activeEdgePlatformName} ${CASE_STUDY_HEADER} ${BUTTON_LABELS.viewAll}`
                    )}
                    type="button"
                    variant={BUTTON_VARIANT.SECONDARY}
                    className="motif-card-btn-view-all"
                    size={BUTTON_SIZE.MEDIUM}
                    onClick={() => handleViewAll()}>
                    {BUTTON_LABELS.viewAll}&nbsp;
                    <Icon src={navigationIcArrowForward24px} aria-hidden="true" />
                </Button>
            </CardBody>
        </CaseStudiesStyle>
    );
};

export default CaseStudies;
