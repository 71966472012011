import { MotifModalHeader } from 'assets/lib/motif';
import { FC } from 'react';
import StyleSensitivityModal from './ModalHeader.styled';

interface IModalHeaderProps {
    headerExtraProps?: React.ReactHTMLElement<HTMLDivElement>;
    closeModalButton?: React.ReactNode;
    closeButtonProps?: Record<string, unknown>;
    headerClassName?: string;
    children: React.ReactNode;
}

const ModelHeader: FC<IModalHeaderProps> = ({
    headerExtraProps = {},
    closeModalButton,
    closeButtonProps = {},
    headerClassName = '',
    children
}) => {
    return (
        <StyleSensitivityModal.Header>
            <MotifModalHeader
                {...headerExtraProps}
                closeModalButton={closeModalButton}
                closeButtonProps={closeButtonProps}
                className={`sat-modal__header ${headerClassName}`}>
                {children}
            </MotifModalHeader>
        </StyleSensitivityModal.Header>
    );
};

export default ModelHeader;
