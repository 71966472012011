import { Card } from 'components/base/Card';
import styled from 'styled-components';

export const ServiceMapStyled = {
    Card: styled(Card)`
        margin-bottom: 20px;

        .motif-card-body {
            min-height: 620px;
        }

        .motif-card-header-subtitle {
            font-weight: var(--weight-regular);
            position: relative;
            top: 2px;
            p {
                font-weight: 400;
            }
        }

        .service-map-footer {
            margin-top: 15px;
            position: absolute;
            bottom: 1rem;
            .motif-text-link {
                margin-right: 5px;
            }
        }
    `,
    Grid: styled.div<{ $gridColumn: string }>`
        display: grid;
        grid-template-columns: ${({ $gridColumn }) => $gridColumn};
        gap: 5px;
    `
};
