import React, { useCallback } from 'react';
import { StyledImageCarousel } from './ImageCarousel.style';
import { IImageCarousel, IPromoCardOffer } from 'core/base/type/MarketingCard';
import MarketingCard from 'components/custom/MarketingCards/MarketingCardItem';
import Carousel from 'components/base/Carousel';
import { API_ENDPOINTS, carouselLength } from 'core/base/const';
import { useGetContentConfigQuery } from 'core/api';

const ImageCarousel: React.FC<IImageCarousel> = (imageCarousel: IImageCarousel) => {
    const apiConfig = {
        url: API_ENDPOINTS.CONTENT_CONFIG
    };
    const { data, isSuccess } = useGetContentConfigQuery(apiConfig);

    const getMarketingCards = useCallback(() => {
        if (imageCarousel?.imageList && imageCarousel?.imageList?.length > 0) {
            return imageCarousel.imageList.map((cardItem: IPromoCardOffer, index: number) => {
                if (index < carouselLength) {
                    const cataNeme = imageCarousel?.isAdmin
                        ? cardItem?.category?.name
                        : cardItem?.categoryName;
                    return (
                        <MarketingCard
                            key={`marketing-cad${cardItem.label}-${index}`}
                            imageUrl={`${data?.baseUrl}/${cardItem.imageIdentifier}${data?.token}`}
                            label={cardItem.label}
                            categoryName={cataNeme}
                            redirectUrl={cardItem.redirectUrl}></MarketingCard>
                    );
                }
            });
        }
    }, [imageCarousel, data, isSuccess]);

    return (
        <StyledImageCarousel
            className={imageCarousel.className ?? ''}
            width={imageCarousel?.width ?? '100%'}
            showNextPreviousControls={false}
            height={imageCarousel?.height ?? '100%'}
            data-test-id={imageCarousel.imageCarouselTestId}>
            {imageCarousel?.imageList &&
                imageCarousel?.imageList?.length > 0 &&
                isSuccess &&
                Object.keys(data)?.length && <Carousel auto={true}>{getMarketingCards()}</Carousel>}
        </StyledImageCarousel>
    );
};

export default ImageCarousel;
