import React, { useEffect } from 'react';
import { MotifToast } from 'assets/lib/motif';
import { ToastVariant } from 'core/base/enum';
import styled from 'styled-components';

type ToastProps = {
    show: boolean;
    actionName?: string;
    variant?: ToastVariant;
    position?: 'top' | 'bottom' | '';
    duration?: number;
    className?: string;
    message: string;
    onActionClick?: () => void;
    onClose: () => void;
    icons?: { close?: React.ReactNode; toast?: React.ReactNode };
    isAutoClose?: boolean;
};

const StyledToast = styled(
    ({
        actionName,
        variant = ToastVariant.SUCCESS,
        position = 'top',
        className = '',
        message,
        onActionClick,
        onClose,
        icons,
        ...other
    }: Omit<ToastProps, 'show' | 'duration' | 'autoClose'>) => (
        <MotifToast
            variant={variant}
            actionName={actionName}
            className={`toast ${className}`}
            position={position}
            actionOnClick={onActionClick}
            onClose={() => {
                onClose();
            }}
            icons={icons}
            {...other}>
            {message}
        </MotifToast>
    )
)`
    z-index: 99;
    max-width: 400px;

    &.motif-toast-fixed-top {
        top: 84px;
        right: 20px;
        left: auto;
    }
`;

const Toast: React.FC<ToastProps> = ({ show, isAutoClose, duration = 3000, ...others }) => {
    useEffect(() => {
        if (isAutoClose) {
            const timer = setTimeout(() => {
                others?.onClose();
            }, duration);

            return () => {
                clearTimeout(timer);
            };
        }
    }, [isAutoClose, duration, others]);

    return <>{show && <StyledToast {...others} />}</>;
};

export default Toast;
