import styled from 'styled-components';

export const StyledIntro = styled.div`
    display: flex;
    width: 90%;
    box-shadow: 0px 0px 16px rgba(0, 0, 0, 0.20287);
    position: absolute;
    left: 50%;
    transform: translate(-50%, 50%);
    .warning {
        padding: 10px 13px;
        height: 100%;
    }
    p,
    span {
        font-style: normal;
        font-weight: 300;
        font-size: 14px;
    }
    .box {
        padding: 10px;
        line-height: 22px;
        width: 100%;
        p span {
            margin: 5px;
        }
    }
    .details-p {
        margin-top: 1rem;
    }
`;
