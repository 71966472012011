import { FC, ReactNode } from 'react';
import { MotifHeaderLogo } from '../../../../assets/lib/motif';

type HeaderLogoProps = {
    children: ReactNode;
    className?: string;
};

const HeaderLogo: FC<HeaderLogoProps> = ({ children, className, ...props }) => {
    return (
        <MotifHeaderLogo className={className} {...props}>
            {children}
        </MotifHeaderLogo>
    );
};

export default HeaderLogo;
