import React, { useEffect, useRef, useState } from 'react';
import { playVideoButton } from 'assets/lib/common/icons';
import { VideoPlayerStyle } from './VideoPlayer.styled';
import { Icon } from 'components/base';
import NoVideoFound from './NoVideoFound';
import NoImageFound from './NoImageFound';
import { AEP_TRACK, AEP_TRACK_ACTION } from 'aepLibrary/aepDataCollection';
import { formatString } from 'common/utils';

interface VideoProps {
    videoUrl: string;
    thumbnailUrl: string;
    thumbnailTitle: string;
    controls?: boolean;
    dynamicVideoPlayerId?: number;
    aepType: string;
}

const VideoPlayerComponent: React.FC<VideoProps> = ({
    thumbnailTitle,
    videoUrl,
    thumbnailUrl,
    controls = true,
    dynamicVideoPlayerId,
    aepType
}) => {
    const [isPlaying, setIsPlaying] = useState(false);
    const videoRef = useRef<HTMLVideoElement>(null);

    useEffect(() => {
        if (isPlaying) {
            videoRef.current?.play();
        }
    }, [isPlaying]);

    useEffect(() => {
        setIsPlaying(false);
    }, [dynamicVideoPlayerId]);

    const handlePlayClick = () => {
        if (videoUrl) {
            setIsPlaying(!isPlaying);
        }
    };

    if (isPlaying) {
        return (
            <VideoPlayerStyle $videoUrl={videoUrl}>
                {videoUrl ? (
                    <video
                        ref={videoRef}
                        data-test-id="video-player-section"
                        src={videoUrl}
                        controls={controls}
                        autoPlay={false}
                    />
                ) : (
                    <NoVideoFound />
                )}
            </VideoPlayerStyle>
        );
    }

    return (
        <VideoPlayerStyle $videoUrl={videoUrl}>
            <div
                onClick={handlePlayClick}
                data-aep-track={AEP_TRACK.analytics}
                data-aep-track-action={AEP_TRACK_ACTION.linkClick}
                data-aep-type={aepType}
                data-aep-name={formatString(thumbnailTitle)}>
                {thumbnailUrl ? (
                    <img
                        src={thumbnailUrl}
                        alt="Video Thumbnail"
                        data-test-id="video-thumbnail-image"
                        className="video-wrapper__thumbnail-image"
                    />
                ) : (
                    <NoImageFound />
                )}
                {videoUrl && (
                    <div className="video-wrapper__play-btn">
                        <Icon src={playVideoButton} testId={'video-play-button'} />
                    </div>
                )}

                {thumbnailTitle !== '' && (
                    <div className="video-wrapper__image-name" data-test-id="video-thumbnailTitle">
                        {thumbnailTitle}
                    </div>
                )}
            </div>
        </VideoPlayerStyle>
    );
};

export default VideoPlayerComponent;
