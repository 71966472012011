import styled from 'styled-components';

const InputFieldStyle = styled.div`
    /* .motif-input {
    padding: 1.4285714286rem 2.8571428571rem 0.0714285714rem 0.5714285714rem;
  } */

    .motif-input-invalid {
        border-radius: var(--input-error--border-radius);
        border: 1px solid var(--input-error--border-color);
    }
`;

export default InputFieldStyle;
