import { createApi, fakeBaseQuery } from '@reduxjs/toolkit/query/react';
import { RTK_QUERY_CACHE_TIME } from '../base/const';

export class ApiBaseError {
    code?: number;
    message?: string[] = [];
    stack?: string;

    constructor(status?: number, data?: string[], customMessage?: string) {
        this.code = status;
        this.message = data;
        this.stack = customMessage ?? '';
    }
}

// initialize an empty api service that we'll inject endpoints into later as needed
export const baseApi = createApi({
    reducerPath: 'api',
    baseQuery: fakeBaseQuery<ApiBaseError>(),
    endpoints: () => ({}),
    keepUnusedDataFor: RTK_QUERY_CACHE_TIME
    // refetchOnMountOrArgChange: true,
});

export const { middleware, reducer, reducerPath } = baseApi;
