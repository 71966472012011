import styled from 'styled-components';

export const StyledPolicyContent = styled.div`
    p {
        font-family: ${({ theme }) => theme?.chart?.fontInterstate};
        font-size: 12px;
        line-height: 20px;
        font-weight: lighter;
        color: rgb(196, 196, 205) !important;
        margin-block-start: 1em;
        margin-block-end: 1em;
    }
    div {
        font-family: ${({ theme }) => theme?.chart?.fontInterstate};
        font-size: 14px;
        line-height: 20px;
        color: rgb(196, 196, 205) !important;
    }
    .text-color {
        color: ${({ theme }) => theme.lightgray};
        & a {
            text-decoration: none;
            color: #${({ theme }) => theme.lightgray};
        }
    }
    .m-b-15 {
        margin-bottom: -15px;
    }
    .center_bold_text {
        font-weight: bold;
        text-align: center;
    }
    .motif-h4 {
        font-family: ${({ theme }) => theme?.chart?.fontInterstate};
        font-size: 16px;
        line-height: 20px;
        color: ${({ theme }) => theme.lightgray} !important;
    }
    .motif-h6 {
        font-family: ${({ theme }) => theme?.chart?.fontInterstate};
        font-size: 14px;
        line-height: 20px;
        color: ${({ theme }) => theme.lightgray} !important;
        font-weight: lighter;
    }

    h1,
    h4 {
        color: ${({ theme }) => theme.lightgray};
    }

    h1 {
        margin: 0;
        padding-bottom: 1.25rem;
        font-weight: 500;
        font-size: 2.25rem;
        font-weight: 500;
        letter-spacing: 0;
        line-height: 3.063rem;
    }

    h4 {
        font-weight: 500;
    }

    ol {
        list-style-type: upper-roman;
        margin-block-start: 1em;
        margin-block-end: 1em;
        margin-inline-start: 0px;
        margin-inline-end: 0px;
        padding-inline-start: 40px;
    }
    ul {
        list-style-type: disc;
        margin-block-start: 1em;
        margin-block-end: 1em;
        margin-inline-start: 0px;
        margin-inline-end: 0px;
        padding-inline-start: 40px;
    }
`;
