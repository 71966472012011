import styled from 'styled-components';

const StyleSensitivityModal = {
    Header: styled.div`
        h3 {
            font-size: 1rem;
        }
    `
};

export default StyleSensitivityModal;
