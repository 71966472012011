import React, { FC, useEffect, useState } from 'react';
import { StyledWelComeMsg } from './WelComeMessage.styled';
import { RootState, useAppSelector } from 'core/store';
import { message } from '.';

const WelComeMessage: FC = () => {
    const isDarkTheme = useAppSelector((state) => state.common.theme);
    const [userFirstName, setUserFirstName] = useState('');
    const displayName = useAppSelector(
        (state: RootState) => state?.userProfile?.userData.displayName
    );

    useEffect(() => {
        const userName = displayName;
        if (userName !== '') {
            const splitValue = userName.split(' ');
            if (splitValue.length > 0) {
                setUserFirstName(`${splitValue[0]}!`);
            } else {
                setUserFirstName(`${displayName}!`);
            }
        }
    }, [displayName]);

    return (
        <StyledWelComeMsg $isDarkTheme={isDarkTheme}>
            <span className="welcome-message">
                <span className="welcome-msg">Welcome,&nbsp;</span>
                <span className="user-first-name">{`${userFirstName}`}</span>
                &nbsp;
                {message}
            </span>
        </StyledWelComeMsg>
    );
};

export default WelComeMessage;
