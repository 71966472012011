import styled from 'styled-components';

export const HomePageStyled = {
    PageWrapper: styled.div`
        padding: 0 20px 20px;
    `,
    WidgetWrapper: styled.div`
        width: 100%;
        align-items: stretch;
        display: grid;
        grid-template-columns: 60% calc(40% - 20px);
        column-gap: 22px;
    `,
    LeftSection: styled.div`
        max-width: 960px;
        .inner-section {
            display: flex;
            align-items: stretch;
            column-gap: 20px;
            margin-bottom: 20px;
            min-height: 420px;
            .product-loader {
                position: relative;
                height: inherit;
                background: var(--body--bg-color);
                flex: 1 1 50%;
            }
        }
    `,
    RightSection: styled.div`
        max-width: 620px;
        display: inline-flex;
        .right-section__edge-card {
            position: relative;
            min-height: 450px;
            margin-bottom: 20px;
        }
        .right-section__error-card {
            margin-bottom: 20px;
            min-height: 450px;
            display: flex;
            justify-content: center;
            align-items: center;

            p {
                color: var(--branding);
            }
        }
    `
};
