import { Card } from 'components/base/Card';
import styled from 'styled-components';

export const TrainingStyle = styled(Card)`
    flex: 1 1 50%;
    .motif-card-body {
        display: flex;
        flex-direction: column;
    }
    .motif-card-body > p {
        color: var(--training-card--text-color);
    }
    .motif-card-btn-view-all {
        line-height: 24px;
        font-size: 18px;
        font-weight: 400;
        width: 130px;
        height: 44px;
        padding: 24px 14px;
    }
    .training-video {
        div:first-child {
            margin: 1rem 0;
        }
        .video-wrapper__image-not-found {
            min-height: 180px;
            .video-wrapper__image-error {
                margin-top: 0;
            }
        }
        .video-wrapper__play-btn {
            top: 45%;
        }
    }
    /* CSS rules for screen sizes above 1200px */
    .motif-card-body {
        .video-wrapper__thumbnail-image {
            min-height: 180px;
        }
    }
`;
