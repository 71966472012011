import styled from 'styled-components';

interface StyledImageCarouselProps {
    width?: string;
    height?: string;
    objectFit?: string;
    showNextPreviousControls?: boolean;
}

export const StyledImageCarousel = styled.div<StyledImageCarouselProps>`
    width: ${(props) => props.width || '100%'};
    height: ${(props) => props.height || '100%'};
    .motif-carousel-controls {
        display: ${(props) => (props.showNextPreviousControls === false ? 'none' : '')} !important;
    }

    .motif-carousel-container .motif-carousel-dot-indicator {
        border-radius: 0px;
        margin-right: 0px;
        background-color: transparent;
        top: -84%;
        right: auto;
        margin-left: 14px;
    }

    .motif-carousel-dot-item:has(.motif-carousel-dot-active) {
        border: 1px solid #fff;
        border-radius: 10px;
    }

    .motif-carousel-container .motif-carousel-dot-indicator .motif-carousel-dot {
        width: 8px;
        height: 8px;
        background-color: #fff;
    }

    .motif-carousel-container .motif-carousel-dot-indicator .motif-carousel-dot-active {
        background-color: #ffffff !important;
        margin: 4px;
    }

    .motif-carousel-container li {
        margin-bottom: 0px;
    }

    .motif-carousel-pause-btn,
    .motif-carousel-play-btn {
        display: none;
    }
`;
