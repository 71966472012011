import axios, {
    AxiosError,
    AxiosRequestConfig,
    AxiosResponse,
    CancelToken,
    InternalAxiosRequestConfig
} from 'axios';
import { Guid as guid } from 'guid-typescript';
import { HTTP_HEADER, HTTP_STATUS_CODES } from 'core/base/enum';
import { getCurrentSessionId } from 'core/service';
import { BASE_API_URL, BASE_APP_URL, CLIENT_ID } from 'app.constant';
import { API_ENDPOINTS, IGenericObject } from 'core/base/const';
import { showPolicyModal } from 'core/store/userProfile.reducer';
import qs from 'query-string';
import { store } from 'core/store';
import { getAccessTokenRequest } from 'core/configs/authConfig';
import { setIsUnautorized } from 'core/store/common.reducer';
import { HEADER_CONTENT_TYPE } from 'core/base/type';

const cache: any = {};
const Axios = axios.create({
    baseURL: BASE_API_URL,
    headers: { 'Content-Type': 'application/json' }
});

export const acceptPrivacyPolicy = (): Promise<IGenericObject> => {
    return post(API_ENDPOINTS.ACCEPT_POLICY, {})
        .then((response) => {
            return response;
        })
        .catch((error: AxiosError) => {
            const { response } = error;
            return response;
        });
};
const unwrapData = (r: AxiosResponse): any => {
    return r.data || {};
};
export const getPrivacyPolicyContent = (policyType: number): Promise<IGenericObject> => {
    const queryParams = { PolicyType: policyType };
    const cacheKey = JSON.stringify({ queryParams, url: API_ENDPOINTS.GET_PRIVACY_POLICY });
    const cacheData = cache[cacheKey];
    if (cacheData) {
        return new Promise((resolve) => {
            setTimeout(() => {
                return resolve(cacheData);
            }, 0);
        });
    }
    return get(API_ENDPOINTS.GET_PRIVACY_POLICY, queryParams)
        .then((response) => {
            cache[cacheKey] = response;
            return response;
        })
        .catch((error: AxiosError) => {
            const { response } = error;
            return response;
        });
};
const getRequestConfig = (params = {}, config: AxiosRequestConfig = {}): AxiosRequestConfig => {
    const requestConfig = {
        params,
        ...config,
        paramsSerializer: (params: any): string => {
            return qs.stringify(params);
        }
    };

    return requestConfig;
};
export const get = <T = any>(
    path: string,
    queryParams = {},
    config: AxiosRequestConfig = {},
    hasCustomCancellationHandler?: boolean
): Promise<T> => {
    const requestConfig = getRequestConfig(queryParams, config);
    return Axios.get(path, requestConfig)
        .then(unwrapData)
        .catch((error: AxiosError) => {
            // handle cancellation token
            if (axios.isCancel(error) && !hasCustomCancellationHandler) {
                return null;
            }
            return Promise.reject(error);
        });
};

export const post = <T = any>(
    path: string,
    data: any,
    cancelToken?: CancelToken,
    hasCustomCancellationHandler = false
): Promise<T> => {
    return Axios.post(path, data, {
        cancelToken: cancelToken
    })
        .then(unwrapData)
        .catch((error: AxiosError) => {
            // handle cancellation token
            if (axios.isCancel(error) && !hasCustomCancellationHandler) {
                return null;
            }

            return Promise.reject(error);
        });
};

/* Here we are using API URL"s which will required header as multipart/form-data. */
const URL_LIST = [API_ENDPOINTS.BANNER_UPLOAD_NEW_IMAGE, API_ENDPOINTS.BANNER_UPDATE_IMAGE];

Axios.interceptors.request.use(
    (config: InternalAxiosRequestConfig<any>) => {
        return getAccessTokenRequest().then((token) => {
            if (token.length > 0 && config.headers) {
                config.headers.Authorization = `Bearer ${token}`;
                config.headers[HTTP_HEADER.ContentType] =
                    (config.url ?? '') && URL_LIST.some((url) => config.url!.startsWith(url))
                        ? HEADER_CONTENT_TYPE.FORM_DATA
                        : HEADER_CONTENT_TYPE.APPLICATION_JSON;
                config.headers[HTTP_HEADER.RequestedWith] = 'XMLHttpRequest';
                config.headers['Request-Id'] = `${guid.create()}`;
                config.headers['X-Correlation-Id'] = `${guid.create()}`;
                config.headers['X-Window-Id'] = getCurrentSessionId();
            }
            return config;
        });
    },
    (error) => Promise.reject(error)
);

// intercept responses and look for errors
Axios.interceptors.response.use(
    (response: AxiosResponse) => response,
    (error) => {
        if (error.response?.status === HTTP_STATUS_CODES.UNAVAILABLE_LEGAL_REASON) {
            const { policyTypeList = [], showPolicy } = error?.response?.data;

            if (showPolicy) {
                const errordata = {
                    policyTypeList,
                    showPolicy
                };
                store.dispatch(showPolicyModal(errordata));
            }
        }
        if (error.response?.status === HTTP_STATUS_CODES.SESSION_TIMEOUT) {
            // Session idle time-out
            localStorage.clear();
            const currentUrl =
                window.location.pathname === 'error' ? BASE_APP_URL : window.location.href;
            window.location.href = `https://login.microsoftonline.com/common/oauth2/logout?client_id=${CLIENT_ID}&post_logout_redirect_uri=${encodeURIComponent(
                currentUrl
            )}`;
        } else if (error.response?.status === HTTP_STATUS_CODES.UNAUTHORIZED) {
            // Unauthorized user
            store.dispatch(setIsUnautorized(true));
        }
        return Promise.reject(error);
    }
);

export { Axios };
