import { MotifModalFooter } from 'assets/lib/motif';
import { FC } from 'react';

interface IModalFooterProps {
    footerExtraProps?: React.ReactHTMLElement<HTMLDivElement>;
    footerClassName?: string;
    children: React.ReactNode;
}

const ModelFooter: FC<IModalFooterProps> = ({
    footerExtraProps = {},
    footerClassName = '',
    children
}) => {
    return (
        <MotifModalFooter {...footerExtraProps} className={`sat-modal__footer ${footerClassName}`}>
            {children}
        </MotifModalFooter>
    );
};

export default ModelFooter;
