import { noImageFound } from 'assets/lib/common/icons';
import { FAILED_TO_LOAD_IMAGE } from 'core/base/const';

/* This will change to actual Image when we will get Image from design team. */
const NoImageFound = () => {
    return (
        <div className="video-wrapper__image-not-found">
            <img src={noImageFound} alt="No Image Found" />
            <span className="video-wrapper__image-error">{FAILED_TO_LOAD_IMAGE}</span>
        </div>
    );
};

export default NoImageFound;
