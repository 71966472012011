import { ReactNode } from 'react';
import { MotifCardHeader } from 'assets/lib/motif';
import styled from 'styled-components';

interface ICardHeaderProps {
    children: ReactNode;
    className?: string;
}

const CardHeader = styled(({ children, className = '' }: ICardHeaderProps) => {
    return <MotifCardHeader className={className}>{children}</MotifCardHeader>;
})`
    border-bottom: none;
    padding-left: 1.5rem;
    padding-right: 1.5rem;
    .motif-card-header-title {
        font-size: 20px;
        font-weight: 400;
        line-height: 30px;
        color: var(--card-header--color);
    }
    .motif-card-header-title-alt {
        color: var(--card-header-branding);
    }
`;

export default CardHeader;
